import { useEffect, useState } from 'react';
import { FaRegTrashAlt as DeleteIcon } from 'react-icons/fa';
import { Product } from '../../../models/Product/Product';
import axiosAPI from '../../../utils/client/axiosAPI';

interface ProductImagesProps {
  product: Product;
}

function ProductImages({ product }: ProductImagesProps) {
  const [images, setImages] = useState<string[]>(product.images ?? []);

  const ImagesList = images?.map((url, index) => (
    <div key={index}>
      <button
        type="button"
        onClick={() => {
          axiosAPI.delete(url).then(() => {
            setImages((state) => {
              const images = [...state];
              images.splice(index, 1);
              return images;
            });
          });
        }}
      >
        <DeleteIcon />
      </button>
      <img src={url} alt="..." onLoad={() => {}} />
    </div>
  ));

  return <>{ImagesList}</>;
}

export default ProductImages;
