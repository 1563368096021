import { Col, NavLink, Row } from 'react-bootstrap';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { Tag, Timeline, Table } from 'antd';
import cx from 'classnames';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area
} from 'recharts';

import {
  CgArrowLongUp as UpIcon,
  CgArrowLongDown as DownIcon,
  CgLock as LockIcon,
  CgCheck as AcceptIcon,
  CgClose as DeclineIcon
} from 'react-icons/cg';

import { FaEye as ViewIcon } from 'react-icons/fa';

import style from './dashboard.module.scss';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { useQuery } from '@tanstack/react-query';
import axiosAPI from '../../../utils/client/axiosAPI';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const ChartData = [
    { name: 'Jan', prodato: 120000 },
    { name: 'Feb', prodato: 190000 },
    { name: 'Mar', prodato: 160000 },
    { name: 'Apr', prodato: 155000 },
    { name: 'Maj', prodato: 134000 },
    { name: 'Jun', prodato: 85000 },
    { name: 'Jul', prodato: 128000 },
    { name: 'Avg', prodato: 144000 },
    { name: 'Sep', prodato: 220000 },
    { name: 'Okt', prodato: 172000 },
    { name: 'Nov', prodato: 160000 },
    { name: 'Dec', prodato: 180000 }
  ];
  const chartData2 = [
    { name: 'Jan', vojno: 1200, civilno: 1000 },
    { name: 'Feb', vojno: 1900, civilno: 2200 },
    { name: 'Mar', vojno: 1600, civilno: 1900 },
    { name: 'Apr', vojno: 1550, civilno: 1700 },
    { name: 'Maj', vojno: 1340, civilno: 1600 },
    { name: 'Jun', vojno: 1000, civilno: 1200 },
    { name: 'Jul', vojno: 1280, civilno: 1100 },
    { name: 'Avg', vojno: 1440, civilno: 1250 },
    { name: 'Sep', vojno: 1800, civilno: 1400 },
    { name: 'Okt', vojno: 1720, civilno: 1900 },
    { name: 'Nov', vojno: 1600, civilno: 2250 },
    { name: 'Dec', vojno: 2200, civilno: 2500 }
  ];

  const OrderStatusClasses: { [key: string]: string } = {
    pending: 'cs-pending-status',
    accepted: 'cs-accepted-status',
    declined: 'cs-declined-status',
    rented: 'cs-rented-status',
    returned: 'cs-returned-status'
  };

  const { data: orders } = useQuery(['orders'], async () => {
    const { data } = await axiosAPI.get(`/orders`);
    return data;
  });
  const columns: ColumnsType<any> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Ime',
      key: 'user',
      render: (row) => <span>{row.full_name}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag
          className={cx(
            OrderStatusClasses[status] || 'cs-default-status',
            'cs-status-badge'
          )}
        >
          {status}
        </Tag>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <div className="cs-action-wrapper">
          <div className="acction-btn">
            <button onClick={(event) => navigate(`/admin/orders/${id}`)}>
              <ViewIcon />
            </button>
          </div>
        </div>
      )
    }
  ];

  return (
    <div>
      <h1 className="cs-title">Kontrolna Tabla</h1>
      <Row className={style.adminCardsRow}>
        <Col lg={4} sm={6}>
          <div className="cs-admin-card">
            <div className="cs-card-content">
              <div className="cs-card-title">Prodaja</div>
              <div className="cs-card-value">840.000 RSD</div>
              <div className="cs-card-info down">
                <span>-12%</span> smanjenje
              </div>
            </div>
            <div className="cs-card-right">
              <div className="cs-card-date">17 sep-17 Oct</div>
              <DownIcon />
            </div>
          </div>
        </Col>
        <Col lg={4} sm={6}>
          <div className="cs-admin-card">
            <div className="cs-card-content">
              <div className="cs-card-title">Prodaja</div>
              <div className="cs-card-value">120.000 RSD</div>
              <div className="cs-card-info up">
                <span>+23%</span> povećanje
              </div>
            </div>
            <div className="cs-card-right">
              <div className="cs-card-date">poslednja 24h</div>
              <UpIcon />
            </div>
          </div>
        </Col>
        <Col lg={4} sm={12}>
          <div className="cs-admin-card">
            <div className="cs-card-content">
              <div className="cs-card-title">Trenutno rentirano</div>
              <div className="cs-card-value">1200 proizvoda</div>
              <div className="cs-card-info up">
                <span>+84%</span> povećanje
              </div>
            </div>
            <div className="cs-card-right">
              <div className="cs-card-date">7 sep-7 Oct</div>
              <UpIcon />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-3 mb-5">
        <ButtonPrimary>Vidi sve</ButtonPrimary>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <h2 className={cx(style.subtitle, 'd-flex justify-content-center')}>
            Dinamika prodaje za 2022
          </h2>
          <LineChart
            width={800}
            height={300}
            data={ChartData}
            margin={{ top: 5, right: 10, bottom: 5, left: 20 }}
            className="mx-auto"
          >
            <Line type="monotone" dataKey="prodato" stroke="#dab94f" />
            <CartesianGrid
              stroke="#e9e9e9"
              opacity={0.2}
              strokeDasharray="3 3"
            />
            <XAxis dataKey="name" stroke="#dab94f" />
            <YAxis dataKey="prodato" stroke="#dab94f" />
            <Tooltip wrapperClassName="cs-tooltip-box" />
          </LineChart>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col lg={6}>
          <div className="cs-admin-card">
            <div className="cs-card-content">
              <div className="cs-card-title">Minimalna Prodaja</div>
              <div className="cs-card-value">128.000 RSD</div>
              {/*               <div className="cs-card-info down">
                <span>-12%</span> smanjenje
              </div> */}
            </div>
            <div className="cs-card-right">
              <div className="cs-card-date">Jul 2022</div>
              <DownIcon />
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="cs-admin-card">
            <div className="cs-card-content">
              <div className="cs-card-title">Maksimalna Prodaja</div>
              <div className="cs-card-value">220.000 RSD</div>
              {/*               <div className="cs-card-info down">
                <span>-12%</span> smanjenje
              </div> */}
            </div>
            <div className="cs-card-right">
              <div className="cs-card-date">Sep 2022</div>
              <UpIcon />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={7}>
          <h2 className={style.subtitle}>Poslednje Narudzbine</h2>
          <Table
            columns={columns}
            dataSource={orders}
            className="cs-table-wrapper cs-dashboard-order"
          ></Table>
        </Col>
        <Col lg={4}>
          <h2 className={style.subtitle}>Poslednje Aktivnosti</h2>
          <Timeline pending=" " className="cs-timeline">
            <Timeline.Item className="cs-login" dot={<LockIcon />}>
              Korisnik Marina login
            </Timeline.Item>
            <Timeline.Item className="cs-success" dot={<AcceptIcon />}>
              Prihvaćena porudzbinu #2214 od Marine
            </Timeline.Item>
            <Timeline.Item className="cs-login" dot={<LockIcon />}>
              Korisnik Marija login
            </Timeline.Item>
            <Timeline.Item className="cs-error" dot={<DeclineIcon />}>
              Korisnik Marija je odbio order #2215
            </Timeline.Item>
          </Timeline>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2 className={cx(style.subtitle, 'd-flex justify-content-center')}>
            Udeo prodaje Vojnih i Civilnih kostima
          </h2>
          <AreaChart
            width={800}
            height={300}
            data={chartData2}
            margin={{ top: 5, right: 10, bottom: 5, left: 20 }}
            className="mx-auto"
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#dab94f" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#dbc16b" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#014a47" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#014a47" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" stroke="#dab94f" />
            <YAxis stroke="#dab94f" />
            <CartesianGrid
              stroke="#e9e9e9"
              opacity={0.2}
              strokeDasharray="3 3"
            />
            <Tooltip wrapperClassName="cs-tooltip-box" />
            <Area
              type="monotone"
              dataKey="civilno"
              stroke="#dab94f"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="vojno"
              stroke="#014a47"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
