import { Form, Formik } from 'formik';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { RentPeriod } from '../../../models/RentPeriod/RentPeriod';
import { Col, FormGroup, FormLabel } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

function CreateRentPeriod() {
  return (
    <div>
      <h1 className="cs-title">Napravi periode za rentiranje</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Formik
        initialValues={{
          duration: 30,
          price_factor: 0,
          point_factor: 0
        }}
        validationSchema={Yup.object({
          duration: Yup.number()
            .integer()
            .min(1, 'duration.min error')
            .required('duration.required error'),
          price_factor: Yup.number()
            .min(1, 'Faktor cene mora da bude minimum 1')
            .required('price_factor.required error'),
          point_factor: Yup.number()
            .min(1, 'Faktor poena mora da bude minimum 1')
            .required('point_factor.required error')
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          RentPeriod.store(values)
            .then((rentPeriod) => {
              resetForm();
              toast('Snimljeno uspešno');
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" className="cs-form-wrapper">
            <FormGroup>
              <Col lg={6}>
                <FormLabel>
                  Dužina perioda <i>[dani]</i>
                </FormLabel>
                <InputField
                  placeholder="Trajanje (dani)"
                  name="duration"
                  type="number"
                  step={1}
                />
                <ErrorMessageLabel name="duration" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col lg={6}>
                <FormLabel>
                  Faktor cene <i>[cena X faktor]</i>
                </FormLabel>
                <InputField
                  placeholder="Procenat uvećanja cene"
                  name="price_factor"
                  type="number"
                  step={0.1}
                />
                <ErrorMessageLabel name="price_factor" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>
                  Faktor uvećanja poena <i>[poeni X faktor]</i>
                </FormLabel>
                <InputField
                  placeholder="Procenat uvećanja poena"
                  name="point_factor"
                  type="number"
                  step={0.1}
                />
                <ErrorMessageLabel name="point_factor" />
              </Col>
            </FormGroup>
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              Sačuvaj
            </ButtonPrimary>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateRentPeriod;
