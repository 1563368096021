import { useState } from 'react';
import { ProductType } from '../../../models/Product/contracts';
import CreateProductForm from './CreateProductForm';

import { Segmented } from 'antd';

function CreateProduct() {
  const [productType, setProductType] = useState(ProductType.SIMPLE);

  return (
    <>
      <h1 className="cs-title">Dodavanje proizvoda</h1>
      <h2 className="cs-subtitle">Izaberite Tip Proizvoda</h2>
      <Segmented
        options={[
          { label: 'Običan', value: ProductType.SIMPLE },
          { label: 'Varijabilan', value: ProductType.VARIABLE }
        ]}
        value={productType}
        onChange={(e: any) => {
          setProductType(e); // Zbunio sam se ovde nesto ko zna sta
        }}
        className="cs-segmented-wrapper"
      />
      {/* <Select
        className="cs-select-ant"
        value={productType}
        onChange={(value) => setProductType(value)}
      >
        <Select.Option value={ProductType.SIMPLE}>
          Normalan Proizvod
        </Select.Option>
        <Select.Option value={ProductType.VARIABLE}>
          Varijabilni Proizvod
        </Select.Option>
      </Select> */}
      <CreateProductForm productType={productType} />
    </>
  );
}

export default CreateProduct;
