import { User } from '../models/User/User';
import {
  MdAccessTime as MenuClock,
  MdSettingsSuggest as MenuSettings,
  MdLibraryAdd as MenuAdd,
  MdOutlineViewList as MenuList,
  MdOutlineStyle as MenuProject,
  MdOutlineCategory as MenuCategory,
  MdOutlineFilterAlt as MenuFilter,
  MdOutlineInvertColors as MenuAttribute,
  MdOutlineFeaturedPlayList as MenuFeature,
  MdOutlineAdminPanelSettings as MenuAdmin,
  MdOutlineShoppingBag as MenuOrders,
  MdManageAccounts as MenuUsers
} from 'react-icons/md';
import { GiClothes as MenuProduct } from 'react-icons/gi';

const links = [
  // {
  //   to: '/admin',
  //   label: 'Dashboard',
  //   icon: <MenuDashboard />,
  //   gate: (user: User | null) => {
  //     return !!user && user.hasRole('super-admin');
  //   }
  // },
  {
    to: '/admin/activity-log',
    label: 'Log Aktivnosti',
    icon: <MenuSettings />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  },
  {
    to: '/admin/configurations/update',
    label: 'Osnovne Konfiguracije',
    icon: <MenuSettings />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  },
  // {
  //   to: '/admin/users/',
  //   label: 'Korisnici',
  //   icon: <MenuUsers />,
  //   children: [
  //     {
  //       to: '/admin/user/create',
  //       label: 'Dodaj Korisnika',
  //       icon: <MenuAdd />,
  //       gate: (user: User | null) => {
  //         return !!user && user.hasRole('super-admin');
  //       }
  //     },
  //     {
  //       to: '/admin/user/list',
  //       label: 'Lista Korisnika',
  //       icon: <MenuList />,
  //       gate: (user: User | null) => {
  //         return !!user && user.hasRole('super-admin');
  //       }
  //     }
  //   ]
  // },
  {
    to: '/admin/rent-periods/',
    label: 'Period izdavanja',
    icon: <MenuClock />,
    children: [
      {
        to: '/admin/rent-periods/create',
        label: 'Kreiraj period izdavanja',
        icon: <MenuAdd />
      },
      {
        to: '/admin/rent-periods/list',
        label: 'Lista perioda izdavanja ',
        icon: <MenuList />
      }
    ],
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  },
  {
    to: '/admin/projects/',
    label: 'Projekti',
    icon: <MenuProject />,
    children: [
      {
        to: '/admin/projects/create',
        label: 'Kreiraj Projekat ',
        icon: <MenuAdd />
      },
      {
        to: '/admin/projects/list',
        label: 'Lista Projekata',
        icon: <MenuList />
      }
    ],
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  },
  {
    to: '/admin/categories/',
    label: 'Kategorije',
    icon: <MenuCategory />,
    children: [
      {
        to: '/admin/categories/create',
        label: 'Kreiraj Kategoriju',
        icon: <MenuAdd />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      },
      {
        to: '/admin/categories/list',
        label: 'Lista Kategorija',
        icon: <MenuList />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      }
    ]
  },
  {
    to: '/admin/products/',
    label: 'Proizvodi',
    icon: <MenuProduct />,
    children: [
      {
        to: '/admin/products/create',
        label: 'Kreiraj Proizvod ',
        icon: <MenuAdd />
      },
      {
        to: '/admin/products/list',
        label: 'Lista Proizvoda',
        icon: <MenuList />
      }
    ]
  },
  {
    to: '/admin/attributes/',
    label: 'Atributi',
    icon: <MenuAttribute />,
    children: [
      {
        to: '/admin/attributes/create',
        label: 'Kreiraj Atribut',
        icon: <MenuAdd />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      },
      {
        to: '/admin/attributes/list',
        label: 'Lista Atributa',
        icon: <MenuList />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      }
    ]
  },
  {
    to: '/admin/features/',
    label: 'Karakteristike',
    icon: <MenuFeature />,
    children: [
      {
        to: '/admin/features/create',
        label: 'Kreiraj Karakteristiku',
        icon: <MenuAdd />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      },
      {
        to: '/admin/features/list',
        label: 'Lista Karakteristika',
        icon: <MenuList />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      }
    ]
  },
  {
    to: '/admin/filters/',
    label: 'Filteri',
    icon: <MenuFilter />,
    children: [
      {
        to: '/admin/filters/create',
        label: 'Kreiraj Filter',
        icon: <MenuAdd />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      },
      {
        to: '/admin/filters/list',
        label: 'Lista Filtera',
        icon: <MenuList />,
        gate: (user: User | null) => {
          return !!user && user.hasRole('super-admin');
        }
      }
    ]
  },
  {
    to: 'admin-orders',
    label: 'Narudžbine',
    icon: <MenuOrders />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    },
    children: [
      {
        to: '/admin/orders',
        label: 'Lista Narudžbina',
        icon: <MenuList />
      },
      {
        to: '/admin/orders/create',
        label: 'Kreiraj Narudžbinu',
        icon: <MenuAdd />
      }
    ]
  },
  {
    to: 'admin-order-documents',
    label: 'Dokumenti',
    icon: <MenuOrders />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    },
    children: [
      {
        to: '/admin/order-documents',
        label: 'Lista Dokumenata',
        icon: <MenuList />
      },
      {
        to: '/admin/order-documents/create',
        label: 'Dodaj Dokument',
        icon: <MenuAdd />
      }
    ]
  },
  {
    to: '/admin',
    label: 'Admin',
    icon: <MenuAdmin />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  },
  {
    to: '/',
    label: 'Prodavnica',
    icon: <MenuOrders />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('super-admin');
    }
  }
  /*  {
    to: '/admin/super',
    label: 'Super',
    icon: <MenuSuperAdmin />,
    gate: (user: User | null) => {
      return !!user && user.hasRole('superuser');
    }
  } */
];

export default links;
