import { useEffect, useState } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { useCartContext } from '../../../context/CartContext/CartContext';
import ShopSideBar from '../../components/SideBar/ShopSideBar';
import style from './home.module.scss';
import ProductCard from '../../components/ProductCard/ProductCard';
import cx from 'classnames';
import {
  MdViewList as ListIcon,
  MdViewModule as GridIcon
} from 'react-icons/md';
import { Product } from '../../../models/Product/Product';
import { useQuery } from '@tanstack/react-query';
import { collect, Collection } from 'collect.js';
import { ProductAttribute } from '../../../models/Product/contracts';
import axiosAPI from '../../../utils/client/axiosAPI';
import { Select, TreeSelect } from 'antd';
import ShopPagination from '../../components/Pagination/ShopPagination';
import { Category } from '../../../models/Category/Category';
import CookieConsent from 'react-cookie-consent';
import CookiesConsent from '../../../components/CookiesConsent/CookiesConsent';

function Home(props: any) {
  const { cart } = useCartContext();

  const [pageSize, setPageSize] = useState(24);

  const initialValues = {
    start_date: cart?.startDate ?? '',
    end_date: cart?.endDate ?? ''
  };

  const [selectedCategories, setSelectedCategories] = useState<number[]>();
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: number]: number[];
  }>({});

  useEffect(() => {
    refetchProducts();
  }, [pageSize]);

  /** THIS IS MY JUNK -- BOJAN */
  // const [chooseDateModal, setChooseDateModal] = useState<boolean>(false);

  const [shopListView, setShopListView] = useState<boolean>(false);
  const { collapsedSidebar, setCollapsedSidebar } = props;

  // const handleClose = () => setChooseDateModal(false);
  // const handleShow = () => setChooseDateModal(true);

  // useEffect(() => {
  //   setChooseDateModal(true);
  // }, []);

  const [page, setPage] = useState(1);

  const { data: filters } = useQuery(['filters'], async () => {
    const { data } = await axiosAPI.get('/product/filters');
    return data;
  });

  const filtersKey = Object.values(selectedFilters).flat().join(',');
  const categoryKey = selectedCategories?.join(',');

  const { data: productsResponse, refetch: refetchProducts } = useQuery(
    ['products', filtersKey, categoryKey, page, pageSize],
    () =>
      Product.all({
        filters: filtersKey,
        page,
        page_size: pageSize,
        categories: selectedCategories
      }),
    {}
  );

  const { data: categories } = useQuery(['filter-categories'], () =>
    Category.all()
  );

  return (
    <div className={style.pageContainer}>
      <ShopSideBar
        collapsed={collapsedSidebar}
        setCollapsed={setCollapsedSidebar}
        heading="Filteri"
      >
        <>
          <FormGroup className="cs-filter-group">
            <FormLabel>Kategorija</FormLabel>
            <TreeSelect
              dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
              showSearch
              treeNodeFilterProp="title"
              treeDefaultExpandAll
              placeholder="Izaberi kategoriju"
              treeData={categories ?? []}
              multiple
              onChange={(value) => {
                setSelectedCategories(value);
              }}
              className="cs-tree-select"
            />
          </FormGroup>
          {filters?.map(({ id, name, options }: any) => {
            return (
              <FormGroup className="cs-filter-group" key={id}>
                <FormLabel>{name}</FormLabel>
                <Select
                  placeholder={`Select ${name}`}
                  mode="multiple"
                  onChange={(values) => {
                    setSelectedFilters((selectedFilters) => ({
                      ...selectedFilters,
                      [id]: values
                    }));
                  }}
                >
                  {options.map(({ id, value }: any) => {
                    return <Select.Option key={id}>{value}</Select.Option>;
                  })}
                </Select>
              </FormGroup>
            );
          })}
        </>
      </ShopSideBar>

      <div
        className={cx(
          style.shopPageWrapper,
          shopListView ? style.list : null,
          collapsedSidebar ? style.collapsed : null,
          'cs-content'
        )}
      >
        <div className={style.pageHeaderContainer}>
          <h1>Katalog kostima</h1>
          <p>
            Nudimo vam najam kostima i rekvizita iz bogate kolekcije Fundusa
            Avala studios d.o.o. Beograd. Ponuda obuhvata različite epohe koje
            možete pronaći u našem katalogu na ovom sajtu. Izborom odgovarajućih
            proizvoda iz naše ponude, u mogućnosti ste da izvršite rezervaciju
            željenih artikala koje želite da iznajmite.
          </p>
        </div>
        <div className={style.topBar}>
          <div className={style.left}>
            <div className={style.infoContainer}>
              Prikazano{' '}
              <span>
                {productsResponse?.pagination.count ?? 0}/
                {productsResponse?.pagination.total ?? 0}
              </span>{' '}
              proizvoda
            </div>
            <div className={style.selectContainer}>
              <Select
                defaultValue="24"
                className="cs-select"
                onChange={(val) => {
                  setPageSize(parseInt(val));
                }}
                options={[
                  { value: 12, label: '12' },
                  { value: 24, label: '24' },
                  { value: 36, label: '36' },
                  { value: 48, label: '48' }
                ]}
              />
            </div>
          </div>
          <div className={cx(style.right, shopListView ? style.active : null)}>
            <ListIcon
              className={shopListView ? style.active : ''}
              onClick={() => setShopListView(true)}
            />
            <GridIcon
              className={!shopListView ? style.active : ''}
              onClick={() => setShopListView(false)}
            />
          </div>
        </div>
        <div className={style.content}>
          {productsResponse?.data.map(
            ({ id, name, price, attributes, images }) => (
              <ProductCard
                key={id}
                id={id}
                image={images?.at(0) ?? ''}
                name={name}
                price={price}
                stock={0}
                url={`/product/${id}`}
                attributes={collect(attributes ?? {})
                  .groupBy<Collection<ProductAttribute>, string>('name')
                  .map((group) => group.toArray())}
                listView={shopListView}
              ></ProductCard>
            )
          )}
        </div>
        <div className={style.pagination}>
          {!!productsResponse?.pagination && (
            <ShopPagination
              pagination={productsResponse?.pagination}
              onChange={(page) => {
                setPage(page);
              }}
            />
          )}
        </div>
        <CookiesConsent />
      </div>
    </div>
  );
}

export default Home;
