import { useEffect, useMemo } from 'react';
import style from './sidebar.module.scss';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Menu, MenuProps } from 'antd';
import { Gate } from '../../../interfaces/Gate';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/use-window-dimensions';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

interface NavBarLink {
  to: string;
  label: string;
  //icon: React.ReactNode;
  gate?: Gate;
}

interface SidebarProps {
  collapsed: boolean;
  setCollapsed: (value: boolean | ((value: boolean) => boolean)) => void;
  links: NavBarLink[];
}

function Sidebar({ collapsed, setCollapsed, links }: SidebarProps) {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const menuItems = useMemo((): MenuItem[] => {
    if (!user) {
      return [];
    }
    const helper = (links: any[]): MenuItem[] => {
      const result: MenuItem[] = [];
      links.forEach(
        ({ to, gate = () => true, label, icon, children = null }) => {
          if (!gate(user)) {
            return;
          }

          result.push(
            getItem(
              label,
              to,
              icon,
              children !== null ? helper(children) : void 0
            )
          );
        }
      );

      return result;
    };

    return helper(links);
  }, [user, links]);

  const window = useWindowDimensions();

  useEffect(() => {
    if (!window) {
      return;
    }

    setCollapsed(window.width <= 980);
  }, [window?.width]);
  return (
    <div
      className={cx(
        style.sidebarWrapper,
        collapsed ? style.collapsed : null,
        'cs-sidebar'
      )}
    >
      <div className={style.header}>
        <div className={style.logo} onClick={() => navigate('/admin')}>
          <img src="/assets/logo1.png" alt="logo" />
        </div>
        <div className={style.info}>
          <h4>{user?.name}</h4>
          <p>{user?.email}</p>
        </div>
      </div>
      <Scrollbars className={style.scrollbarWrapper}>
        <div className={cx(style.sidebarContent)}>
          <Menu
            onClick={({ key }) => {
              navigate(key);
            }}
            mode="inline"
            theme="dark"
            items={menuItems}
            inlineCollapsed={collapsed}
            className="cs-admin-menu"
          />
        </div>
      </Scrollbars>
      <div
        className={style.collapsButton}
        onClick={() => setCollapsed((collapsed) => !collapsed)}
      >
        {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
      </div>
    </div>
  );
}
export default Sidebar;
