import { Routes, Route, NavLink } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { AuthActionType } from '../../../context/AuthContext/AuthReducer';
import { useNavigate } from 'react-router-dom';

import { useCartContext } from '../../../context/CartContext/CartContext';
import style from './header.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import {
  MdOutlineSearch as SearchIcon,
  MdAutoGraph as HeaderPoints,
  MdOutlineShoppingBag as HeaderCart,
  MdNotificationsActive as HeaderBell,
  MdHome as HeaderShop,
  MdLogout as HeaderLogout,
  MdLockOutline as HeaderLogin,
  MdOutlineAdminPanelSettings as MenuAdmin
} from 'react-icons/md';

import {
  FaUserCog as HeaderUser,
  FaCog as HeaderSettings
} from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOrdersList } from '../../../api/orders/queries';
import { OrderStatus } from '../../../api/orders/types';

export default function Header(att: any) {
  const { user, dispatch } = useAuthContext();
  const [pendingOrders, setPendingOrders] = useState<number>();

  const { data: orders } = useQuery(['orders'], getOrdersList);

  useEffect(() => {
    if (orders) {
      const pendingOrdersCount = orders.filter(
        (order) => order.status === OrderStatus.PENDING
      ).length;
      setPendingOrders(pendingOrdersCount);
    }
  }, [orders]);

  const navigate = useNavigate();

  return (
    /* {cart?.hasStartEndDates && ( */
    <div className={style.adminHeader}>
      <div></div>
      <div>
        {/* <button
          className={style.headerButton}
          onClick={() =>
            setMinicartOpened((minicartOpened: boolean) => !minicartOpened)
          }
        >
          <HeaderCart />
        </button>*/}
        <button className={style.headerButton}>
          <HeaderShop
            onClick={() => {
              navigate('/admin');
            }}
          />
        </button>
        <button
          className={style.headerButton}
          onClick={() => navigate('/admin/orders')}
        >
          <HeaderBell />
          <div className={style.notification}>
            <span>{pendingOrders} </span>
          </div>
        </button>

        {(!!user && (
          <Dropdown className={style.dropdownButton}>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <HeaderUser />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <p className={style.dropdownTitle}>{user?.name}</p>
              <p className={style.dropdownEmail}>{user?.email}</p>
              <div
                className={style.dropdownLinks}
                onClick={() => navigate('/admin')}
              >
                <span>
                  <MenuAdmin />
                </span>
                <span>Admin Panel</span>
              </div>
              <div className={style.dropdownLinks}>
                <span>
                  <HeaderSettings />
                </span>
                <span>Settings</span>
              </div>
              <div
                className={style.dropdownLinks}
                onClick={() => {
                  dispatch({ type: AuthActionType.LOGOUT });
                }}
              >
                <span>
                  <HeaderLogout />
                </span>
                <span>Logout</span>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )) || (
          <NavLink to="/login" className={style.headerButton}>
            <HeaderLogin />
          </NavLink>
        )}
      </div>
    </div>
    /*  )} */
  );
}
