import { FaCheck } from 'react-icons/fa';
import style from './thank-you.module.scss';
import cx from 'classnames';
import { HTMLAttributes, PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { CgArrowLongLeft as PreviousIcon } from 'react-icons/cg';
import { useCartContext } from '../../../context/CartContext/CartContext';

interface ThankYouProps extends HTMLAttributes<HTMLDivElement> {
  setNoSideBar: (value: boolean | ((value: boolean) => boolean)) => void;
}

export default function ThankYou({
  setNoSideBar,
  ...props
}: PropsWithChildren<ThankYouProps>) {
  //setNoSideBar(true); TREBALO BI DA SE RESETUJE KAD ODE BILO GDE DRUGDE
  const { user, dispatch } = useAuthContext();
  const { cartClear } = useCartContext();

  useEffect(() => {
    cartClear();
  }, []);

  return (
    <div {...props} className={cx(style.wrapper, 'cs-content no-sidebar')}>
      <div className={style.icon}>
        <FaCheck />
      </div>
      <h1 className="title">Hvala na porudžbini {user?.name} !</h1>
      <p>
        Vaša porudžbina je uspešno poslata očekujte odgovor na{' '}
        <span className="cs-primary-color">{user?.email}</span>
      </p>
      <ButtonPrimary url={'/'}>
        <PreviousIcon className={style.buttonIcon} />
        Prodavnica
      </ButtonPrimary>
    </div>
  );
}
