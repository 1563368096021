import { useEffect, useMemo, useState } from 'react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Image,
  ImageWithZoom,
  Slide,
  Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import cx from 'classnames';
import style from './singleProduct.module.scss';
import ShopSideBar from '../../components/SideBar/ShopSideBar';
import AttributeGroup from '../../components/Attributes/AttributeGroup';
import Accordion from 'react-bootstrap/Accordion';

import AddToCartButton from '../../components/Cart/AddToCartButton';

import { FaPlus as PlusIcon, FaMinus as MinusIcon } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Product } from '../../../models/Product/Product';
import collect, { Collection } from 'collect.js';
import { ProductAttribute } from '../../../models/Product/contracts';
import { ProductType } from '../../../models/Product/contracts';

interface ProductDetailsProps {
  product: Product;
  setSelectedProduct: (product: Product) => void;
}

function CompositeProduct({}: ProductDetailsProps) {
  return <>CompositeProduct</>;
}

function VariableProduct({ product, setSelectedProduct }: ProductDetailsProps) {
  const [selectedAttributeValues, setSelectedAttributeValues] = useState<{
    [key: string]: number;
  }>({});

  const attributes = useMemo(
    () =>
      collect(product.attributes ?? [])
        .groupBy<Collection<ProductAttribute>, string>('name')
        .map<ProductAttribute[]>((attributes) => attributes.toArray()),
    [product]
  );

  const variations = useMemo(() => {
    const variationMap = new Map<string, any>();
    product?.variations?.forEach((variation) => {
      variationMap.set(
        variation.attributes
          ?.map(({ id }: any) => id)
          .sort()
          .join('-') ?? '',
        variation
      );
    });
    return variationMap;
  }, [product?.variations]);

  useEffect(() => {
    const key = Object.values(selectedAttributeValues).sort().join('-');
    if (!variations.has(key)) {
      // No combination, restore previous state or something?
      return;
    }
    setSelectedProduct(variations.get(key));
  }, [selectedAttributeValues]);

  return (
    <>
      {attributes
        .map((attributes, key) => (
          <AttributeGroup
            key={key}
            title={key}
            attributes={attributes}
            isCard={false}
            onSelect={({ name, id }) => {
              setSelectedAttributeValues((attributeValues) => {
                return {
                  ...attributeValues,
                  [name]: id
                };
              });
            }}
          />
        ))
        .toArray()}
    </>
  );
}

function SimpleProduct({ product, setSelectedProduct }: ProductDetailsProps) {
  useEffect(() => {
    setSelectedProduct(product);
  }, [product]);

  const attributes = useMemo(
    () =>
      collect(product.attributes ?? [])
        .groupBy<Collection<ProductAttribute>, string>('name')
        .map<ProductAttribute[]>((attributes) => attributes.toArray()),
    []
  );

  return (
    <>
      {attributes
        .map((attributes, key) => (
          <AttributeGroup
            key={key}
            title={key}
            attributes={attributes}
            isCard={true}
          />
        ))
        .toArray()}
    </>
  );
}

function ProductDetailsFactory({ product, ...props }: ProductDetailsProps) {
  switch (product.type) {
    case ProductType.SIMPLE:
      return <SimpleProduct product={product} {...props} />;
    case ProductType.VARIABLE:
      return <VariableProduct product={product} {...props} />;
    // case 'composite':
    //   return <CompositeProduct product={product} {...props} />;
  }

  throw new Error(
    `Factory Error: Failed to create product component of type "${product?.type}"`
  );
}

function SingleProduct({ collapsedSidebar, setCollapsedSidebar }: any) {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { id } = useParams();
  const { data: product, isLoading } = useQuery(
    ['product', id],
    () => Product.get(id as string),
    {
      enabled: !!id
    }
  );

  return (
    <>
      <ShopSideBar
        collapsed={collapsedSidebar}
        setCollapsed={setCollapsedSidebar}
        // renderItems={renderSideBar}
        heading="Dodatne informacije"
      >
        <>
          {selectedProduct?.features?.map((item: any) => (
            <div key={item.id} className={style.sidebarItem}>
              <div className={style.sidebarTitle}>{item.name}:</div>
              <div className={style.sidebarValue}>{item.value}</div>
            </div>
          ))}
        </>
      </ShopSideBar>

      {isLoading ? (
        <>...</>
      ) : (
        <div className="cs-content">
          <div className={style.wrapper}>
            <div className={style.gallery}>
              <CarouselProvider
                visibleSlides={1}
                totalSlides={5}
                naturalSlideWidth={300}
                naturalSlideHeight={400}
                className={style.productCarousel}
              >
                {/* Main product images */}
                <div className={style.thumbs}>
                  {product?.images?.map((item, index) => (
                    <Dot
                      key={index}
                      slide={index}
                      className={cx(
                        style.paginationButton,
                        currentSlide === index ? style.selected : ''
                      )}
                      onClick={() => setCurrentSlide(index)}
                    >
                      <Image src={item} hasMasterSpinner={false} />
                    </Dot>
                  ))}
                </div>
                <Slider className={style.mainSlider}>
                  {product?.images?.map((item, index) => (
                    <Slide key={index} index={index}>
                      <ImageWithZoom src={item} />
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </div>
            <div className={style.info}>
              <h4 className={style.subtitle}>
                {selectedProduct?.category?.name}
              </h4>
              <h1 className={style.title}>{selectedProduct?.name}</h1>
              {/* <p className={style.description}>{selectedProduct?.sku}</p> */}
              {/* <div className={style.price}>
                {selectedProduct?.price}&nbsp;
                {process.env.REACT_APP_DEFAULT_CURRENCY}
              </div> */}
              <div className={style.productDetails}>
                {!!product && (
                  <ProductDetailsFactory
                    product={product}
                    setSelectedProduct={setSelectedProduct}
                  />
                )}
              </div>
              {!!selectedProduct && (
                <AddToCartButton product={selectedProduct!} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleProduct;
