import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import ShopSideBar from '../../components/SideBar/ShopSideBar';
import style from './privacy-policy.module.scss';
import cx from 'classnames';
import { CgArrowLongLeft as LeftArrow } from 'react-icons/cg';

interface PrivacyPolicyProps {
  collapsedSidebar: boolean;
}

function PrivacyPolicy(props: any) {
  const { collapsedSidebar, setCollapsedSidebar } = props;

  const navigate = useNavigate();

  return (
    <div className={style.pageContainer}>
      <ShopSideBar
        collapsed={collapsedSidebar}
        setCollapsed={setCollapsedSidebar}
        heading=""
      ></ShopSideBar>

      <div
        className={cx(
          style.shopPageWrapper,
          collapsedSidebar ? style.collapsed : null,
          'cs-content'
        )}
      >
        <h1>COOKIES I DRUGE SLIČNE TEHNOLOGIJE</h1>
        <p>
          Prilikom posete našoj internet stranici i navigiranja kroz sadržaj na
          istoj, prikupljamo određene primarno tehničke podatke putem „kolačića“
          (tzv. cookies) i drugih sličnih tehnologija, koji se pod određenim
          uslovima mogu smatrati podacima o ličnosti. Ti podaci uključuju: IP
          adresu, podatke o uređaju sa koga pristupate internet stranici, vrsti
          i verziji vašeg pretraživača, datumu i vremenu pristupa, o sadržaju
          koji pretražujete na našoj internet stranici, vremenu zadržavanja, kao
          i koje linkove koristite i druge tehničke podatke. Kada prvi put
          posećujete našu internet stranicu, imate mogućnost da odaberete koji
          „kolačići“ će biti korišćeni, odnosno da date ili odbijete davanje
          saglasnosti za korišćenje određenih kategorija „kolačića“ (izuzev
          neophodnih „kolačića“).
        </p>

        <h2 className={style.subTitle}>Neophodni „kolačića“</h2>
        <p>
          Ove „kolačiće“ koristimo da bismo omogućili funkcionalnost naše
          internet stranice i pristup sigurnim delovima internet stranice
          (uključujući i zaštitu internet stranice, otkrivanje i sprečavanje
          napada) – što sve čini naš legitimni interes i osnov za obradu
          određenih podataka u tom slučaju (propisan članom 12. stav 1. tačka 6)
          Zakona, odnosno članom 6. stav 1(f) GDPR). U svakom slučaju, vi možete
          podesiti svoj internet pretraživač tako da blokira ove „kolačiće“ ili
          da vas upozori na njih, ali u tom slučaju je moguće da neki delovi
          internet stranice neće raditi.
        </p>

        <h2 className={style.subTitle}>„Kolačići“ performansi</h2>
        <p>
          Ove „kolačiće“ koristimo kako bismo unapredili funkcionalnost naše
          internet stranice kroz identifikaciju i lociranje problema, rešavanje
          problema i otklanjanje grešaka u funkcionisanju internet stranice. U
          ovom slučaju, osnov za obradu podataka je isključivo vaš pristanak.
        </p>

        <h2 className={style.subTitle}>Analitički „kolačići“</h2>
        <p>
          Ove „kolačiće“ koristimo kako bismo dobili informacije o vašoj
          interakciji sa našom internet stranicom (broj posetilaca, zadržavanje
          na internet stranici, koji delovi internet stranice su posećeni), a
          sve radi unapređenja sadržaja koji vam nudimo i prilagođavanja istog
          vašim potrebama i interesovanjima. U ovom slučaju, osnov za obradu
          podataka je isključivo vaš pristanak.
        </p>

        <h2 className={style.subTitle}>Marketinški „kolačići“</h2>
        <p>
          Ovi „kolačići“ se koriste za praćenje posetilaca na internet
          stranicama u cilju prikazivanja reklamnih i oglasnih poruka koje su
          prilagođene interesovanjima i potrebama pojedinačnog korisnika.
          Marketinški „kolačići“ su postavljeni na našoj internet stranici od
          strane partnerskih internet stranica za oglašavanje kao što su Google,
          Facebook, Instagram, LinkedIn, koji ih koriste kako bi kreirali profil
          vaših interesovanja i prikazali vam relevantne reklamne sadržaje na
          drugim internet stranicama. U ovom slučaju, osnov za obradu podataka
          je isključivo vaš pristanak.
        </p>

        <p>
          U svakom trenutku imate mogućnost da u okviru podešavanja vašeg
          internet pretraživača, izmenite preferencije u pogledu „kolačića“ koje
          internet stranica može da koristi.
        </p>
        <ButtonPrimary onClick={() => navigate('/')}>
          <LeftArrow className={style.icon} /> Nazad na prodavnicu
        </ButtonPrimary>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
