import { AttributeTypes } from '../../Shop/components/Attributes/AttributeValue';
import { Attribute } from './Attribute';
import { AttributeValueResponse } from './contracts';

export class AttributeValue {
  public readonly attribute: Attribute;
  public readonly id: number;
  public readonly value: string;
  public readonly settings: any;
  public readonly filterValues: any[];
  public readonly type: AttributeTypes;

  constructor(
    attribute: Attribute,
    { id, value, filter_values, settings, type }: AttributeValueResponse
  ) {
    this.attribute = attribute;
    this.id = id;
    this.value = value;
    this.filterValues = filter_values;
    this.settings = settings;
    this.type = type;
  }
}
