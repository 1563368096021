import axiosAPI from '../../utils/client/axiosAPI';

export enum UserRoleTypes {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  MANAGER = 'manager',
  CUSTOMER = 'customer'
}

export enum UserCustomerType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual'
}

export interface UserRole {
  id: number;
  value: UserRoleTypes;
}

export interface UserResponse {
  id: number;
  name: string;
  email: string;

  readonly type: string;
  readonly id_number?: string;
  readonly tax_id?: string;

  roles?: string[];
  permissions?: string[];
}

export interface UserRegisterPayload {
  name: string;
  email: string;
  password: string;
  cart_token?: string;
  type?: string;
  id_number?: string;
  tax_id?: string;
}

export interface UserLoginPayload {
  email: string;
  password: string;
  cart_token?: string;
}

export class User {
  public readonly id: number;
  public readonly name: string;
  public readonly email: string;
  public readonly type: string;
  public readonly idNumber?: string;
  public readonly taxId?: string;

  protected readonly authorization: { roles: string[]; permissions: string[] };
  protected token: string;

  public constructor({
    id,
    name,
    email,
    type,
    id_number,
    tax_id,
    roles,
    permissions
  }: UserResponse) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.type = type;
    this.idNumber = id_number;
    this.taxId = tax_id;
    this.authorization = {
      roles: roles ?? [],
      permissions: permissions ?? []
    };
    this.token = '';
  }

  public setToken(token: string): User {
    this.token = token;
    return this;
  }

  public getToken(): string {
    return this.token;
  }

  public hasRole(role: string): boolean {
    return true;
    return this.hasAuthorization('roles', role);
  }

  public hasAnyRole(roles: string[]): boolean {
    return true;
    return this.hasAnyAuthorization('roles', roles);
  }

  public hasRoles(roles: string[]): boolean {
    return true;
    return this.hasAuthorizations('roles', roles);
  }

  public can(permission: string): boolean {
    return true;
    return this.hasAuthorization('permissions', permission);
  }

  public canAny(permissions: string[]): boolean {
    return true;
    return this.hasAuthorizations('permissions', permissions);
  }

  public canAll(permissions: string[]): boolean {
    return true;
    return this.hasAuthorizations('permissions', permissions);
  }

  public static clone(user: User): User {
    return new User({
      id: user.id,
      name: user.name,
      email: user.email,

      type: user.type,
      id_number: user.idNumber,
      tax_id: user.taxId,

      roles: user.authorization.roles.slice(),
      permissions: user.authorization.permissions.slice()
    }).setToken(user.token);
  }

  public static async register(payload: UserRegisterPayload): Promise<User> {
    const {
      data: { user, token }
    } = await axiosAPI.post('/auth/register', payload);
    return new User(user).setToken(token);
  }

  public static async login(payload: UserLoginPayload): Promise<User> {
    const {
      data: { user, token }
    } = await axiosAPI.post('/auth/login', payload);
    return new User(user).setToken(token);
  }

  public static async fetchByToken(token: string): Promise<User> {
    const { data } = await axiosAPI.get('/user', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return new User(data).setToken(token);
  }

  public static async getAllRoles(): Promise<UserRole[]> {
    //TODO get all roles
    const { data } = await axiosAPI.get('/user/roles', {});
    return data;
  }

  protected hasAuthorization(
    key: 'roles' | 'permissions',
    authorization: string
  ): boolean {
    return this.authorization[key].includes(authorization);
  }

  protected hasAnyAuthorization(
    key: 'roles' | 'permissions',
    authorizations: string[]
  ): boolean {
    return this.authorization[key].some(
      Set.prototype.has,
      new Set(authorizations)
    );
  }

  protected hasAuthorizations(
    key: 'roles' | 'permissions',
    authorizations: string[]
  ): boolean {
    return this.authorization[key].every(
      Set.prototype.has,
      new Set(authorizations)
    );
  }
}
