import { FilterValue } from '../Filter/FilterValue';
import { AddonValueResponse } from './contracts';

export class AddonValue {
  public readonly id: number;
  public readonly value: string;

  constructor({ id, value }: AddonValueResponse) {
    this.id = id;
    this.value = value;
  }
}
