import { HTMLAttributes, PropsWithChildren, useState } from 'react';
import style from './shopSideBar.module.scss';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';

interface ShopSideBarProps extends HTMLAttributes<HTMLDivElement> {
  collapsed: boolean;
  setCollapsed: (value: boolean | ((value: boolean) => boolean)) => void;
  heading: string;
}

function ShopSideBar({
  collapsed,
  setCollapsed,
  heading,
  children,
  ...props
}: PropsWithChildren<ShopSideBarProps>) {
  const navigation = useNavigate();
  return (
    <div
      {...props}
      className={cx(
        style.sidebarWrapper,
        collapsed ? style.collapsed : null,
        'cs-sidebar'
      )}
    >
      <div className={style.header}>
        <div className={style.logo} onClick={() => navigation('/')}>
          <img src="/assets/logo1.png" alt="logo" />
        </div>
      </div>
      <Scrollbars className={style.scrollbarWrapper}>
        <div className={cx(style.sidebarContent)}>
          <h2 className={style.sidebarHeading}>{heading}</h2>
          {children}
        </div>
      </Scrollbars>
      <div
        className={style.collapsButton}
        onClick={() => setCollapsed((collapsed) => !collapsed)}
      >
        {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
      </div>
    </div>
  );
}
export default ShopSideBar;
