import { Form, Formik } from 'formik';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { Project } from '../../../models/Project/Project';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

function CreateProject() {
  return (
    <>
      <Row>
        <Col lg={6}>
          <h1 className="cs-title">Napravi Projekat</h1>
          <p className="cs-body">
            Napravite cenovne projekat, kada porudžbina dostigne određeni broj
            poena cena se menja u predodređenu cenu.{' '}
          </p>
        </Col>
      </Row>
      <ToastContainer className="cs-toast-wrapper" />
      <Formik
        initialValues={{
          name: '',
          price: 0,
          duration: 0,
          points_required: 0
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Projekat mora imati naziv'),
          duration: Yup.number()
            .integer()
            .min(0, 'Projekat mora imati period trajanja')
            .required('Unesite vreme trajanja projekta'),
          price: Yup.number().integer().required('Unesite cenu projekta'),
          points_required: Yup.number()
            .min(0, 'Projekat mora zahtevati minimalan broj poena')
            .required('Unesite broj poena potrebnih za projekat')
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          Project.store(values)
            .then((project) => {
              resetForm();
              toast('Save success');
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" className="cs-form-wrapper">
            <FormGroup>
              <Col lg={6}>
                <FormLabel>Naziv projekta</FormLabel>
                <InputField
                  placeholder="Unesite naziv projekta"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessageLabel name="name" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>Trajanje projekta</FormLabel>
                <InputField
                  placeholder="Duration (days)"
                  name="duration"
                  type="number"
                  step={1}
                />
                <ErrorMessageLabel name="duration" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>Cena</FormLabel>
                <InputField
                  placeholder="Cena"
                  name="price"
                  type="number"
                  step={1}
                />
                <ErrorMessageLabel name="duration" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>Broj potrebnih poena</FormLabel>
                <InputField
                  placeholder="Points Required"
                  name="points_required"
                  type="number"
                  step={0.1}
                />
                <ErrorMessageLabel name="points_required" />
              </Col>
            </FormGroup>
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              Sačuvaj
            </ButtonPrimary>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateProject;
