import { CartProductResponse } from '../../../models/Cart/Cart';
import { UserCustomerType } from '../../../models/User/User';
import axiosAPI from '../../../utils/client/axiosAPI';

export type CreateOrderPayload = {
  cart_token: string;
  products: number[];
  full_name: string;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  phone: string;
  state: string;
  city: string;
  address: string;
  zip: number | string;
  note: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
};

export type OrderResponse = {
  id: number;
  products: number[];
  full_name: string;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  phone: string;
  state: string;
  city: string;
  address: string;
  zip: number | string;
  note: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
};

// 'note' => $request->input('note'),
// 'full_name' => $request->input('full_name'),
// 'type' => $request->input('type'),
// 'company_name' => $request->input('company_name'),
// 'company_tax_number' => $request->input('company_tax_number'),
// 'company_id_number' => $request->input('company_id_number'),
// 'email' => $request->input('email'),
// 'phone' => $request->input('phone'),
// 'state' => $request->input('state'),
// 'city' => $request->input('city'),
// 'address' => $request->input('address'),
// 'zip' => $request->input('zip'),
// 'reservation_start' => $request->input('reservation_start'),
// 'reservation_end' => $request->input('reservation_end')

export async function createOrderCheckout(payload: CreateOrderPayload) {
  const { data } = await axiosAPI.post(`/orders`, payload);

  return data;
}
