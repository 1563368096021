import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table/interface';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import { Attribute } from '../../../models/Attribute/Attribute';
import { FaEdit as EditIcon } from 'react-icons/fa';

import style from './attribute-list.module.scss';

export default function AttributeList() {
  const { data: attributes } = useQuery(['attributes'], () => Attribute.all());

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Naziv',
      dataIndex: 'name'
    },
    {
      title: 'Akcije',
      render({ id }) {
        return (
          <Link to={`/admin/attributes/edit/${id}`}>
            <EditIcon />
          </Link>
        );
      }
    }
  ];

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title">Lista Atributa</h1>
      <div className={style.info}>
        <div className={style.number}>
          Trenutno imate <span>{attributes?.length}</span> atributa
        </div>
        <ButtonPrimary url="/admin/attributes/create">
          Dodaj Atribut
        </ButtonPrimary>
      </div>
      <Table
        columns={columns}
        dataSource={attributes}
        className="cs-table-wrapper"
        rowKey={'id'}
      />
    </div>
  );
}
