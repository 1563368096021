import { AttributeResponse, AttributeStorePayload } from './contracts';
import { AttributeValue } from './AttributeValue';
import axiosAPI from '../../utils/client/axiosAPI';

export class Attribute {
  public readonly id: number;
  public readonly name: string;
  public readonly categories?: any[];
  public readonly options?: AttributeValue[];

  public constructor({ id, name, categories, options }: AttributeResponse) {
    this.id = id;
    this.name = name;
    this.categories = categories;
    this.options = options?.map((data) => new AttributeValue(this, data));
  }

  public static async all(...relations: string[]): Promise<Attribute[]> {
    const { data } = await axiosAPI.get<AttributeResponse[]>(
      relations.length === 0
        ? '/attributes'
        : `/attributes?with=${relations.join(',')}`
    );
    return data.map((data) => new Attribute(data));
  }

  public static async store(
    payload: AttributeStorePayload
  ): Promise<Attribute> {
    const { data } = await axiosAPI.post<AttributeResponse>(
      '/attributes',
      payload
    );
    return new Attribute(data);
  }

  public static toMap(attributes: Attribute[]): Map<number, Attribute> {
    const result = new Map<number, Attribute>();
    attributes.forEach((attribute) => {
      result.set(attribute.id, attribute);
    });
    return result;
  }

  public static toValueMap(
    attributes: Attribute[]
  ): Map<number, AttributeValue> {
    const result = new Map<number, AttributeValue>();
    attributes.forEach(({ options }) => {
      options?.forEach((option) => {
        result.set(option.id, option);
      });
    });
    return result;
  }

  public static async get(id: string | number): Promise<Attribute> {
    const { data } = await axiosAPI.get<AttributeResponse>(`/attributes/${id}`);
    return new Attribute(data);
  }

  public async update(payload: AttributeStorePayload): Promise<any> {
    return axiosAPI.put(`/attributes/${this.id}`, payload);
  }
}
