import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import axiosAPI from '../../../../utils/client/axiosAPI';
import style from './single-order.module.scss';
import {
  // FaCheck as AcceptIcon,
  // FaTimes as DeclinedIcon,
  // FaFilePdf as PdfIcon,
  // FaPrint as PrintIcon,
  // FaEnvelope as EmailIcon,
  FaFileDownload as DownloadIcon
} from 'react-icons/fa';
import cx from 'classnames';
import { useMemo } from 'react';
import { Product } from '../../../../models/Product/Product';
import { currencyFormat } from '../../../../utils/helpers/helpers';
import { DocumentDownloadType, downloadDocument } from './queries';

const OrderStatusClasses: { [key: string]: string } = {
  pending: 'cs-pending-status',
  accepted: 'cs-accepted-status',
  declined: 'cs-declined-status',
  'client-accepted': 'cs-accepted-status',
  'client-declined': 'cs-declined-status',
  rented: 'cs-rented-status',
  returned: 'cs-returned-status'
};

const StatusActions: {
  [key: string]: Array<{ status: string; label: string }>;
} = {
  pending: [
    { status: 'accepted', label: 'Accept' },
    { status: 'declined', label: 'Decline' }
  ],
  accepted: [
    {
      status: 'client-accepted',
      label: 'Ova ponuda nije jos prihvacena od klijenta.'
    }
  ],
  'client-accepted': [{ status: 'rented', label: 'Rent' }],
  'client-declined': [
    {
      status: 'client-declined',
      label: 'Ponuda je odbijena od strane klijenta.'
    }
  ],
  rented: [{ status: 'returned', label: 'Return' }]
};

function SingleOrder() {
  const { id } = useParams();
  const { data: order, refetch } = useQuery(
    ['order', id],
    async () => {
      const { data } = await axiosAPI.get(`/orders/${id}`);
      return data;
    },
    { enabled: typeof id !== 'undefined' }
  );

  const totalPrice: number = useMemo(() => {
    return (
      order?.products?.reduce(
        (acc: number, product: Product) => acc + product.price,
        0
      ) * order?.price_factor ??
      1 ??
      0
    );
  }, [order]);

  const updateOrderStatus = (status: string) => {
    axiosAPI
      .put(`/orders/${id}`, {
        status
      })
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='cs-inner-container"'>
      <h1 className="cs-title mb-2">Narudžbina broj {order?.id}</h1>
      <div className={style.orderStatus}>
        <span className={style.text}>Status narudžbine:</span>
        <div
          className={cx(
            style.value,
            OrderStatusClasses[order?.status] || 'cs-default-status-tab',
            'cs-status-tab'
          )}
        >
          {order?.status}
        </div>
      </div>
      <div className={style.singleOrderHeader}>
        <div className={style.info}>
          <h2 className={style.subtitle}>Informacije o porudzbini:</h2>

          <div className={style.item}>
            <span className={style.label}>Porudžbina kreirana na:</span>
            <span className={style.value}>{order?.reservation_start}</span>
          </div>
          <div className={style.item}>
            <div className={style.label}>Poručilac:</div>
            <div className={style.value}>{order?.full_name}</div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Email Adresa:</div>
            <div className={style.value}>{order?.email}</div>
          </div>
          {order?.type === 'company' && (
            <>
              <div className={style.item}>
                <div className={style.label}>Ime Kompanije:</div>
                <div className={style.value}>{order?.company_name}</div>
              </div>
              <div className={style.item}>
                <div className={style.label}>Maticni broj:</div>
                <div className={style.value}>{order?.company_id_number}</div>
              </div>
              <div className={style.item}>
                <div className={style.label}>PIB:</div>
                <div className={style.value}>{order?.company_tax_number}</div>
              </div>
            </>
          )}
          <div className={style.item}>
            <div className={style.label}>Početak Rezervacije:</div>
            <div className={style.value}>{order?.reservation_start}</div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Kraj Rezervacije:</div>
            <div className={style.value}>{order?.reservation_end}</div>
          </div>
          <div className={style.item}>
            <div className={style.label}>Dodatne napomene:</div>
            <div className={style.value}>
              {order?.note ||
                'Nije ostavljena nikakva dodatna napomena uz narudzbinu.'}
            </div>
          </div>
        </div>
        <div className={style.documentation}>
          <h2 className={style.subtitle}>Dokumentacija za preuzimanje:</h2>

          <div className={style.documentItem}>
            <p className={style.label}>Kalkulacija cene:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.CALCULATION,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
          <div className={style.documentItem}>
            <p className={style.label}>Revers:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.GET_PRODUCTS,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
          <div className={style.documentItem}>
            <p className={style.label}>Povraćaj reversa:</p>
            <button
              onClick={() =>
                downloadDocument({
                  type: DocumentDownloadType.RETURN_PRODUCTS,
                  id: id ?? 0
                })
              }
            >
              <DownloadIcon />
            </button>
          </div>
        </div>
      </div>
      <div className={style.orderActions}>
        {StatusActions[order?.status]?.map(({ status, label }) => {
          if (
            order?.status === 'accepted' ||
            order?.status === 'client-declined'
          ) {
            return (
              <p
                key={status}
                className={cx(
                  order?.status === 'accepted'
                    ? style.acceptedText
                    : style.declinedText
                )}
              >
                {label}
              </p>
            );
          } else {
            return (
              <button
                key={status}
                onClick={() => {
                  updateOrderStatus(status);
                }}
                className={cx(
                  OrderStatusClasses[status] || 'cs-default-status',
                  'cs-status-button'
                )}
              >
                {label}
              </button>
            );
          }
        })}
      </div>

      <h2 className="cs-subtitle mt-4">Sadržaj porudžbine</h2>

      <div className={style.orderList}>
        <div className={style.orderHeader}>
          <span>SKU</span>
          <span>Naziv</span>
          <span>Cena [RSD]</span>
          {/* <span>Količina [Kom.]</span> */}
          {/* <span>Ukupno</span> */}
        </div>
        <div className={style.orderBody}>
          {order?.products?.map(({ id, name, sku, images, price }: any) => (
            <div key={id} className={style.orderItem}>
              <div className={style.itemId}>{sku}</div>
              <div className={style.itemInfo}>
                <div className={style.itemImage}>
                  <img src={images[0]} alt={name} />
                </div>
                <div className={style.itemName}>{name}</div>
              </div>
              <div className={style.itemPrice}>
                {price * order.price_factor}
              </div>
            </div>
          ))}
        </div>

        <div className={style.orderFooter}>
          <div className={style.orderSubtotal}>
            <div className={style.label}>Predračun:</div>
            <div className={style.value}>{currencyFormat(totalPrice)}</div>
          </div>
          <div className={style.orderPdv}>
            <div className={style.label}>Ukupan PDV:</div>
            <div className={style.value}>
              {currencyFormat(totalPrice * 0.2)}
            </div>
          </div>
          <div className={style.orderTotal}>
            <div className={style.label}>Ukupno:</div>
            <div className={style.value}>
              {currencyFormat(totalPrice * 1.2)}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={style.orderListActions}>
        <Button className="btn btn-info">
          <EmailIcon />
        </Button>
        <a
          href={`http://127.0.0.1:8000/download-invoice/${id}`}
          className="btn btn-danger"
        >
          <PdfIcon />
        </a>
        <Button
          className="btn btn-secondary"
          onClick={() =>
            downloadDocument({
              type: DocumentDownloadType.CALCULATION,
              id: 216
            })
          }
        >
          <PrintIcon />
        </Button>
      </div> */}
    </div>
  );
}

export default SingleOrder;
