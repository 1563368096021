import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaRegTrashAlt as DeleteIcon } from 'react-icons/fa';
// import styles from '@styles/components/dropzone/dropzone.module.scss';

/* const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle: CSSProperties = {
  borderColor: '#2196f3'
};

const acceptStyle: CSSProperties = {
  borderColor: '#00e676'
};

const rejectStyle: CSSProperties = {
  borderColor: '#ff1744'
};

const thumbsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb: CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner: CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img: CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%'
};
 */
interface DropzoneProps {
  onSelectFiles: (files: File[]) => void;
  onError?: (message: string) => void;
  accept?: { [key: string]: string[] };
  maxFiles?: number;
}

export const Dropzone = ({
  onSelectFiles,
  onError,
  accept = { 'image/*': ['.jpeg', '.png'] },
  maxFiles = 5
}: DropzoneProps) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      maxFiles,
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          onError?.('FILE LIMIT ERROR');
          return;
        }
        onSelectFiles(acceptedFiles);
      }
    });

  /*   const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  ); */

  return (
    <section className="cs-dropzone">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Prevuci sliku da dodas ili klikni na pravougaonik.</p>
        <p>
          (Maksimalno {maxFiles} {maxFiles === 1 ? 'file' : 'files'} se može
          dodati)
        </p>
      </div>
    </section>
  );
};

interface DropzonePreviewProps {
  files: File[];
  onRemove: (index: number) => void;
}

export const DropzonePreview = ({
  children,
  files,
  onRemove
}: PropsWithChildren<DropzonePreviewProps>) => {
  const FilesList = files
    .map((file) => {
      // TODO: Set default thumbnail for non img files.
      return Object.assign(file, {
        preview: URL.createObjectURL(file)
      });
    })
    .map((file, index) => (
      <div key={index}>
        <button
          type="button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <DeleteIcon />
        </button>
        <img
          src={file.preview}
          alt="..."
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    ));

  return (
    <section className="cs-dropzone-preview">
      {FilesList}
      {children}
    </section>
  );
};
