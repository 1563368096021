import { useQuery } from '@tanstack/react-query';
import { Input, InputRef, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import type {
  ColumnType,
  ColumnsType,
  FilterValue
} from 'antd/es/table/interface';
import { useMemo, useRef, useState } from 'react';
import { Product } from '../../../models/Product/Product';
import { Collection } from 'collect.js';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import style from './product-list.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FaEdit as EditIcon, FaTrash as DeleteIcon } from 'react-icons/fa';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';

interface ProductTableList {
  key: number;
  id: number;
  name: string;
  category: string;
  stand: string;
  sku: string;
}
interface ColumnTableType {
  name: string;
  category: string;
  stand: string;
  sku: string;
}

type DataIndex = keyof ColumnTableType;

export default function ProductList() {
  const searchInput = useRef<InputRef>(null);

  const { data: products, refetch: refetchProducts } = useQuery(
    ['products'],
    () => Product.list()
  );

  const navigate = useNavigate();

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const uniqueCategories = useMemo(() => {
    return new Collection(products)
      .pluck('category.name')
      .unique()
      .toArray<string>();
  }, [products]);

  const ProductsData: ProductTableList[] = useMemo(
    () =>
      products?.map((product) => ({
        key: product.id,
        id: product.id,
        name: product.name,
        category: product.category.name,
        stand: product.storageRoom?.name + '/' + product.storageStand?.name,
        sku: product.sku
      })) || [],
    [products]
  );

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<ProductTableList> => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div
            className="cs-table-dropdown"
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Pretrazi ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
            />
            <div className="cs-table-dropdown-actions">
              <button
                onClick={() => confirm()}
                className="cs-table-button-primary"
              >
                Pretraži
              </button>

              <button
                onClick={() => {
                  clearFilters && clearFilters();
                }}
                className="cs-table-button-secondary"
              >
                Resetuj
              </button>
              <button
                onClick={() => {
                  confirm({ closeDropdown: false });
                }}
                className="cs-table-button-icon"
              >
                <FilterOutlined />
              </button>
            </div>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => <SearchOutlined />,
      filteredValue: filteredInfo[dataIndex] || null,
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },

      render: (text) => text
    };
  };

  const columns: ColumnsType<ProductTableList> = [
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name, row) => (
        <div
          className="cs-table-link"
          onClick={() => navigate(`/admin/product/${row.id}`)}
        >
          {name}
        </div>
      )
    },
    {
      title: 'Kategorija',
      dataIndex: 'category',
      key: 'category',
      filters: uniqueCategories.map((item) => {
        return {
          text: item,
          value: item
        };
      }),
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filteredInfo.category || null,
      onFilter: (value: string | number | boolean, record) =>
        record.category.includes(value.toString()),
      ellipsis: true
    },
    {
      title: 'Lokacija',
      dataIndex: 'stand',
      key: 'stand',
      ...getColumnSearchProps('stand'),
      render: (stand) => <div className="cs-table-stand">{stand}</div>
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ...getColumnSearchProps('sku')
    },
    {
      key: 'action',
      title: 'Akcije',
      render(_, product) {
        return (
          <>
            <Link
              className={style.editButton}
              to={`/admin/products/edit/${product.id}`}
            >
              <EditIcon />
            </Link>
            <button
              className={style.deleteButton}
              onClick={() => {
                if (
                  window.confirm(
                    'Da li ste sigurni da želite da izbrišete ' +
                      product.name +
                      '?'
                  )
                ) {
                  Product.delete(product.id).then(() => refetchProducts());
                }
              }}
            >
              <DeleteIcon />
            </button>
          </>
        );
      }
    }
  ];

  const handleChange: TableProps<ProductTableList>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
  };

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title">Lista Proizvoda</h1>
      <div className={style.info}>
        <div className={style.number}>
          Trenutno imate <span>{products?.length}</span> proizvoda
        </div>
        <ButtonPrimary url="/admin/products/create">
          Dodaj Proizvod
        </ButtonPrimary>
      </div>
      <Table
        columns={columns}
        dataSource={ProductsData}
        onChange={handleChange}
        className="cs-table-wrapper"
      />
    </div>
  );
}
