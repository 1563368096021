import Webcam from 'react-webcam';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { MdCamera, MdPlayCircle, MdFlipCameraAndroid } from 'react-icons/md';

const videoConstraints = {
  width: 600,
  height: 800,
  facingMode: 'user'
};

export default function CameraUploader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    // @ts-ignore
    const imageSrc = webcamRef.current?.getScreenshot();
  }, [webcamRef]);

  return (
    <>
      <button type="button" className="cs-button-primary" onClick={handleShow}>
        <MdCamera />
      </button>
      <Modal className="cs-camera-modal" show={show} onHide={handleClose}>
        <Modal.Body>
          <Webcam
            audio={false}
            height={800}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={600}
            videoConstraints={videoConstraints}
          />

          <div className="cs-button-group">
            <button type="button" onClick={capture}>
              <MdPlayCircle />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
