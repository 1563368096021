import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { FormikContextType, FormikContext, Field, FieldArray } from 'formik';
import { useContext, useMemo } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Feature } from '../../../../models/Feature/Feature';
import { ErrorMessageLabel } from '../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { StoreProductValues } from '../CreateProductForm';

function StepThree() {
  const { values, setValues, setFieldValue } =
    useContext<FormikContextType<StoreProductValues>>(FormikContext);
  const { data: features = [] } = useQuery(['features'], () =>
    Feature.all('values')
  );
  const featureMap = useMemo(() => Feature.toMap(features), [features]);

  const getFeatureRelatedFields = (features: number[]) => {
    const featureValues: { [key: number]: number[] } = {};
    features.forEach((featureID) => {
      featureValues[featureID] = values.feature_values[featureID] ?? [];
    });
    return {
      features,
      feature_values: featureValues
    };
  };

  return (
    <>
      <FormGroup>
        <FormLabel>Izaberi karakteristike</FormLabel>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          placeholder="Select features"
          value={values.features}
          onChange={(value: number[]) => {
            setValues({
              ...values,
              ...getFeatureRelatedFields(value)
            });
          }}
        >
          {features?.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
        <ErrorMessageLabel name="features" />
      </FormGroup>
      <FormGroup>
        <FieldArray name="feature_values">
          {() => (
            <>
              {values.features?.map((featureID) => {
                if (!featureMap.has(featureID)) {
                  return null;
                }
                const { name, options } = featureMap.get(featureID) as Feature;
                return (
                  <FormGroup key={featureID}>
                    <FormLabel>{name}</FormLabel>
                    <Field
                      value={values.feature_values[featureID]}
                      name={`feature_values.${featureID}`}
                      style={{ width: '100%' }}
                      component={Select}
                      mode={'multiple'}
                      onChange={(value: number[]) => {
                        setFieldValue(
                          `feature_values.${featureID}`,
                          value,
                          true
                        );
                      }}
                    >
                      {options?.map(({ id, value }) => (
                        <Select.Option key={id} value={id}>
                          {value}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessageLabel name={`feature_values.${featureID}`} />
                  </FormGroup>
                );
              })}
            </>
          )}
        </FieldArray>
        <ErrorMessageLabel name="feature_values" />
      </FormGroup>
    </>
  );
}

export default StepThree;
