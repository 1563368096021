import { ErrorMessage, ErrorMessageProps } from 'formik';
import React, { PropsWithChildren } from 'react';
import style from './error-message.module.scss';

export const ErrorMessageLabel = (
  props: PropsWithChildren<ErrorMessageProps>
) => {
  return (
    <div className={style.errorMessage}>
      <ErrorMessage {...props}></ErrorMessage>
    </div>
  );
};
