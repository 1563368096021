import axiosAPI from '../../utils/client/axiosAPI';
import { User } from '../User/User';

export interface CartProductResponse {
  readonly id: number;
  readonly quantity: number;
  readonly name: string;
  readonly sku: string;
  readonly images: string[];
  readonly attribute_values?: number[];
  readonly feature_values?: number[];
}

export interface CartResponse {
  token: string;
  products: CartProductResponse[];
  start_date?: string;
  end_date?: string;
}

export class CartProduct {
  public readonly id: number;
  public readonly name: string;
  public readonly sku: string;
  public readonly images: string[];

  public quantity: number;

  public constructor({ id, name, sku, images, quantity }: CartProductResponse) {
    this.id = id;
    this.name = name;
    this.sku = sku;
    this.images = images;
    this.quantity = quantity;
  }
}

export class Cart {
  public readonly token: string;
  public readonly products: CartProduct[];
  public startDate?: string;
  public endDate?: string;

  public constructor({ token, products, start_date, end_date }: CartResponse) {
    this.token = token;
    this.products = products?.map((data) => new CartProduct(data)) ?? [];
    this.startDate = start_date;
    this.endDate = end_date;
  }

  public clone(): Cart {
    return new Cart({
      token: this.token,
      start_date: this.startDate,
      end_date: this.endDate,
      products: this.products.map(({ id, quantity, name, sku, images }) => ({
        id,
        quantity,
        name,
        sku,
        images
      }))
    });
  }

  public static async get(cartToken: string | null): Promise<Cart> {
    const { data } = await axiosAPI.get(
      cartToken === null ? `/carts` : `/carts/${cartToken}`
    );
    return new Cart(data);
  }

  public async setStartEndDates(startDate: string, endDate: string) {
    await axiosAPI.post(`/carts/${this.token}/set-start-end-dates`, {
      start_date: startDate,
      end_date: endDate
    });
    this.startDate = startDate;
    this.endDate = endDate;
    this.products.splice(0);
    return this.clone();
  }

  public async addProduct(productID: number, quantity: number) {
    const { data } = await axiosAPI.post(
      `/carts/${this.token}/add-product/${productID}`,
      { quantity }
    );
    const index = this.products.findIndex(({ id }) => productID === id);
    if (index === -1) {
      this.products.push(new CartProduct(data.product));
    } else {
      this.products[index].quantity = data.quantity;
    }
    return this.clone();
  }

  public async removeProduct(productID: number) {
    await axiosAPI.post(`/carts/${this.token}/remove-product/${productID}`);
    const index = this.products.findIndex(({ id }) => productID === id);
    if (index >= 0) {
      this.products.splice(index, 1);
    }
    return this.clone();
  }

  public async emptyCart() {
    await axiosAPI.post(`/carts/${this.token}/clear-cart`);
    this.products.splice(0);
    return this.clone();
  }

  public async changeProductQuantity(productID: number, delta: number = 1) {
    const { data: quantity } = await axiosAPI.post(
      `/carts/${this.token}/change-product-quantity/${productID}`,
      { delta }
    );
    const index = this.products.findIndex(({ id }) => productID === id);
    if (index >= 0) {
      this.products[index].quantity = quantity;
    }
    return this.clone();
  }

  public hasStartEndDates(): boolean {
    return (
      !!this.startDate &&
      !!this.endDate &&
      !!Date.parse(this.startDate) &&
      !!Date.parse(this.endDate)
    );
  }
}
