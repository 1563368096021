import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRedirectAuthenticated } from '../../components/RedirectAuthenticated/RedirectAuthenticated';
import { useAuthContext } from '../../context/AuthContext/AuthContext';
import { AuthActionType } from '../../context/AuthContext/AuthReducer';
import { User, UserLoginPayload } from '../../models/User/User';
import Session from '../../utils/storage/ClientStorage';
import style from './login.module.scss';
import { useNavigate } from 'react-router-dom';
import { CgArrowLongLeft as LeftArrow } from 'react-icons/cg';

function Login() {
  const { dispatch, error } = useAuthContext();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserLoginPayload>({
    email: '',
    password: '',
    cart_token: Session.get('cart_token')
  });

  const loginUser = () => {
    dispatch({ type: AuthActionType.REQUEST_LOGIN });
    User.login(userData)
      .then((user) => {
        dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload: user });
      })
      .catch((error) => {
        dispatch({ type: AuthActionType.LOGIN_ERROR });
      });
  };

  return (
    <div className={style.registerWrapper}>
      <img className={style.logo} src="/assets/logo1.png" alt="logo" />
      <h1 className={style.title}>Uloguj se</h1>
      <Container className={style.formWrapper}>
        <form className={style.innerFormWrapper}>
          <Row>
            <Col>
              {error && (
                <p className={style.errorMessage}>
                  Uneli ste pogrešnu šifru za email adresu
                </p>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className={style.formGroup}>
                <label>Unesite email:</label>
                <input
                  type="text"
                  defaultValue={userData.email}
                  onChange={({ target }) => {
                    setUserData({
                      ...userData,
                      email: target.value
                    });
                  }}
                />
                {/* <p className={style.errorMessage}>Nepostojeća email adresa</p> */}
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className={style.formGroup}>
                <label>Unesite šifru</label>
                <input
                  type="password"
                  defaultValue={userData.password}
                  onChange={({ target }) => {
                    setUserData({
                      ...userData,
                      password: target.value
                    });
                  }}
                />
                {/* <p className={style.errorMessage}>Pogrešna šifra</p> */}
              </div>
            </Col>
          </Row>
          <button onClick={loginUser}>Uloguj se</button>
          <div className={style.backButton} onClick={() => navigate('/')}>
            <LeftArrow /> Vrati se nazad
          </div>
          {/*           <div className={style.loginBottom}>
            <p>Jos uvek nemaš nalog?</p>
            <NavLink to="/register">Registruj se</NavLink>
          </div> */}
        </form>
      </Container>
    </div>
    // <>
    //   <input
    //     type="text"
    //     defaultValue={userData.email}
    //     onChange={({ target }) => {
    //       setUserData({
    //         ...userData,
    //         email: target.value
    //       });
    //     }}
    //   />
    //   <input
    //     type="text"
    //     defaultValue={userData.password}
    //     onChange={({ target }) => {
    //       setUserData({
    //         ...userData,
    //         password: target.value
    //       });
    //     }}
    //   />
    //   <button onClick={loginUser}>Login</button>
    // </>
  );
}

export default withRedirectAuthenticated(Login);
