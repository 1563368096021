import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table/interface';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import { Project } from '../../../models/Project/Project';
import { FaEdit as EditIcon, FaTrash as DeleteIcon } from 'react-icons/fa';

import style from './project-list.module.scss';
import { useMemo } from 'react';
import { ProjectResponse } from '../../../models/Project/contracts';

export default function ProjectList() {
  type DataType = {
    key: number;
    id: number;
    name: string;
    duration: number;
    points_required: number;
  };
  const { data: projects, refetch } = useQuery(['projects'], () =>
    Project.all()
  );

  const initialValues: DataType[] = useMemo(() => {
    return (
      projects?.map((project, index) => ({
        key: project.id,
        id: project.id,
        name: project.name,
        duration: project.duration,
        points_required: project.points_required
      })) ?? []
    );
  }, [projects]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Ime',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Trajanje [dani]',
      key: 'duration',
      dataIndex: 'duration'
    },

    {
      title: 'Potrebni Poeni',
      key: 'points_required',
      dataIndex: 'points_required'
    },
    {
      key: 'action',
      title: 'Akcije',

      render(_, val) {
        return (
          <>
            <Link to={`/admin/projects/edit/${val.id}`}>
              <EditIcon />
            </Link>
            <button
              className="cs-delete-button"
              onClick={() => {
                if (
                  window.confirm(
                    'Da li ste sigurni da želite da izbrišete ' + val.name + '?'
                  )
                ) {
                  Project.delete(val.id).then(() => refetch());
                }
              }}
            >
              <DeleteIcon />
            </button>
          </>
        );
      }
    }
  ];

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title">Lista Projekata</h1>
      <div className={style.info}>
        <div className={style.number}>
          Trenutno imate <span>{projects?.length}</span> projekta
        </div>
        <ButtonPrimary url="/admin/project/create">
          Dodaj Projekat
        </ButtonPrimary>
      </div>
      <Table
        columns={columns}
        dataSource={initialValues}
        className="cs-table-wrapper"
        rowKey={'key'}
      />
    </div>
  );
}
