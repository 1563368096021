import { Form, Formik } from 'formik';
import { Modal, Row, FormGroup, FormLabel, Button } from 'react-bootstrap';
import { InputField } from '../../../Admin/components/InputField/InputField';
import style from './date-modal.module.scss';
import { useCartContext } from '../../../context/CartContext/CartContext';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ButtonPrimary } from '../Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../../Admin/components/ErrorMessageLabel/ErrorMessageLabel';
import * as Yup from 'yup';

interface DateModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: () => void;
}
const DataModalValidationSchema = Yup.object().shape({
  start_date: Yup.date().required('Unesite datum pocetka rezervacije'),
  end_date: Yup.date()
    .required('Unesite datum kraja rezervacije')
    .min(
      Yup.ref('start_date'),
      'Datum kraja rezervacije mora biti veci od datuma pocetka rezervacije'
    )
});

const DateModal: FC<DateModalProps> = ({ open = false, setOpen, onAction }) => {
  const { cart, cartSetStartEndDates } = useCartContext();

  const initialValues = {
    start_date: cart?.startDate ?? '',
    end_date: cart?.endDate ?? ''
  };

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      centered
      backdrop="static"
      className={style.wrapper}
    >
      <Modal.Header closeButton className={style.header}>
        <Modal.Title>Izaberi datum</Modal.Title>
      </Modal.Header>
      <Modal.Body className={style.body}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={DataModalValidationSchema}
          onSubmit={(values) => {
            cartSetStartEndDates(values['start_date'], values['end_date']);
            setOpen(false);
            setTimeout(() => {
              onAction?.();
            }, 1000);
          }}
        >
          {() => (
            <Form autoComplete="off">
              <Row className="mb-4">
                <FormGroup>
                  <FormLabel className="fw-bold">Početak rezervacije</FormLabel>
                  <InputField
                    name="start_date"
                    autoComplete="off"
                    type="date"
                  />
                  <ErrorMessageLabel name="start_date" />
                </FormGroup>
              </Row>
              <Row className="mb-4">
                <FormGroup>
                  <FormLabel className="fw-bold">Kraj Rezervacije</FormLabel>
                  <InputField name="end_date" autoComplete="off" type="date" />
                  <ErrorMessageLabel name="end_date" />
                </FormGroup>
              </Row>
              <Row>
                <div>
                  <button type="submit" className={style.button}>
                    Izaberi datum
                  </button>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default DateModal;
