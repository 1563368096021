import { FaCheck } from 'react-icons/fa';
import style from './accept-offer.module.scss';
import cx from 'classnames';
import { HTMLAttributes, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import axiosAPI from '../../../utils/client/axiosAPI';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';

interface ThankYouProps extends HTMLAttributes<HTMLDivElement> {
  setNoSideBar: (value: boolean | ((value: boolean) => boolean)) => void;
}

export default function AcceptOffer({
  setNoSideBar,
  ...props
}: PropsWithChildren<ThankYouProps>) {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data, refetch, isLoading } = useQuery({
    enabled: !!id,
    queryKey: ['order', id],
    queryFn: async () => {
      const { data } = await axiosAPI.get(`/orders/${id}`);
      return data;
    }
  });

  const { mutate: updateOrderStatus } = useMutation({
    mutationKey: ['order', id],
    mutationFn: async (status: string) => {
      await axiosAPI.put(`/orders/${id}`, {
        status
      });
    },
    onSuccess: () => {
      refetch();
    }
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <div {...props} className={cx(style.wrapper)}>
      {data?.status === 'accepted' ? (
        <>
          <div className={style.icon}>
            <FaCheck />
          </div>
          <h1 className={style.title}>
            Da li zelite da prihvatite ponudu broj {data?.id}
          </h1>
          <p>
            Ukoliko ste zadovoljni sa ponudom koju ste dobili na{' '}
            <span className="cs-primary-color">{data?.email}</span>, prihvatite
            klikom na dugme "Prihvati ponudu".
          </p>
          <div className={style.buttonsContainer}>
            <button onClick={() => updateOrderStatus('client-declined')}>
              Odbij ponudu
            </button>
            <button onClick={() => updateOrderStatus('client-accepted')}>
              Prihvati ponudu
            </button>
          </div>
        </>
      ) : (
        <div className={style.acceptedContainer}>
          <h1 className={style.title}>Ova ponuda je već procesuirana</h1>
          <ButtonPrimary onClick={() => navigate('/')}>
            Nazad na prodavnicu
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
}
