import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table/interface';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import { Filter } from '../../../models/Filter/Filter';
import { FaEdit as EditIcon } from 'react-icons/fa';

import style from './filter-list.module.scss';

export default function FilterList() {
  const { data: filters } = useQuery(['filters'], () => Filter.all());

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Naziv',
      dataIndex: 'name'
    },
    {
      title: 'Akcije',
      render({ id }) {
        return (
          <Link to={`/admin/filters/edit/${id}`}>
            <EditIcon />
          </Link>
        );
      }
    }
  ];

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title">Lista Filtera</h1>
      <div className={style.info}>
        <div className={style.number}>
          Trenutno imate <span>{filters?.length}</span> filtera
        </div>
        <ButtonPrimary url="/admin/filters/create">Dodaj Filter</ButtonPrimary>
      </div>
      <Table
        columns={columns}
        dataSource={filters}
        className="cs-table-wrapper"
        rowKey={'id'}
      />
    </div>
  );
}
