export function deleteOptionalProperties<Type>(
  object: Type,
  properties: (keyof Type)[]
): Type {
  properties.forEach((property) => {
    if (!object[property]) {
      delete object[property];
    }
  });
  return object;
}

export function keyBy<Type>(
  array: Type[],
  key: keyof Type
): Map<Type[keyof Type], Type> {
  const collection = new Map<Type[keyof Type], Type>();
  array.forEach((value) => {
    collection.set(value[key], value);
  });
  return collection;
}

export function combineArrays<Type>(input: Type[][]) {
  const result: Type[][] = [];
  const stack: Type[] = [];
  const helper = (input: Type[][]) => {
    if (input.length === 0) {
      result.push(stack.slice());
      return;
    }
    const array = input.shift();
    array?.forEach((value) => {
      stack.push(value);
      helper([...input]);
      stack.pop();
    });
  };
  helper(input);
  return result;
}

export function IsFormDataIterable(value: unknown): boolean {
  return (
    (value instanceof Object || value instanceof Array) &&
    !(value instanceof File)
  );
}

export function toFormData(value: unknown): FormData {
  const data = new FormData();
  const append = (value: any, keys: string[] = []) => {
    if (!IsFormDataIterable(value)) {
      data.append(
        `${keys.shift()}${keys.map((key) => `[${key}]`).join('')}`,
        value
      );
      return;
    }
    for (const key in value) {
      keys.push(key);
      append(value[key], [...keys]);
      keys.pop();
    }
  };
  append(value);
  return data;
}

export function currencyFormat(num: number, currency: string = 'RSD') {
  return (
    num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ` ${currency}`
  );
}
