import axiosAPI from '../../../utils/client/axiosAPI';

export type CreateOrderDocumentPayload = {
  name: string;
  order_status: string;
  document: File;
};

export type OrderDocument = {
  id: number;
  name: string;
  order_status: string;
  original_name: string;
};

export async function createOrderDocument(payload: CreateOrderDocumentPayload) {
  const { data } = await axiosAPI.postForm('/order-documents', payload);

  return data;
}

export async function getAllOrderDocuments() {
  const { data } = await axiosAPI.get<OrderDocument[]>('/order-documents');

  return data;
}

export async function downloadOrderDocument(orderDocument: OrderDocument) {
  const { id, original_name } = orderDocument;
  const { data } = await axiosAPI.get(`/order-documents/${id}/download`, {
    responseType: 'blob'
  });

  const href = URL.createObjectURL(data);

  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', original_name);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function deleteOrderDocument(orderDocument: OrderDocument) {
  const { id } = orderDocument;
  const { data } = await axiosAPI.delete(`/order-documents/${id}`);

  return data;
}
