import { Form, Formik } from 'formik';
import { FormGroup, Row, Col, FormLabel } from 'react-bootstrap';
import { ErrorMessageLabel } from '../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../../components/InputField/InputField';
import { Select, Upload } from 'antd';
import { ButtonPrimary } from '../../../components/Button/ButtonPrimary';
import { createOrderDocument } from '../queries';
import { MdFileUpload as FileUploadIcon } from 'react-icons/md';
import * as Yup from 'yup';
import { useRef } from 'react';

const OrderDocumentsCreate = () => {
  const fileRef = useRef<HTMLInputElement>(null);

  const orderStatuses = [
    { value: 'pending', label: 'Na čekanju' },
    { value: 'accepted', label: 'Prihvaćeno' },
    { value: 'declined', label: 'Odbijeno' },
    { value: 'rented', label: 'Iznajmljeno' },
    { value: 'returned', label: 'Vraćeno' }
  ];

  type FormValues = {
    name: string;
    order_status: string;
    document: File | '';
  };

  const initialValues: FormValues = {
    name: '',
    order_status: '',
    document: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(50, 'Ovo polje mora imati manje od 50 karaktera')
      .required('Ovo polje je obavezno'),
    order_status: Yup.string().required('Ovo polje je obavezno'),
    document: Yup.mixed().required('Ovo polje je obavezno')
  });

  return (
    <>
      <h1 className="cs-title">Dodaj dokument</h1>
      <p>
        Uneti dokumente koje zelite da se salju prilikom promena statusa
        porudzbine.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (typeof values.document === 'string') {
            setSubmitting(false);

            return;
          }

          setSubmitting(true);

          createOrderDocument({ ...values, document: values.document as File })
            .then(() => {
              !!fileRef.current && (fileRef.current.value = '');

              resetForm();
            })
            .catch(() => {})
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Row>
              <Col lg={12} className="mb-2">
                <FormGroup>
                  <FormLabel className="fw-bold">Naziv Dokumenta</FormLabel>
                  <InputField placeholder="" name="name" autoComplete="off" />
                  <ErrorMessageLabel name="name" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mb-2">
                <FormGroup>
                  <FormLabel className="fw-bold">Status Narudžbine</FormLabel>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Izaberite status ordera"
                    value={values.order_status}
                    onChange={(value) => {
                      setFieldValue('order_status', value);
                    }}
                    className="cs-select"
                  >
                    {orderStatuses.map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                  <ErrorMessageLabel name="order_status" />
                </FormGroup>
              </Col>
            </Row>
            {/* 
            <FormGroup>
              <FormLabel>Izaberi Dokument</FormLabel>
              <input
                ref={fileRef}
                name="document"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  const file = event.target?.files?.[0];

                  if (!file) {
                    return;
                  }

                  setFieldValue('document', file);
                }}
              />
              <ErrorMessageLabel name="document" />
            </FormGroup> */}
            <FormGroup>
              <FormLabel className="fw-bold me-2">Izaberi Dokument</FormLabel>
              <Upload
                beforeUpload={(file) => {
                  // You can add additional checks or validations here
                  setFieldValue('document', file);
                  return false; // Prevent automatic upload
                }}
              >
                <button type="button" className="cs-table-button-icon">
                  <FileUploadIcon />
                </button>
              </Upload>
              <ErrorMessageLabel name="document" />
            </FormGroup>

            <div className="ms-3">
              <ButtonPrimary type="submit" disabled={isSubmitting}>
                Sačuvaj
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrderDocumentsCreate;
