import axiosAPI from '../../utils/client/axiosAPI';
import { toFormData } from '../../utils/helpers/helpers';
import { ResponseWithPagination } from '../contracts';
import {
  ProductAttribute,
  ProductFeature,
  ProductResponse,
  ProductType,
  StoreProductPayload
} from './contracts';

export interface StorageRoom {
  id: number;
  name: string;
}

export interface StorageStand extends StorageRoom {
  id: number;
  name: string;
  storage_room: StorageRoom;
}

export class Product {
  public readonly id: number;
  public readonly type: ProductType;
  public readonly name: string;
  public readonly sku: string;
  public readonly price: number;
  public readonly points: number;
  public readonly category: any;
  public readonly stand: any;
  public readonly variations?: Product[];
  public readonly attributes?: ProductAttribute[];
  public readonly features?: ProductFeature[];
  public readonly images?: string[];
  public readonly storageRoom?: StorageRoom;
  public readonly storageStand?: StorageStand;

  public constructor({
    id,
    type,
    name,
    sku,
    price,
    points,
    category,
    stand,
    variations,
    attribute_values,
    feature_values,
    images,
    storage_room_stand
  }: ProductResponse) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.sku = sku;
    this.price = price;
    this.points = points;
    this.category = category;
    this.stand = stand;
    this.variations = variations?.map((data) => new Product(data));
    this.attributes = attribute_values;
    this.features = feature_values;
    this.images = images;
    this.storageStand = storage_room_stand;
    this.storageRoom = storage_room_stand?.storage_room;
  }

  public static async get(id: string | number): Promise<Product> {
    const { data } = await axiosAPI.get(`/products/${id}`);
    return new Product(data);
  }

  public static async store(payload: StoreProductPayload): Promise<Product> {
    const { data } = await axiosAPI.post<ProductResponse>(
      '/products',
      toFormData(payload)
    );
    return new Product(data);
  }

  public async update(payload: StoreProductPayload): Promise<Product> {
    const { data } = await axiosAPI.post<ProductResponse>(
      `/products/${this.id}`,
      // Need to spoof "multipart/form-data"
      toFormData({ _method: 'put', ...payload })
    );
    return new Product(data);
  }

  public static async all({
    filters,
    page = 1,
    page_size = 12,
    categories = []
  }: {
    filters?: string;
    page?: number;
    page_size?: number;
    categories?: number[];
  }): Promise<ResponseWithPagination<Product>> {
    const {
      data: { pagination, data }
    } = await axiosAPI.get<ResponseWithPagination<ProductResponse>>(
      `/products`,
      {
        params: {
          filters,
          page,
          page_size,
          categories
        }
      }
    );

    return {
      pagination,
      data: data.map((product) => new Product(product))
    };
  }

  public static async list() {
    const { data } = await axiosAPI.get<ProductResponse[]>(`/product/list`);

    return data.map((product) => new Product(product));
  }

  public async delete() {
    return await axiosAPI.delete<null>(`/products/${this.id}`);
  }

  public static async delete(id: number) {
    return await axiosAPI.delete<null>(`/products/${id}`);
  }
}
