import { FieldArray, Form, Formik } from 'formik';
import { Button, Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Addon } from '../../../models/Addon/Addon';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';

function CreateAddon() {
  return (
    <>
      <h1 className="text-white ms-3 mb-5">Kreiraj modifikator</h1>
      <ToastContainer />
      <Formik
        initialValues={{
          name: '',
          values: []
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(255, 'name.max').required('name.required'),
          values: Yup.array()
            .required()
            .of(
              Yup.object().shape({
                value: Yup.string().required('values.value')
              })
            )
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          Addon.store(values)
            .then(() => {
              resetForm();
              toast('Save success');
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <FormGroup>
              <Col lg={4} className="mb-5 ms-3 ">
                <FormLabel>Ime modifikatora</FormLabel>
                <InputField
                  placeholder="Ime modifikatora"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessageLabel name="name" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col lg={4} className="mb-5 ms-3 ">
                <FormLabel>Vrednost modifikatora</FormLabel>
                <FieldArray name="values">
                  {({ push, remove }) => {
                    return (
                      <>
                        {values.values?.map((_, index) => (
                          <Row key={index}>
                            <Col>
                              <InputField
                                placeholder="vrednost modifikatora"
                                name={`values.${index}.value`}
                                autoComplete="off"
                              />
                              <ErrorMessageLabel
                                name={`values.${index}.value`}
                              />
                            </Col>
                            <Col>
                              <Button
                                variant="outline-light "
                                className="JHover"
                                onClick={() => remove(index)}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <div>
                          <Button
                            variant="outline-light"
                            className="mt-2 mb-5  btn-lg JHover"
                            onClick={() =>
                              push({
                                value: '',
                                filter_values: []
                              })
                            }
                          >
                            <i className="fa-solid fa-circle-plus"></i>
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </FieldArray>
              </Col>
            </FormGroup>
            <div className="ms-3">
              <ButtonPrimary type="submit">Sačuvaj</ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateAddon;
