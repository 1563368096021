import { useMemo } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import { Button, Input, Pagination, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import type { Product } from '../queries';
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnsType } from 'antd/lib/table';
import {
  MdAdd as PlusIcon,
  MdRemove as DeleteIcon,
  MdLink as ViewIcon,
  MdCheck as CheckIcon
} from 'react-icons/md';
import cx from 'classnames';
import style from '../create-order.module.scss';

interface ProductsTableProps {
  products: Product[];
  selectedProducts: Product[];
  handleAddToOrder?: (product: Product) => void;
  handleRemoveFromOrder?: (product: Product) => void;
}

const ProductsTable = ({
  products,
  selectedProducts,
  handleAddToOrder,
  handleRemoveFromOrder
}: ProductsTableProps) => {
  type DataType = {
    key: number;
    name: string;
    sku: string;
    category: string;
    product: Product;
  };

  type DataIndex = keyof DataType;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const selectedIdArray = useMemo(
    () => selectedProducts?.map((obj) => obj.id) ?? [],
    [selectedProducts]
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: (options: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm({ closeDropdown: true });
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (options: FilterConfirmProps) => void
  ) => {
    setSearchText('');
    setSearchedColumn('');
    clearFilters();
    confirm({ closeDropdown: true });
  };

  const handleClose = (
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    confirm: (options: FilterConfirmProps) => void
  ) => {
    setSelectedKeys([]);
    confirm({ closeDropdown: true });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(event) =>
            setSelectedKeys(event.target.value ? [event.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              handleClose(setSelectedKeys, confirm);
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      ...getColumnSearchProps('sku'),
      render: (_, row) => {
        return (
          <div className={style.checkmarkColumn}>
            {selectedIdArray.includes(row.key) && <CheckIcon />}
            <span>{row.sku}</span>
          </div>
        );
      }
    },
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name')
    },
    {
      title: 'Kategorija',
      dataIndex: 'category',
      key: 'category',
      ...getColumnSearchProps('category')
    },
    {
      title: 'Akcije',
      key: 'actions',
      render(_, row) {
        return (
          <div className={style.tableIcons}>
            <button
              className="cs-table-button-icon"
              onClick={() => {
                handleAddToOrder?.(row.product);
              }}
            >
              <PlusIcon />
            </button>
            <button
              className="cs-table-button-icon"
              onClick={() => {
                handleRemoveFromOrder?.(row.product);
              }}
            >
              <DeleteIcon />
            </button>
            <a
              className="cs-table-button-icon"
              href={`/admin/product/${row.key}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ViewIcon />
            </a>
          </div>
        );
      }
    }
  ];

  const data = useMemo<DataType[]>(
    () =>
      products.map((product) => {
        const { id, name, sku, category } = product;

        return {
          key: id,
          sku,
          name,
          category: category.name,
          product
        };
      }),
    [products]
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      className={cx(
        style.tableWrapper,
        'cs-table-wrapper',
        'cs-create-order-table'
      )}
    />
  );
};

export default ProductsTable;
