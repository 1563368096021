import Pagination from 'react-bootstrap/Pagination';
import useWindowDimensions from '../../../hooks/use-window-dimensions';
import style from './shop-pagination.module.scss';
import cx from 'classnames';
import { PaginationResponse, Paginator } from '../../../models/Pagination';

interface ShopPaginationProps {
  pagination: PaginationResponse;
  onChange: (page: number) => void;
}

function ShopPagination({ pagination, onChange }: ShopPaginationProps) {
  const window = useWindowDimensions();
  const paginator = new Paginator(
    pagination,
    (window?.width ?? 0) < 980 ? 4 : 4
  );

  if (!paginator.hasPages()) {
    return <></>;
  }

  return (
    <Pagination className={style.paginationWrapper}>
      <Pagination.Prev
        className={style.paginationButton}
        onClick={() => onChange(paginator.getPreviousPageIndex())}
      />
      {paginator.getPages().map(({ index, asEllipsis }) => {
        return asEllipsis ? (
          <Pagination.Ellipsis
            key={index}
            className={style.paginationBreak}
            onClick={() => onChange(index)}
          />
        ) : (
          <Pagination.Item
            key={index}
            active={index === pagination.current_page}
            className={cx(
              style.items,
              index === pagination.current_page ? style.active : null
            )}
            onClick={() => onChange(index)}
          >
            {index}
          </Pagination.Item>
        );
      })}
      <Pagination.Next
        className={style.paginationButton}
        onClick={() => onChange(paginator.getNextPageIndex())}
      />
    </Pagination>
  );
}

export default ShopPagination;
