import { useMemo } from 'react';
import { Button, Table } from 'antd';
import type { Product } from '../queries';
import type { ColumnsType } from 'antd/lib/table';
import {
  MdAdd as PlusIcon,
  MdRemove as DeleteIcon,
  MdLink as ViewIcon
} from 'react-icons/md';
import style from '../create-order.module.scss';

interface CreateOrderProductsTableProps {
  products: Product[];
  handleRemoveFromOrder?: (product: Product) => void;
}

const OrderTable = ({
  products,
  handleRemoveFromOrder
}: CreateOrderProductsTableProps) => {
  type DataType = {
    key: number;
    name: string;
    sku: string;
    category: string;
    points: number;
    product: Product;
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Kategorija',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Akcije',
      key: 'actions',
      render(_, row) {
        return (
          <div className={style.tableIcons}>
            <button
              className="cs-table-button-icon"
              onClick={() => {
                handleRemoveFromOrder?.(row.product);
              }}
            >
              <DeleteIcon />
            </button>
            <a
              className="cs-table-button-icon"
              href={`/admin/product/${row.key}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ViewIcon />
            </a>
          </div>
        );
      }
    }
  ];

  const data = useMemo<DataType[]>(
    () =>
      products.map((product) => {
        const { id, name, sku, points, price, category } = product;

        return {
          key: id,
          sku,
          name,
          category: category.name,
          points,
          price,
          product
        };
      }),
    [products]
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      className="cs-table-wrapper"
    />
  );
};

export default OrderTable;
