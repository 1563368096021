import style from './product-card.module.scss';
import { MdHistoryToggleOff as OutOfStock } from 'react-icons/md';
import { ButtonPrimary } from '../Button/ButtonPrimary';
import { AttributeProps } from '../Attributes/AttributeValue';
import AttributeGroup from '../Attributes/AttributeGroup';
import cx from 'classnames';
import { AttributeValue } from '../../../models/Attribute/AttributeValue';
import { ProductAttribute } from '../../../models/Product/contracts';
import { Collection } from 'collect.js';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from '../../../context/CartContext/CartContext';
import Product from '../../Product/Product';

interface ProductCardProps {
  id: number;
  name: string;
  image: string;
  price: number;
  stock: number;
  url: string;
  listView: boolean;
  attributes?: Collection<ProductAttribute[]>;
}

function ProductCard({
  id,
  image,
  name,
  price,
  stock,
  url,
  attributes,
  listView
}: ProductCardProps) {
  const navigate = useNavigate();
  const { cart, cartAddProduct, cartRemoveProduct } = useCartContext();
  const isProductInCart = cart?.products.some((product) => product.id === id);
  return (
    <div className={cx(style.productCard, listView ? style.list : null)}>
      {/*     {stock < 1 ? (
        <div className={style.stockage}>
          <OutOfStock />
        </div>
      ) : null} */}

      <div
        className={cx(style.imageWrapper, isProductInCart ? style.active : '')}
        onClick={() => navigate(url)}
      >
        <img src={image} alt={name} />
        {/* <img src="/assets/products/1.jpg" alt={name} /> */}
      </div>
      <div className={style.contentWrapper}>
        <div className={style.heading}>
          <div className={style.title} onClick={() => navigate(url)}>
            {name}
          </div>

          {!isProductInCart ? (
            <ButtonPrimary onClick={() => cartAddProduct(id, 1)}>
              Dodaj
            </ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={() => cartRemoveProduct(id)}>
              Ukloni
            </ButtonPrimary>
          )}

          {/* {listView && (
            <div className={style.price}>
              {price} {process.env.REACT_APP_DEFAULT_CURRENCY}
            </div>x
          )} */}
        </div>
        {listView && (
          <div className={style.attributesWrapper}>
            {attributes
              ?.map((attributes, key) => (
                <AttributeGroup
                  key={key}
                  title={key}
                  attributes={attributes}
                  isCard={true}
                />
              ))
              .toArray()}
          </div>
        )}
        {listView && (
          <a
            className={style.button}
            onClick={() => {
              navigate(url);
            }}
          >
            Vidi više
          </a>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
