import axiosAPI from '../../../utils/client/axiosAPI';
export enum ActivityType {
  PRODUCT = 'product',
  CATEGORY = 'category',
  ORDER = 'order',
  ORDER_DOCUMENT = 'order_document',
  PROJECT = 'project',
  USER = 'user'
}
export enum EventType {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted'
}

export type Activity = {
  id: number;
  log_name: ActivityType;
  description: string;
  event: EventType;
  subject_type: string;
  subject_id: number;
  causer_type: string;
  causer_id: number;
  causer: Causer | null;
  properties: Properties;
  created_at: string;
  updated_at: string;
};

type Causer = {
  id: number;
  name: string;
};

type Properties = {
  attributes: Record<string, any>;
};

export async function getActitityLog({
  skip,
  take,
  controller
}: {
  skip: number;
  take?: number;
  controller?: AbortController;
}) {
  const { data } = await axiosAPI.get<Activity[]>('activity-log', {
    params: { skip, take },
    signal: controller?.signal
  });

  return data;
}
