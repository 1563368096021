import style from './mini-cart.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import {
  FaTimes as CloseIcon,
  FaRegTrashAlt as DeleteIcon,
  FaPlus as PlusIcon,
  FaMinus as MinusIcon
} from 'react-icons/fa';
import { useCartContext } from '../../../context/CartContext/CartContext';

export interface miniCartProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function MiniCart(props: miniCartProps) {
  const { opened, setOpened } = props;
  const { cart, cartRemoveProduct } = useCartContext();
  return (
    <div className={cx(style.miniCartWrapper, opened ? style.opened : null)}>
      <div className={style.miniCartContainer}>
        <div className={style.header}>
          <div className={style.title}>Pregled Rezervacije</div>
          <CloseIcon
            className={style.closeIcon}
            onClick={() => setOpened((opened: boolean) => !opened)}
          />
        </div>
        <div className={style.productsWrapper}>
          <Scrollbars className={style.scrollbarWrapper}>
            {cart?.products.map(({ id, name, sku, quantity, images }) => (
              <div className={style.cartProduct} key={id}>
                <div className={style.imageWrapper}>
                  <img src={images?.at(0) ?? ''} alt={name} />
                </div>
                <div className={style.contentWrapper}>
                  <div className={style.left}>
                    <div className={style.name}>{name}</div>
                  </div>
                  <div className={style.right}>
                    <span
                      className={style.deleteButton}
                      onClick={() => cartRemoveProduct(id)}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Scrollbars>
        </div>
        <div className={style.footer}>
          <NavLink
            className={style.buttonPrimary}
            onClick={() => {
              setOpened(false);
            }}
            to="/cart"
          >
            Pregled odabranog
          </NavLink>
          <NavLink
            className={style.buttonSecondary}
            onClick={() => {
              setOpened(false);
            }}
            to="/checkout"
          >
            Plaćanje
          </NavLink>
        </div>
      </div>
    </div>
  );
}
export default MiniCart;
