import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { FormikContextType, FormikContext, FieldArray } from 'formik';
import { useContext, useMemo } from 'react';
import { Row, Col, FormGroup, FormLabel, Accordion } from 'react-bootstrap';
import { Dropzone, DropzonePreview } from '../../../../dropzone';
import { Attribute } from '../../../../models/Attribute/Attribute';
import { Product } from '../../../../models/Product/Product';
import axiosAPI from '../../../../utils/client/axiosAPI';
import { ErrorMessageLabel } from '../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../../components/InputField/InputField';
import { StoreProductValues } from '../CreateProductForm';
import ProductImages from '../ProductImages';

interface StepFiveProps {
  product?: Product;
}

function StepFive({ product }: StepFiveProps) {
  const { data: storage } = useQuery(['storage'], async () => {
    const { data } = await axiosAPI.get(`/product/test`);
    return data;
  });

  const { values, setFieldValue } =
    useContext<FormikContextType<Required<StoreProductValues>>>(FormikContext);

  const { data: attributes = [] } = useQuery(['attributes'], () =>
    Attribute.all('values')
  );
  const attributeValueMap = useMemo(() => {
    const result = new Map<number, string>();
    attributes.forEach(({ options }) => {
      options?.forEach(({ id, value }) => {
        result.set(id, value);
      });
    });
    return result;
  }, [attributes]);

  const variationsMap = useMemo(() => {
    const result = new Map<string, Product>();
    product?.variations?.forEach((product) => {
      const key = product.attributes?.map(({ id }) => id).join('-');
      if (!key) {
        return;
      }
      result.set(key, product);
    });
    return result;
  }, [product?.variations]);

  return (
    <>
      <FormGroup>
        <FormLabel>Varijacije</FormLabel>
        <FieldArray
          name="variations"
          render={() => {
            return (
              <Accordion className="cs-variation-form" defaultActiveKey="0">
                {values.variations &&
                  values.variations?.map((variation, index) => {
                    const key = variation.attribute_values.join('-');
                    return (
                      <Accordion.Item key={index} eventKey={`${index}`}>
                        <Accordion.Header>
                          {variation.attribute_values
                            .map((id) => attributeValueMap.get(id))
                            .join(', ')}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col>
                              <FormGroup>
                                <FormLabel>Name</FormLabel>
                                <InputField
                                  value={values.variations?.[index].name}
                                  name={`variations.${index}.name`}
                                  autoComplete="off"
                                />
                                <ErrorMessageLabel
                                  name={`variations.${index}.name`}
                                />
                              </FormGroup>
                              <FormGroup>
                                <FormLabel>Sku</FormLabel>
                                <InputField
                                  value={values.variations?.[index].sku}
                                  name={`variations.${index}.sku`}
                                  autoComplete="off"
                                />
                                <ErrorMessageLabel
                                  name={`variations.${index}.sku`}
                                />
                              </FormGroup>

                              <FormGroup>
                                <FormLabel>Izaberi Lokaciju</FormLabel>
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Izaberi lokaciju"
                                  value={values.variations?.[index].stand}
                                  onChange={(value: number) => {
                                    setFieldValue(
                                      `variations.${index}.stand`,
                                      value,
                                      true
                                    );
                                  }}
                                  optionFilterProp="children"
                                >
                                  {storage?.map(({ id, name, stands }: any) => (
                                    <Select.OptGroup key={id} label={name}>
                                      {stands?.map(({ id, name }: any) => (
                                        <Select.Option key={id} value={id}>
                                          {name}
                                        </Select.Option>
                                      ))}
                                    </Select.OptGroup>
                                  ))}
                                </Select>
                                <ErrorMessageLabel
                                  name={`variations.${index}.stand`}
                                />
                              </FormGroup>

                              <FormGroup>
                                <FormLabel>Price</FormLabel>
                                <InputField
                                  name={`variations.${index}.price`}
                                  type="number"
                                  step={0.1}
                                  autoComplete="off"
                                />
                                <ErrorMessageLabel
                                  name={`variations.${index}.price`}
                                />
                              </FormGroup>
                              <FormGroup>
                                <FormLabel>Points</FormLabel>
                                <InputField
                                  name={`variations.${index}.points`}
                                  type="number"
                                  step={1}
                                  autoComplete="off"
                                />
                                <ErrorMessageLabel
                                  name={`variations.${index}.points`}
                                />
                              </FormGroup>
                            </Col>
                            {/* <Col>
                              <FormGroup>
                                <FormLabel>Gallery</FormLabel>
                                <Dropzone
                                  onSelectFiles={(newFiles) => {
                                    setFieldValue(
                                      `variations.${index}.images`,
                                      [
                                        ...values.variations?.[index].images,
                                        ...newFiles
                                      ]
                                    );
                                  }}
                                ></Dropzone>
                                <DropzonePreview
                                  files={values.variations[index].images}
                                  onRemove={(index) => {
                                    const newFiles = [
                                      ...values.variations[index].images
                                    ];
                                    newFiles.splice(index, 1);
                                    setFieldValue(
                                      `variations.${index}.images`,
                                      [...newFiles]
                                    );
                                  }}
                                >
                                  {variationsMap.has(key) && (
                                    <ProductImages
                                      product={variationsMap.get(key)!}
                                    />
                                  )}
                                </DropzonePreview>
                                <ErrorMessageLabel
                                  name={`variations.${index}.images`}
                                />
                              </FormGroup>
                            </Col> */}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
            );
          }}
        />
      </FormGroup>
    </>
  );
}

export default StepFive;
