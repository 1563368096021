import { FieldArray, Form, Formik } from 'formik';
import { Button, Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Filter } from '../../../models/Filter/Filter';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { InputField } from '../../components/InputField/InputField';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function EditFilter() {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = useQueryClient();

  const { data: filter, isLoading } = useQuery(
    ['filter', id],
    () => Filter.get(id!),
    {
      enabled: typeof id !== 'undefined'
    }
  );

  if (isLoading) {
    return <h4>Loading...</h4>;
  }

  return (
    <>
      <h1 className="cs-title">Kreiraj Filter</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Formik
        initialValues={{
          name: filter?.name ?? '',
          values: filter?.options?.map(({ value }) => ({ value })) ?? []
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(255, 'name.max')
            .required('Morate uneti ime filtera'),
          values: Yup.array()
            .max(64, 'values.max')
            .required()
            .of(
              Yup.object().shape({
                value: Yup.string().required('Potrebna je vrednost filtera')
              })
            )
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          filter
            ?.update(values)
            .then(() => {
              client
                .invalidateQueries({
                  queryKey: ['filters'],
                  exact: true,
                  refetchType: 'active'
                })
                .then(() => navigate('/admin/filters/list'));
              client.invalidateQueries({
                queryKey: ['filter', id],
                exact: true,
                refetchType: 'none'
              });
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form autoComplete="off" className="cs-form-wrapper">
            <FormGroup>
              <Col lg={6}>
                <FormLabel>Ime Filtera</FormLabel>
                <InputField
                  placeholder="ime filtera"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessageLabel name="name" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col lg={6}>
                <FormLabel>Vrednosti Filtera</FormLabel>
                <br />
                <FieldArray name="values">
                  {({ push, remove }) => {
                    return (
                      <>
                        {values.values?.map((_, index) => (
                          <Row key={index}>
                            <Col lg={10} className="mb-3">
                              <InputField
                                placeholder="Filter value"
                                name={`values.${index}.value`}
                                autoComplete="off"
                              />
                              <ErrorMessageLabel
                                name={`values.${index}.value`}
                              />
                            </Col>
                            <Col
                              lg={2}
                              className="d-flex justify-content-end align-items-start"
                            >
                              <Button
                                variant="outline-light"
                                className="JHover"
                                onClick={() => remove(index)}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          variant="outline-light"
                          className="ms-1 mt-2  btn-lg JHover"
                          onClick={() =>
                            push({
                              value: ''
                            })
                          }
                        >
                          <i className="fa-solid fa-circle-plus"></i>
                        </Button>
                      </>
                    );
                  }}
                </FieldArray>
              </Col>
            </FormGroup>
            <div>
              <ButtonPrimary type="submit" disabled={isSubmitting}>
                Sačuvaj Filtere
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditFilter;
