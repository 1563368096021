import { RentPeriodResponse, RentPeriodStorePayload } from './contracts';
import axiosAPI from '../../utils/client/axiosAPI';

export class RentPeriod {
  public readonly id: number;
  public readonly duration: number;
  public readonly priceFactor: number;
  public readonly pointFactor: number;

  public constructor({
    id,
    duration,
    price_factor,
    point_factor
  }: RentPeriodResponse) {
    this.id = id;
    this.duration = duration;
    this.priceFactor = price_factor;
    this.pointFactor = point_factor;
  }

  public static async all(...relations: string[]): Promise<RentPeriod[]> {
    const { data } = await axiosAPI.get<RentPeriodResponse[]>('rent-periods');
    return data.map((data) => new RentPeriod(data));
  }

  public static async store(
    payload: RentPeriodStorePayload
  ): Promise<RentPeriod> {
    const { data } = await axiosAPI.post<RentPeriodResponse>(
      '/rent-periods',
      payload
    );
    return new RentPeriod(data);
  }

  public static async get(id: string | number): Promise<RentPeriod> {
    const { data } = await axiosAPI.get<RentPeriodResponse>(
      `/rent-periods/${id}`
    );
    return new RentPeriod(data);
  }

  public async delete() {
    return await axiosAPI.delete<null>(`/rent-periods/${this.id}`);
  }

  public static async delete(id: number) {
    return await axiosAPI.delete<null>(`/rent-periods/${id}`);
  }

  public async update(payload: RentPeriodStorePayload): Promise<any> {
    return axiosAPI.put(`/rent-periods/${this.id}`, payload);
  }
}
