import { PropsWithChildren } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import style from './button.module.scss';

interface ButtonPrimaryProps extends ButtonProps {
  url?: string;
}

export const ButtonPrimary = ({
  url,
  onClick,
  ...props
}: PropsWithChildren<ButtonPrimaryProps>) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={(event) => (!url ? onClick?.(event) : navigate(url))}
      {...props}
      className={cx(
        style.mainButton,
        props.className ? props.className : style.defaultClassButton
      )}
    ></button>
  );
};
