import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Product } from '../../../models/Product/Product';
import CreateProductForm from './CreateProductForm';

function EditProduct() {
  const { id } = useParams();

  const { data: product } = useQuery(['product', id], () => Product.get(id!), {
    enabled: typeof id !== 'undefined'
  });

  if (!product) {
    return <></>;
  }

  return <CreateProductForm productType={product.type} product={product} />;
}

export default EditProduct;
