import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { collect } from 'collect.js';
import { FormikContextType, FormikContext, Field, FieldArray } from 'formik';
import { useContext, useEffect, useMemo } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Attribute } from '../../../../models/Attribute/Attribute';
import { Product } from '../../../../models/Product/Product';
import { combineArrays } from '../../../../utils/helpers/helpers';
import { ErrorMessageLabel } from '../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { StoreProductValues } from '../CreateProductForm';

interface StepTwoProps {
  product?: Product;
}

function StepTwo({ product }: StepTwoProps) {
  const { values, setValues } =
    useContext<FormikContextType<StoreProductValues>>(FormikContext);

  const { data: attributes = [] } = useQuery(['attributes'], () =>
    Attribute.all('values')
  );
  const attributeMap = useMemo(() => Attribute.toMap(attributes), [attributes]);
  const variationsMap = useMemo(() => {
    const result = new Map<string, Product>();
    product?.variations?.forEach((product) => {
      const key = product.attributes?.map(({ id }) => id).join('-');
      if (!key) {
        return;
      }
      result.set(key, product);
    });
    return result;
  }, [product?.variations]);

  const getAttributeRelatedFields = (attributes: number[]) => {
    const attributeValues: { [key: number]: number[] } = {};
    attributes.forEach((attributeID) => {
      attributeValues[attributeID] = values.attribute_values[attributeID] ?? [];
    });
    return {
      attributes,
      attribute_values: attributeValues
    };
  };

  const setAttributeValues = (attributeValues: { [key: number]: number[] }) => {
    setValues(
      {
        ...values,
        attribute_values: attributeValues,
        variations: combineArrays(Object.values(attributeValues)).map(
          (attributeValueIDs) => {
            const key = attributeValueIDs.join('-');
            return {
              attribute_values: attributeValueIDs,
              name: variationsMap.get(key)?.name ?? '',
              sku: variationsMap.get(key)?.sku ?? '',
              stand: variationsMap.get(key)?.stand?.id ?? '',
              price: variationsMap.get(key)?.price ?? 0,
              points: variationsMap.get(key)?.points ?? 0,
              images: []
            };
          }
        )
      },
      true
    );
  };

  useEffect(() => {
    setAttributeValues(values.attribute_values);
  }, []);

  return (
    <>
      <FormGroup>
        <FormLabel>Izaberi atribute</FormLabel>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          placeholder="Izaberi atribute"
          value={values.attributes}
          onChange={(value: number[]) => {
            setValues({
              ...values,
              ...getAttributeRelatedFields(value)
            });
          }}
        >
          {attributes?.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
        <ErrorMessageLabel name="attributes" />
      </FormGroup>
      <FormGroup>
        <FieldArray name="attribute_values">
          {() => (
            <>
              {values.attributes?.map((attributeID) => {
                if (!attributeMap.has(attributeID)) {
                  return null;
                }

                const { name, options } = attributeMap.get(
                  attributeID
                ) as Attribute;

                return (
                  <FormGroup key={attributeID}>
                    <FormLabel>{name}</FormLabel>
                    <Field
                      value={values.attribute_values[attributeID]}
                      name={`attribute_values.${attributeID}`}
                      style={{ width: '100%' }}
                      component={Select}
                      mode={'multiple'}
                      onChange={(value: number[]) => {
                        setAttributeValues({
                          ...values.attribute_values,
                          [attributeID]: value
                        });
                      }}
                    >
                      {options?.map(({ id, value }) => (
                        <Select.Option key={id} value={id}>
                          {value}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessageLabel
                      name={`attribute_values.${attributeID}`}
                    />
                  </FormGroup>
                );
              })}
            </>
          )}
        </FieldArray>
        <ErrorMessageLabel name="attribute_values" />
      </FormGroup>
    </>
  );
}

export default StepTwo;
