import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Field, Form, Formik, FormikProps } from 'formik';
import { InputField } from '../../../../components/InputField/InputField';
import { ErrorMessageLabel } from '../../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { ButtonPrimary } from '../../../../components/Button/ButtonPrimary';
import { Textarea } from '../../../../components/Textarea/Textarea';
import * as Yup from 'yup';
import { createOrder } from '../queries';
import { UserCustomerType } from '../../../../../models/User/User';
import { NavLink } from 'react-router-dom';
import style from '../create-order.module.scss';
import cx from 'classnames';
import { useCartContext } from '../../../../../context/CartContext/CartContext';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

interface OrderFormProps {
  products: number[];
  onAfterSubmit?: () => void;
}

const OrderForm = ({ products, onAfterSubmit }: OrderFormProps) => {
  const { cart, cartSetStartEndDates } = useCartContext();
  type FormValues = {
    cart_token: string;
    full_name: string;
    number_of_order: number;
    type: UserCustomerType;
    company_name: string;
    company_tax_number: number | string;
    company_id_number: number | string;
    email: string;
    state: string;
    city: string;
    zip: number | '';
    address: string;
    phone?: string;
    note?: string;
    washing_fee: boolean;
    reservation_start: Date | string;
    reservation_end: Date | string;
    products: number[];
  };

  const initialValues: FormValues = useMemo(
    () => ({
      cart_token: cart?.token ?? '',
      full_name: '',
      number_of_order: 0,
      type: UserCustomerType.INDIVIDUAL,
      company_name: '',
      company_tax_number: '',
      company_id_number: '',
      email: '',
      phone: '',
      state: '',
      city: '',
      address: '',
      zip: '',
      note: '',
      washing_fee: false,
      reservation_start: '',
      reservation_end: '',
      products
    }),
    [cart]
  );

  const validationSchema = Yup.object({
    full_name: Yup.string().required(
      'Potrebno je da unesete ime osobe koja salje zahtev.'
    ),
    type: Yup.string().required(),
    company_name: Yup.string().when('type', {
      is: UserCustomerType.COMPANY,
      then: Yup.string().required('Company name is required')
    }),
    company_tax_number: Yup.mixed().when('type', {
      is: UserCustomerType.COMPANY,
      then: Yup.string()
        .min(9, 'Broj mora sadrzati najmanje 9 cifara')
        .required('PIB je obavezno polje')
    }),
    company_id_number: Yup.mixed().when('type', {
      is: UserCustomerType.COMPANY,
      then: Yup.string()
        .min(8, 'Broj mora sadrzati najmanje 8 cifara')
        .required('Maticni broj je obavezno polje')
    }),
    email: Yup.string()
      .email('email nije validan')
      .required('Potrebno je da unesete email'),
    state: Yup.string().required('Drzava je obavezno polje'),
    city: Yup.string().required('Grad je obavezno polje'),
    zip: Yup.string().required('Postanski broj je obavezno polje'),
    address: Yup.string().required('Adresa je obavezno polje'),
    reservation_start: Yup.date().required('Unesite datum pocetka rezervacije'),
    reservation_end: Yup.date()
      .required('Unesite datum kraja rezervacije')
      .min(
        Yup.ref('reservation_start'),
        'Datum kraja rezervacije mora biti veci od datuma pocetka rezervacije'
      ),
    products: Yup.array().min(1, 'Potrebno je izabrati bar 1 proizvod')
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        // console.log(values);

        createOrder({ ...values })
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            setSubmitting(false);
            resetForm();
            onAfterSubmit?.();
          });
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="number_of_order">Broj Porudzbine</label>
                <Field name="number_of_order" type="number" />
                <ErrorMessageLabel name="number_of_order" />
              </div>
            </Col>
          </Row>
          <Row>
            <div className={style.formGroup}>
              <label htmlFor="type">Odaberite način poručivanja:</label>
              <div className={style.userRoleSwitch}>
                <div
                  className={cx(
                    style.button,
                    values.type == UserCustomerType.INDIVIDUAL
                      ? style.active
                      : null
                  )}
                  onClick={() => {
                    setFieldValue('type', UserCustomerType.INDIVIDUAL);
                  }}
                >
                  Fizičko lice
                </div>
                <div
                  className={cx(
                    style.button,
                    values.type == UserCustomerType.COMPANY
                      ? style.active
                      : null
                  )}
                  onClick={() => {
                    setFieldValue('type', UserCustomerType.COMPANY);
                  }}
                >
                  Pravno lice
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="reservation_start">Početak rezervacije:</label>
                <InputField
                  name="reservation_start"
                  autoComplete="off"
                  type="date"
                  onChange={(e) => {
                    setFieldValue('reservation_start', e.target.value);
                    cartSetStartEndDates(
                      e.target.value,
                      values.reservation_end.toString()
                    );
                  }}
                />
                <ErrorMessageLabel name="reservation_start" />
              </div>
            </Col>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="reservation_end">Kraj rezervacije:</label>
                <InputField
                  name="reservation_end"
                  autoComplete="off"
                  type="date"
                  onChange={(e) => {
                    setFieldValue('reservation_end', e.target.value);
                    cartSetStartEndDates(
                      values.reservation_start.toString(),
                      e.target.value
                    );
                  }}
                />
                <ErrorMessageLabel name="reservation_end" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="full_name">Ime:</label>
                <Field name="full_name" type="text" />
                <ErrorMessageLabel name="full_name" />
              </div>
            </Col>
            {values.type == UserCustomerType.COMPANY && (
              <Col>
                <div className={style.formGroup}>
                  <label htmlFor="company_name">Naziv Kompanije:</label>
                  <Field name="company_name" type="text" />
                  <ErrorMessageLabel name="company_name" />
                </div>
              </Col>
            )}
          </Row>
          {values.type == UserCustomerType.COMPANY && (
            <Row>
              <Col>
                <div className={style.formGroup}>
                  <label htmlFor="company_id_number">Matični broj:</label>
                  <Field name="company_id_number" type="text" />
                  <ErrorMessageLabel name="company_id_number" />
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label htmlFor="company_tax_number">PIB:</label>
                  <Field name="company_tax_number" type="text" />
                  <ErrorMessageLabel name="company_tax_number" />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="email">Email adresa:</label>
                <Field name="email" type="email" />
                <ErrorMessageLabel name="email" />
              </div>
            </Col>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="phone">Broj telefona:</label>
                <Field name="phone" type="text" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="state">Država:</label>
                <Field name="state" type="text" />
                <ErrorMessageLabel name="state" />
              </div>
            </Col>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="city">Grad:</label>
                <Field name="city" type="text" />
                <ErrorMessageLabel name="city" />
              </div>
            </Col>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="zip">Poštanski broj:</label>
                <Field name="zip" type="text" />
                <ErrorMessageLabel name="zip" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="address">Adresa:</label>
                <Field name="address" type="text" />
                <ErrorMessageLabel name="address" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={style.formGroup}>
                <label htmlFor="note">Dodatne napomene:</label>
                <Field name="note" type="textarea" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cx(style.formGroup, style.checkboxField)}>
                <label htmlFor="washing_fee">Dodatna naknada za pranje:</label>
                <label className={style.checkboxLabel}>
                  <input
                    type="checkbox"
                    name="washing_fee"
                    checked={values.washing_fee}
                    onChange={(e) => {
                      setFieldValue('washing_fee', e.target.checked);
                    }}
                  />
                  <p>
                    Ukoliko čekirate ovo polje plaćate dodatnih 10% na ukupnu
                    cenu za uslugu pranja kostima.
                  </p>
                </label>
                <ErrorMessageLabel name="washing_fee" />
              </div>
            </Col>
          </Row>

          <div className={style.footer}>
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              Kreiraj
            </ButtonPrimary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrderForm;
