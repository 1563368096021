import { FilterResponse, FilterStorePayload } from './contracts';
import { FilterValue } from './FilterValue';
import axiosAPI from '../../utils/client/axiosAPI';

export class Filter {
  public readonly id: number;
  public readonly name: string;
  public readonly options?: FilterValue[];

  public constructor({ id, name, options }: FilterResponse) {
    this.id = id;
    this.name = name;
    this.options = options?.map((data) => new FilterValue(data));
  }

  public static async get(id: string | number): Promise<Filter> {
    const { data } = await axiosAPI.get<FilterResponse>(`/filters/${id}`);
    return new Filter(data);
  }

  public static async all(...relations: string[]): Promise<Filter[]> {
    const { data } = await axiosAPI.get<FilterResponse[]>(
      relations.length === 0
        ? '/filters'
        : `/filters?with=${relations.join(',')}`
    );
    return data.map((data) => new Filter(data));
  }

  public static async store(payload: FilterStorePayload): Promise<Filter> {
    const { data } = await axiosAPI.post<FilterResponse>('/filters', payload);
    return new Filter(data);
  }

  public async update(payload: FilterStorePayload): Promise<any> {
    return axiosAPI.put<FilterResponse>(`/filters/${this.id}`, payload);
  }
}
