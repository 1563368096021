import ShopSideBar from '../../components/SideBar/ShopSideBar';
import style from './checkout.module.scss';
import cx from 'classnames';
import { useCartContext } from '../../../context/CartContext/CartContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { UserCustomerType } from '../../../models/User/User';
import { InputField } from '../../../Admin/components/InputField/InputField';
import { ErrorMessageLabel } from '../../../Admin/components/ErrorMessageLabel/ErrorMessageLabel';
import { useMutation } from '@tanstack/react-query';
import { CreateOrderPayload, createOrderCheckout } from './queries';

const CheckoutValidationScheme = Yup.object({
  full_name: Yup.string().required(
    'Potrebno je da unesete ime osobe koja salje zahtev.'
  ),
  type: Yup.string().required(),
  company_name: Yup.string().when('type', {
    is: UserCustomerType.COMPANY,
    then: Yup.string().required('Company name is required')
  }),
  company_tax_number: Yup.mixed().when('type', {
    is: UserCustomerType.COMPANY,
    then: Yup.string()
      .min(9, 'Broj mora sadrzati najmanje 9 cifara')
      .required('PIB je obavezno polje')
  }),
  company_id_number: Yup.mixed().when('type', {
    is: UserCustomerType.COMPANY,
    then: Yup.string()
      .min(8, 'Broj mora sadrzati najmanje 8 cifara')
      .required('Maticni broj je obavezno polje')
  }),
  email: Yup.string()
    .email('email nije validan')
    .required('Potrebno je da unesete email'),
  state: Yup.string().required('Drzava je obavezno polje'),
  city: Yup.string().required('Grad je obavezno polje'),
  zip: Yup.string().required('Postanski broj je obavezno polje'),
  address: Yup.string().required('Adresa je obavezno polje'),
  reservation_start: Yup.date().required('Unesite datum pocetka rezervacije'),
  reservation_end: Yup.date()
    .required('Unesite datum kraja rezervacije')
    .min(
      Yup.ref('reservation_start'),
      'Datum kraja rezervacije mora biti veci od datuma pocetka rezervacije'
    )
});

function Checkout(props: any) {
  const { collapsedSidebar, setCollapsedSidebar } = props;

  const { cart, cartSetStartEndDates } = useCartContext();

  const navigate = useNavigate();
  const initialValues: CreateOrderPayload = useMemo(
    () => ({
      cart_token: cart?.token ?? '',
      products: cart?.products.map((products) => products.id) ?? [],
      full_name: '',
      type: UserCustomerType.INDIVIDUAL,
      company_name: '',
      company_tax_number: '',
      company_id_number: '',
      email: '',
      phone: '',
      state: '',
      city: '',
      address: '',
      zip: '',
      note: '',
      washing_fee: false,
      reservation_start: cart?.startDate ?? '',
      reservation_end: cart?.endDate ?? ''
    }),
    [cart, cartSetStartEndDates]
  );

  const { mutate } = useMutation({
    mutationFn: (data: CreateOrderPayload) => createOrderCheckout(data)
  });

  return (
    <div className={style.pageContainer}>
      <ShopSideBar
        collapsed={collapsedSidebar}
        setCollapsed={setCollapsedSidebar}
        // renderItems={renderSideBar}
        heading="Vaša porudžbina"
      >
        <div className={style.checkoutOrderWrapper}>
          <div className={style.productsWrapper}>
            {cart?.products.map(({ id, name, sku, quantity, images }) => (
              <div className={style.cartProduct} key={id}>
                <div className={style.imageWrapper}>
                  <img src={images.at(0) ?? ''} alt={name} />
                </div>
                <div className={style.contentWrapper}>
                  <div className={style.left}>
                    <div className={style.name}>{name}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={style.footer}>
            <p>Posaljite upit da dobijete cenu.</p>
            {/* <div>
              <div className={style.label}>Predračun:</div>
              <div className={style.value}>0RSD</div>
            </div>
            <div>
              <div className={style.label}>PDV:</div>
              <div className={style.value}>0RSD</div>
            </div>
            <div>
              <div className={style.label}>Ukupno sa PDV:</div>
              <div className={style.value}>0RSD</div>
            </div> */}
          </div>
        </div>
      </ShopSideBar>

      <div
        className={cx(
          style.checkoutPageWrapper,
          collapsedSidebar ? style.collapsed : null,
          'cs-content'
        )}
      >
        <h1 className={style.title}>Poručivanje</h1>

        <Container className={style.checkoutInfoWrapper}>
          <Formik
            initialValues={initialValues}
            validationSchema={CheckoutValidationScheme}
            validateOnChange
            enableReinitialize
            validateOnMount
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              mutate(values, {
                onSuccess: () => {
                  navigate('/thank-you');
                },
                onError: (error) => {
                  console.error(error);
                },
                onSettled: () => {
                  setSubmitting(false);
                }
              });
            }}
          >
            {({ values, setFieldValue, isValid, isSubmitting }) => {
              return (
                <Form>
                  <Row>
                    <div className={style.formGroup}>
                      <label htmlFor="type">Odaberite način poručivanja:</label>
                      <div className={style.userRoleSwitch}>
                        <div
                          className={cx(
                            style.button,
                            values.type == UserCustomerType.INDIVIDUAL
                              ? style.active
                              : null
                          )}
                          onClick={() => {
                            setFieldValue('type', UserCustomerType.INDIVIDUAL);
                          }}
                        >
                          Fizičko lice
                        </div>
                        <div
                          className={cx(
                            style.button,
                            values.type == UserCustomerType.COMPANY
                              ? style.active
                              : null
                          )}
                          onClick={() => {
                            setFieldValue('type', UserCustomerType.COMPANY);
                          }}
                        >
                          Pravno lice
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="reservation_start">
                          Početak rezervacije:
                        </label>
                        <InputField
                          name="reservation_start"
                          autoComplete="off"
                          type="date"
                        />
                        <ErrorMessageLabel name="reservation_start" />
                      </div>
                    </Col>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="reservation_end">
                          Kraj rezervacije:
                        </label>
                        <InputField
                          name="reservation_end"
                          autoComplete="off"
                          type="date"
                        />
                        <ErrorMessageLabel name="reservation_end" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="full_name">Ime:</label>
                        <Field name="full_name" type="text" />
                        <ErrorMessageLabel name="full_name" />
                      </div>
                    </Col>
                    {values.type == UserCustomerType.COMPANY && (
                      <Col>
                        <div className={style.formGroup}>
                          <label htmlFor="company_name">Naziv Kompanije:</label>
                          <Field name="company_name" type="text" />
                          <ErrorMessageLabel name="company_name" />
                        </div>
                      </Col>
                    )}
                  </Row>
                  {values.type == UserCustomerType.COMPANY && (
                    <Row>
                      <Col>
                        <div className={style.formGroup}>
                          <label htmlFor="company_id_number">
                            Matični broj:
                          </label>
                          <Field name="company_id_number" type="text" />
                          <ErrorMessageLabel name="company_id_number" />
                        </div>
                      </Col>
                      <Col>
                        <div className={style.formGroup}>
                          <label htmlFor="company_tax_number">PIB:</label>
                          <Field name="company_tax_number" type="text" />
                          <ErrorMessageLabel name="company_tax_number" />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="email">Email adresa:</label>
                        <Field name="email" type="email" />
                        <ErrorMessageLabel name="email" />
                      </div>
                    </Col>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="phone">Broj telefona:</label>
                        <Field name="phone" type="text" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="state">Država:</label>
                        <Field name="state" type="text" />
                        <ErrorMessageLabel name="state" />
                      </div>
                    </Col>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="city">Grad:</label>
                        <Field name="city" type="text" />
                        <ErrorMessageLabel name="city" />
                      </div>
                    </Col>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="zip">Poštanski broj:</label>
                        <Field name="zip" type="text" />
                        <ErrorMessageLabel name="zip" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="address">Adresa:</label>
                        <Field name="address" type="text" />
                        <ErrorMessageLabel name="address" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={style.formGroup}>
                        <label htmlFor="note">Dodatne napomene:</label>
                        <Field name="note" type="textarea" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={cx(style.formGroup, style.checkboxField)}>
                        <label htmlFor="washing_fee">
                          Dodatna naknada za pranje:
                        </label>
                        <label className={style.checkboxLabel}>
                          <input
                            type="checkbox"
                            name="washing_fee"
                            checked={values.washing_fee}
                            onChange={(e) => {
                              setFieldValue('washing_fee', e.target.checked);
                            }}
                          />
                          <p>
                            Ukoliko čekirate ovo polje plaćate dodatnih 10% na
                            ukupnu cenu za uslugu pranja kostima.
                          </p>
                        </label>
                        <ErrorMessageLabel name="washing_fee" />
                      </div>
                    </Col>
                  </Row>
                  {/* <Col>
                      <div className={cx(style.formGroup, style.checkboxField)}>
                        <label htmlFor="washing_fee">
                          Dodatna naknada za pranje:
                        </label>
                        <div>
                          <Field
                            name="washing_fee"
                            type="checkbox"
                            checked={values.washing_fee}
                            onChange={(e: any) => {
                              setFieldValue('washing_fee', e.target.checked);
                            }}
                          />
                          <p>
                            Ukoliko čekirate ovo polje plaćate dodatnih 10% na
                            ukupnu cenu za uslugu pranja kostima.
                          </p>
                        </div>
                        <ErrorMessageLabel name="washing_fee" />
                      </div>
                    </Col>
                  </Row> */}

                  <div className={style.footer}>
                    <NavLink className={style.buttonSecondary} to={'/'}>
                      Nazad na prodavnicu
                    </NavLink>
                    <button
                      className="cs-primary-button-full"
                      disabled={!isValid || isSubmitting}
                    >
                      Poruči
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>
    </div>
  );
}

export default Checkout;
