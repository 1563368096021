import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table/interface';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import { RentPeriod } from '../../../models/RentPeriod/RentPeriod';
import { FaEdit as EditIcon, FaTrash as DeleteIcon } from 'react-icons/fa';

import style from './rent-period-list.module.scss';
import { useMemo } from 'react';
import { Project } from '../../../models/Project/Project';

export default function RentPeriodList() {
  type DataType = {
    key: number;
    id: number;
    duration: number;
    priceFactor: number;
    pointFactor: number;
  };
  const { data: rentPeriod, refetch } = useQuery(['rent-periods'], () =>
    RentPeriod.all()
  );

  const initialValues: DataType[] = useMemo(
    () =>
      rentPeriod?.map((period, ind) => ({
        key: period.id,
        id: period.id,
        duration: period.duration,
        priceFactor: period.priceFactor,
        pointFactor: period.pointFactor
      })) ?? [],
    [rentPeriod]
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Period [dana]',
      dataIndex: 'duration'
    },
    {
      title: 'Faktor Cene',
      dataIndex: 'priceFactor'
    },
    {
      title: 'Faktor Poena',
      dataIndex: 'pointFactor'
    },
    {
      title: 'Akcije',
      render(_, row) {
        return (
          <div className="cs-table-controlls">
            <Link to={`/admin/rent-periods/edit/${row.id}`}>
              <EditIcon />
            </Link>
            <button
              className="cs-delete-button"
              onClick={() => {
                if (
                  window.confirm('Da li ste sigurni da želite da izbrišete ?')
                ) {
                  RentPeriod.delete(row.id).then(() => refetch());
                }
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="cs-inner-container">
      <h1 className="cs-title">Lista Perioda Izdavanja</h1>
      <div className={style.info}>
        <div className={style.number}>
          Trenutno imate <span>{rentPeriod?.length}</span> perioda
        </div>
        <ButtonPrimary url="/admin/rent-periods/create">
          Dodaj Period
        </ButtonPrimary>
      </div>
      <Table
        columns={columns}
        dataSource={initialValues}
        className="cs-table-wrapper"
        rowKey={'id'}
      />
    </div>
  );
}
