import { FeatureResponse, FeatureStorePayload } from './contracts';
import { FeatureValue } from './FeatureValue';
import axiosAPI from '../../utils/client/axiosAPI';

export class Feature {
  public readonly id: number;
  public readonly name: string;
  public readonly categories?: any[];
  public readonly options?: FeatureValue[];

  public constructor({ id, name, categories, options }: FeatureResponse) {
    this.id = id;
    this.name = name;
    this.categories = categories;
    this.options = options?.map((data) => new FeatureValue(data));
  }

  public static async all(...relations: string[]): Promise<Feature[]> {
    const { data } = await axiosAPI.get<FeatureResponse[]>(
      relations.length === 0
        ? '/features'
        : `/features?with=${relations.join(',')}`
    );
    return data.map((data) => new Feature(data));
  }

  public static async store(payload: FeatureStorePayload): Promise<Feature> {
    const { data } = await axiosAPI.post<FeatureResponse>('/features', payload);
    return new Feature(data);
  }

  public static toMap(features: Feature[]): Map<number, Feature> {
    const result = new Map<number, Feature>();
    features.forEach((feature) => {
      result.set(feature.id, feature);
    });
    return result;
  }

  public static async get(id: string | number): Promise<Feature> {
    const { data } = await axiosAPI.get<FeatureResponse>(`/features/${id}`);
    return new Feature(data);
  }

  public async update(payload: FeatureStorePayload): Promise<any> {
    return axiosAPI.put(`/features/${this.id}`, payload);
  }
}
