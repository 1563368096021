import { Form, FormikProvider, useFormik } from 'formik';
import { Steps } from 'antd';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  ProductType,
  StoreProductPayload
} from '../../../models/Product/contracts';
import { Col, Row } from 'react-bootstrap';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import StepFive from './Steps/StepFive';
import * as Yup from 'yup';
import { Product } from '../../../models/Product/Product';
import { useNavigate } from 'react-router-dom';
import {
  CgArrowLongLeft as PreviousIcon,
  CgArrowLongRight as NextIcon
} from 'react-icons/cg';
import { collect, Collection } from 'collect.js';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export interface StoreProductValues {
  type: ProductType;
  category: number | undefined;
  stand: number | undefined;
  name: string;
  sku: string;
  price: number;
  points: number;
  images: File[];
  attributes: number[];
  features: number[];
  addons: number[];
  attribute_values: { [key: number]: number[] };
  feature_values: { [key: number]: number[] };
  variations?: StoreProductVariationValues[];
}

interface StoreProductVariationValues {
  name: string;
  sku: string;
  price: number;
  stand: number;
  points: number;
  images: File[];
  attribute_values: number[];
}

interface CreateProductFormProps {
  productType: ProductType;
  product?: Product;
}

function collectionToObject<Type = any>(collection: Collection<Type>): any {
  const result: { [key: string | number]: Type } = {};
  collection.each((item, key) => {
    if (!key) {
      return;
    }
    result[key] = item;
  });
  return result;
}

function CreateProductForm({
  productType,
  product
}: PropsWithChildren<CreateProductFormProps>) {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const client = useQueryClient();

  const initialValues = useMemo(() => {
    const values: StoreProductValues = {
      type: productType,
      category: product?.category.id ?? void 0,
      stand: product?.stand?.id,
      name: product?.name ?? '',
      sku: product?.sku ?? '',
      price: product?.price ?? 0,
      points: product?.points ?? 0,
      images: [],
      attributes:
        collect(product?.attributes).pluck('attribute_id').unique().toArray() ??
        [],
      features:
        collect(product?.features).pluck('feature_id').unique().toArray() ?? [],
      addons: [],
      attribute_values: collectionToObject(
        collect(product?.attributes)
          .groupBy('attribute_id')
          .mapWithKeys((value: any, attributeID: any) => {
            return [attributeID, value.pluck('id').toArray()];
          })
      ),
      feature_values: collectionToObject(
        collect(product?.features)
          .groupBy('feature_id')
          .mapWithKeys((value: any, attributeID: any) => {
            return [attributeID, value.pluck('id').toArray()];
          })
      )
    };
    productType === ProductType.VARIABLE && (values.variations = []);
    return values;
  }, [product, productType]);

  const steps = useMemo(() => {
    const imageValidation =
      typeof product === 'undefined'
        ? {
            images: Yup.array()
              .of(Yup.mixed())
              .min(1, 'images.min')
              .max(5, 'images.max')
              .required('images.required')
          }
        : {
            images: Yup.array().of(Yup.mixed()).max(5, 'images.max').optional()
          };

    const steps: Array<{
      title: string;
      content: JSX.Element;
      validationSchema: Yup.AnyObjectSchema;
    }> = [
      {
        title: 'Details',
        content: <StepOne product={product} />,
        validationSchema: Yup.object({
          category: Yup.number().integer().required('category.required'),
          stand: Yup.number().integer().required('stand.required'),
          name: Yup.string().max(255, 'name.max').required('name.required'),
          sku: Yup.string().max(255, 'sku.max').required('sku.required'),
          price: Yup.number().min(0, 'price.min').required('price.required'),
          points: Yup.number().min(0, 'points.min').required('points.required'),
          ...imageValidation
        })
      },
      {
        title: 'Attributes',
        content: <StepTwo product={product} />,
        validationSchema: Yup.object({
          attributes: Yup.array()
            .of(Yup.number().integer())
            .min(1, 'attributes.min')
            .required('attributes.required'),
          attribute_values: Yup.lazy((value) => {
            const shape: {
              [key: string]: Yup.ArraySchema<Yup.NumberSchema>;
            } = {};
            for (const key in value) {
              shape[key] = Yup.array()
                .of(Yup.number().integer())
                .min(1, 'attribute_values.min')
                .required('attribute_values.required');
            }
            return Yup.object().shape(shape);
          })
        })
      },
      {
        title: 'Features',
        content: <StepThree />,
        validationSchema: Yup.object({
          features: Yup.array()
            .of(Yup.number().integer())
            .min(1, 'features.min')
            .required('features.required'),
          feature_values: Yup.lazy((value) => {
            const shape: {
              [key: string]: Yup.ArraySchema<Yup.NumberSchema>;
            } = {};
            for (const key in value) {
              shape[key] = Yup.array()
                .of(Yup.number().integer())
                .min(1, 'feature_values.min')
                .required('feature_values.required');
            }
            return Yup.object().shape(shape);
          })
        })
      }
      // {
      //   title: 'Addons',
      //   content: <StepFour />,
      //   validationSchema: Yup.object({
      //     addons: Yup.array().of(Yup.number().integer()).optional()
      //   })
      // }
    ];
    productType === ProductType.VARIABLE &&
      steps.push({
        title: 'Variations',
        content: <StepFive product={product} />,
        validationSchema: Yup.object().shape({
          variations: Yup.array()
            .of(
              Yup.object().shape({
                attribute_values: Yup.array()
                  .of(Yup.number().integer())
                  .required('variations.attribute_values.required'),
                name: Yup.string()
                  .max(255, 'variations.name.max')
                  .required('variations.name.required'),
                sku: Yup.string()
                  .max(255, 'variations.sku.max')
                  .required('variations.sku.required'),
                stand: Yup.number().required('variations.stand.required'),
                price: Yup.number()
                  .min(0, 'variations.price.min')
                  .required('variations.price.required'),
                points: Yup.number()
                  .min(0, 'variations.points.min')
                  .required('variations.points.required')
                // images: Yup.array()
                //   .min(1, 'variations.images.min')
                //   .max(5, 'variations.images.max')
                //   .required('variations.images.required')
              })
            )
            .required()
        })
      });
    return steps;
  }, [productType, product]);

  useEffect(() => {
    setStep(0);
  }, [productType]);

  const goForward = () => {
    setStep(Math.min(step + 1, steps.length - 1));
  };

  const goBackwards = () => {
    setStep(Math.max(step - 1, 0));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: steps[step].validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      if (step === steps.length - 1) {
        const payload: StoreProductPayload = {
          type: values.type,
          category: values.category,
          stand: values.stand,
          name: values.name,
          sku: values.sku,
          price: values.price,
          points: values.points,
          images: values.images,
          addons: values.addons,
          attribute_values: [],
          feature_values: [],
          variations: []
        };
        for (const key in values.attribute_values) {
          payload.attribute_values.push(...values.attribute_values[key]);
        }
        for (const key in values.feature_values) {
          payload.feature_values.push(...values.feature_values[key]);
        }
        if (productType === ProductType.VARIABLE && !!values.variations) {
          payload.variations?.push(...values.variations);
        }
        setSubmitting(true);

        (typeof product === 'undefined'
          ? Product.store(payload)
          : product.update(payload)
        )
          .then((product) => {
            if (typeof product === 'undefined') {
              resetForm();
              setStep(0);
              toast('Proizvod je uspešno kreiran.');
            } else {
              client
                .invalidateQueries({
                  queryKey: ['products'],
                  exact: true,
                  refetchType: 'active'
                })
                .then(() => navigate('/admin/products/list'));
              client.invalidateQueries({
                queryKey: ['product', product.id],
                exact: true,
                refetchType: 'none'
              });
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
        return;
      }
      formik.setTouched({}, false);
      goForward();
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" className="cs-form-wrapper">
        <Steps current={step}>
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <Row>
          <Col>{steps[step].content}</Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between align-items-center">
            {step > 0 && (
              <button
                className="cs-button-secondary"
                type="button"
                onClick={() => goBackwards()}
              >
                <PreviousIcon />
                <span>Nazad</span>
              </button>
            )}
            {step < steps.length - 1 && (
              <button className="cs-button-primary" type="submit">
                <span>Napred</span>
                <NextIcon />
              </button>
            )}
            {step === steps.length - 1 && (
              <button className="cs-button-primary" type="submit">
                <span>Završi</span>
              </button>
            )}
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  );
}

export default CreateProductForm;
