import { TreeEntity } from '../../interfaces/TreeEntity';
import {
  CategoryResponse,
  CategoryStorePayload,
  CategoryUpdatePayload
} from './contracts';
import { deleteOptionalProperties } from '../../utils/helpers/helpers';
import axiosAPI from '../../utils/client/axiosAPI';
import { Attribute } from '../Attribute/Attribute';
import { Feature } from '../Feature/Feature';

export class Category implements TreeEntity {
  public readonly id: number;
  public readonly parentId: number | null;
  public readonly image: string;
  public readonly name: string;
  public readonly price: number;
  public readonly key?: number;
  public readonly value?: number;
  public readonly title?: string;
  public readonly children?: Category[];
  public readonly attributes?: Attribute[];
  public readonly features?: Feature[];

  public constructor({
    id,
    parent_id,
    name,
    price,
    children,
    attributes,
    features,
    image
  }: CategoryResponse) {
    this.id = id;
    this.parentId = parent_id;
    this.name = name;
    this.key = id;
    this.value = id;
    this.price = price;
    this.title = name;
    this.children = children?.map((data) => new Category(data)) ?? [];
    this.attributes = attributes?.map((data) => new Attribute(data)) ?? [];
    this.features = features?.map((data) => new Feature(data)) ?? [];
    this.image = image;
  }

  public static async all(...relations: string[]): Promise<Category[]> {
    const { data } = await axiosAPI.get<CategoryResponse[]>(
      relations.length === 0
        ? '/categories'
        : `/categories?with=${relations.join(',')}`
    );
    return data.map((data) => new Category(data));
  }

  public static async store(payload: CategoryStorePayload): Promise<Category> {
    console.log(payload);
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('price', payload.price.toString());
    if (payload.parent_id) {
      formData.append('parent_id', payload.parent_id.toString());
    }
    if (payload.image) {
      formData.append('image', payload.image);
    }
    const { data } = await axiosAPI.post<CategoryResponse>(
      'categories',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return new Category(data);
  }

  public static toMap(categories: Category[]): Map<number, Category> {
    const result = new Map<number, Category>();
    const toMap = (categories: Category[]) => {
      categories.forEach((category) => {
        result.set(category.id, category);
        !!category.children && toMap(category.children);
      });
    };
    toMap(categories);
    return result;
  }

  public static async get(id: number | string): Promise<Category> {
    const { data } = await axiosAPI.get(`categories/${id}`);
    return new Category(data);
  }

  public async update(payload: CategoryUpdatePayload): Promise<Category> {
    const { data } = await axiosAPI.put(`categories/${this.id}`, payload);
    return new Category(data);
  }
}
