import ShopSideBar from '../../components/SideBar/ShopSideBar';
import style from './cart.module.scss';
import cx from 'classnames';
import { useCartContext } from '../../../context/CartContext/CartContext';
import { FaRegTrashAlt as DeleteIcon } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export interface CartProps {
  collapsedSidebar: boolean;
  setCollapsedSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

function Cart(props: CartProps) {
  const { collapsedSidebar, setCollapsedSidebar } = props;

  useEffect(() => {
    setCollapsedSidebar(true);
  }, []);

  const { cart, cartRemoveProduct, cartChangeProductQuantity } =
    useCartContext();

  const orderPrice = 0;

  return (
    <div className={style.pageContainer}>
      <ShopSideBar
        collapsed={collapsedSidebar}
        setCollapsed={setCollapsedSidebar}
        heading="Naša ponuda"
      >
        <p>
          Nudimo vam najam kostima i rekvizita iz bogate kolekcije Fundusa Avala
          studios d.o.o. Beograd. Ponuda obuhvata različite epohe koje možete
          pronaći u našem katalogu na ovom sajtu. Izborom odgovarajućih
          proizvoda iz naše ponude, u mogućnosti ste da izvršite rezervaciju
          željenih artikala koje želite da iznajmite.
        </p>
      </ShopSideBar>

      <div
        className={cx(
          style.cartPageWrapper,
          collapsedSidebar ? style.collapsed : null,
          'cs-content'
        )}
      >
        <h1 className={style.title}>Vaš izbor iz kataloga</h1>
        <div className={style.cartTable}>
          <div className={style.header}>
            <span>SKU</span>
            <span>Proizvod</span>
            <span>Kontrole</span>
          </div>
          <div className={style.body}>
            {cart?.products.map(({ id, name, sku, quantity, images }) => {
              return (
                <div key={id} className={style.product}>
                  <div className={style.productId}>{sku}</div>
                  <div className={style.info}>
                    <div className={style.image}>
                      <img src={images?.at(0) ?? ''} alt={name} />
                    </div>
                    <NavLink className={style.name} to={`/product/${id}`}>
                      {name}
                    </NavLink>
                  </div>
                  <div
                    className={style.remove}
                    onClick={() => cartRemoveProduct(id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.footer}>
          <NavLink className={style.buttonSecondary} to={'/'}>
            Nazad na prodavnicu
          </NavLink>
          <NavLink className={style.button} to={'/checkout'}>
            Na poručivanje
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Cart;
