import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { useLocation } from 'react-router-dom';
import { User } from '../../models/User/User';
import Session from '../../utils/storage/ClientStorage';
import AuthReducer, {
  AuthReducerState,
  AuthReducerAction,
  AuthActionType
} from './AuthReducer';

interface AuthContextState extends AuthReducerState {
  dispatch: Dispatch<AuthReducerAction>;
}

const initialState: AuthContextState = {
  user: null,
  isAuthenticating: true,
  dispatch: () => {},
  error: false
};

export const AuthContext = createContext(initialState);

export const useAuthContext = () => useContext(AuthContext);

export default function AuthContextProvider({ children }: PropsWithChildren) {
  const location = useLocation();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const token = Session.get('user_token');
    dispatch({ type: AuthActionType.REQUEST_LOGIN });
    if (!token) {
      dispatch({ type: AuthActionType.LOGIN_ERROR });
      return;
    }
    User.fetchByToken(token)
      .then((user) => {
        dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload: user });
      })
      .catch(() => {
        dispatch({ type: AuthActionType.LOGIN_ERROR });
      });
  }, []);

  useEffect(() => {
    if (
      !Session.refresh(
        'user_token',
        parseInt(process.env.REACT_APP_SESSION_TIMEOUT)
      )
    ) {
      dispatch({ type: AuthActionType.LOGOUT });
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {!state.isAuthenticating ? children : <></>}
    </AuthContext.Provider>
  );
}
