import { User } from '../../models/User/User';
import Session from '../../utils/storage/ClientStorage';

export interface AuthReducerState {
  user: User | null;
  isAuthenticating: boolean;
  error: boolean;
}

export enum AuthActionType {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT'
}

export type AuthReducerAction =
  | { type: AuthActionType.REQUEST_LOGIN }
  | { type: AuthActionType.LOGIN_SUCCESS; payload: User }
  | { type: AuthActionType.LOGIN_ERROR }
  | { type: AuthActionType.LOGOUT };

const actions = {
  [AuthActionType.REQUEST_LOGIN]: (state: AuthReducerState) => {
    return {
      ...state,
      isAuthenticating: true,
      user: null,
      error: false
    };
  },

  [AuthActionType.LOGIN_SUCCESS]: (state: AuthReducerState, user: User) => {
    Session.set('user_token', user.getToken());
    return {
      ...state,
      isAuthenticating: false,
      user: User.clone(user),
      error: false
    };
  },

  [AuthActionType.LOGIN_ERROR]: (state: AuthReducerState) => {
    return {
      ...state,
      isAuthenticating: false,
      user: null,
      error: true
    };
  },

  [AuthActionType.LOGOUT]: (state: AuthReducerState) => {
    Session.delete('user_token');
    return {
      ...state,
      isAuthenticating: false,
      user: null,
      error: false
    };
  }
};

export default function AuthReducer(
  state: AuthReducerState,
  action: AuthReducerAction
): AuthReducerState {
  switch (action.type) {
    case AuthActionType.REQUEST_LOGIN:
      return actions[AuthActionType.REQUEST_LOGIN](state);

    case AuthActionType.LOGOUT:
      return actions[AuthActionType.LOGOUT](state);

    case AuthActionType.LOGIN_SUCCESS:
      return actions[AuthActionType.LOGIN_SUCCESS](state, action.payload);

    case AuthActionType.LOGIN_ERROR:
      return actions[AuthActionType.LOGIN_ERROR](state);
  }
}
