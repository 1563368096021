import { useQuery } from '@tanstack/react-query';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Select, TreeSelect } from 'antd';
import { Category } from '../../../models/Category/Category';
import { Filter } from '../../../models/Filter/Filter';
import { Feature } from '../../../models/Feature/Feature';
import { Button, Col, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import style from './create-feature.module.scss';
import { FaTrash as DeleteIcon, FaEdit as EditIcon } from 'react-icons/fa';

function CreateFeature() {
  const { data: filters } = useQuery(['filters'], () => Filter.all('values'));
  const { data: categories } = useQuery(['categories'], () => Category.all());
  const { data: features, refetch: refetchFeatures } = useQuery(
    ['features'],
    () => Feature.all('values')
  );

  return (
    <>
      <h1 className="cs-title">Kreiranje Karakteristika</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Row>
        <Formik
          initialValues={{
            categories: [],
            name: '',
            values: []
          }}
          validationSchema={Yup.object({
            name: Yup.string().max(255, 'name.max').required('name.required'),
            categories: Yup.array().of(Yup.number()).optional(),
            values: Yup.array()
              .required()
              .of(
                Yup.object().shape({
                  value: Yup.string().required('value.required'),
                  filter_values: Yup.array().of(Yup.number()).optional()
                })
              )
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            Feature.store(values)
              .then((feature) => {
                refetchFeatures();
                resetForm();
                toast('Save success');
              })
              .catch((error) => {
                toast.error(error.response.data.message);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off" className="cs-form-wrapper">
              <FormGroup>
                <FormLabel>Izaberi kategoriju</FormLabel>
                <TreeSelect
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                  treeCheckable
                  showSearch
                  treeDefaultExpandAll
                  placeholder="Izaberi kategoriju"
                  treeData={categories ?? []}
                  value={values.categories}
                  onChange={(value) => setFieldValue('categories', value, true)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Naziv Karakteristike</FormLabel>
                <InputField
                  placeholder="Ime Karakteristike"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessageLabel name="name" />
              </FormGroup>
              <FormGroup>
                <FormLabel>Vrednosti Karakteristike</FormLabel>
                <FieldArray name="values">
                  {({ push, remove }) => (
                    <>
                      {values.values?.map((_, index) => (
                        <Row key={index} className="mb-2">
                          <Col className="cs-attribute-name-wrapper">
                            <InputField
                              placeholder="Feature value"
                              name={`values.${index}.value`}
                              autoComplete="off"
                            />
                            <ErrorMessageLabel name={`values.${index}.value`} />
                          </Col>
                          <Col className="cs-attribute-filters-wrapper">
                            <Field
                              name={`values.${index}.filter_values`}
                              component={Select}
                              mode={'multiple'}
                              onChange={(value: number[]) =>
                                setFieldValue(
                                  `values.${index}.filter_values`,
                                  value,
                                  true
                                )
                              }
                            >
                              {filters?.map(({ id, name, options }) => (
                                <Select.OptGroup key={id} label={name}>
                                  {options?.map(({ id, value }) => (
                                    <Select.Option key={id} value={id}>
                                      {value}
                                    </Select.Option>
                                  ))}
                                </Select.OptGroup>
                              ))}
                            </Field>
                            <Button
                              className="mx-2 JHover"
                              variant="outline-light"
                              onClick={() => remove(index)}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <div>
                        <Button
                          variant="outline-light"
                          className="mt-2 btn-lg JHover"
                          onClick={() =>
                            push({
                              value: '',
                              filter_values: []
                            })
                          }
                        >
                          <i className="fa-solid fa-circle-plus"></i>
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </FormGroup>

              <ButtonPrimary type="submit">Sačuvaj</ButtonPrimary>
            </Form>
          )}
        </Formik>
      </Row>
      {/* 
      <h2 className="cs-title mt-4 mb-3">Lista Karakteristika</h2>
      <div className={style.featuresTable}>
        {features?.map(({ id, name, options }) => {
          return (
            <div key={id} className={style.featuresItem}>
              <div className={style.featuresHeader}>
                <span className={style.title}>{name}</span>
                <div className={style.actions}>
                  <EditIcon className={style.edit} />
                  <DeleteIcon className={style.delete} />
                </div>
              </div>
              <div className={style.featuresValuesWrapper}>
                {options?.map(({ id, value }) => {
                  return (
                    <span className={style.featuresValue} key={id}>
                      {value}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div> */}
    </>
  );
}

export default CreateFeature;
