import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Col, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from '../../../models/Project/Project';

function EditProject() {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = useQueryClient();

  const { data: project, isLoading } = useQuery(
    ['project', id],
    () => Project.get(id!),
    {
      enabled: typeof id !== 'undefined'
    }
  );

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <h1 className="cs-title">Napravi Projekat</h1>
          <p className="cs-body">
            Napravite cenovne projekat, kada porudžbina dostigne određeni broj
            poena cena se menja u predodređenu cenu.{' '}
          </p>
        </Col>
      </Row>
      <ToastContainer className="cs-toast-wrapper" />
      <Formik
        initialValues={{
          name: project?.name ?? '',
          duration: project?.duration ?? 0,
          points_required: project?.points_required ?? 0
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Projekat mora imati naziv'),
          duration: Yup.number()
            .integer()
            .min(0, 'Projekat mora imati period trajanja')
            .required('Unesite vreme trajanja projekta'),
          points_required: Yup.number()
            .min(0, 'Projekat mora zahtevati minimalan broj poena')
            .required('Unesite broj poena potrebnih za projekat')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          project
            ?.update(values)
            .then(() => {
              client
                .invalidateQueries({
                  queryKey: ['projects'],
                  exact: true,
                  refetchType: 'active'
                })
                .then(() => navigate('/admin/projects/list'));
              client.invalidateQueries({
                queryKey: ['project', id],
                exact: true,
                refetchType: 'none'
              });
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" className="cs-form-wrapper">
            <FormGroup>
              <Col lg={6}>
                <FormLabel>Naziv projekta</FormLabel>
                <InputField
                  placeholder="Unesite naziv projekta"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessageLabel name="name" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>Trajanje projekta</FormLabel>
                <InputField
                  placeholder="Duration (days)"
                  name="duration"
                  type="number"
                  step={1}
                />
                <ErrorMessageLabel name="duration" />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg={6}>
                <FormLabel>Broj potrebnih poena</FormLabel>
                <InputField
                  placeholder="Points Required"
                  name="points_required"
                  type="number"
                  step={0.1}
                />
                <ErrorMessageLabel name="points_required" />
              </Col>
            </FormGroup>
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              Sačuvaj
            </ButtonPrimary>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditProject;
