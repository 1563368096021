import { useEffect, useState } from 'react';
import { Activity, ActivityType, EventType, getActitityLog } from './queries';
import { Button } from 'react-bootstrap';
import style from './activity-log.module.scss';
import {
  FaTrash as DeleteIcon,
  FaWindowRestore as UpdateIcon,
  FaCheck as CreateIcon
} from 'react-icons/fa';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';

const ActivityLog = () => {
  const [activities, setActivities] = useState<Activity[]>([]);

  const loadActivities = (controller?: AbortController) => {
    getActitityLog({
      skip: activities.length,
      controller
    })
      .then((activities) => {
        setActivities((previousActivities) => [
          ...previousActivities,
          ...activities
        ]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const controller = new AbortController();

    loadActivities(controller);

    return () => {
      controller.abort();
    };
  }, []);

  const activityEventData = {
    [EventType.CREATED]: {
      name: 'kreirao',
      icon: CreateIcon
    },
    [EventType.UPDATED]: {
      name: 'izmenio',
      icon: UpdateIcon
    },
    [EventType.DELETED]: {
      name: 'obrisao',
      icon: DeleteIcon
    }
  };
  const activityEvent: Record<string, string> = {
    created: 'Kreirano',
    updated: 'Izmenjeno',
    deleted: 'Obrisano'
  };

  const activityLogData = {
    [ActivityType.PRODUCT]: {
      name: 'Proizvod',
      url: '/admin/products/edit/'
    },
    [ActivityType.CATEGORY]: {
      name: 'Kategorija',
      url: '/admin/categories/edit/'
    },
    [ActivityType.ORDER]: {
      name: 'Narudžbina',
      url: '/admin/orders/'
    },
    [ActivityType.ORDER_DOCUMENT]: {
      name: 'Dokument Narudzbine',
      url: '/admin/documents'
    },
    [ActivityType.PROJECT]: {
      name: 'Projekat',
      url: '/admin/projects/edit/'
    },
    [ActivityType.USER]: {
      name: 'Korisnik',
      url: '/admin/users/edit/'
    }
  };

  const activityLog: Record<string, string> = {
    product: 'Proizvod',
    category: 'Kategorija',
    order: 'Narudžbina',
    order_document: 'Dokument Narudžbine',
    project: 'Projekat',
    user: 'Korisnik'
  };

  return (
    <>
      <h1 className="cs-title">Istorija Aktivnosti</h1>
      <div className={style.wrapper}>
        {activities.map(
          ({ id, causer, event, log_name, subject_id, created_at }) => {
            let date = new Date(Date.parse(created_at));
            return (
              <div key={id} className={style.item}>
                <div>
                  Korisnik: <span>{causer?.name ?? 'N/A'}</span>{' '}
                </div>
                <div>
                  Akcija: <span>{activityEvent[event] ?? 'N/A'}</span>
                </div>
                <div>
                  Subjekat: <span>{activityLog[log_name] ?? 'N/A'}</span>
                </div>
                <div>
                  ID: <span>{subject_id}</span>
                </div>
                <div>
                  Datum: <span>{date.toLocaleDateString()}</span>
                </div>
              </div>
              // <div key={id} className={style.item}>
              //   Korisnik <span>{causer?.name ?? 'N/A'}</span> je{' '}
              //   <span>{activityEventData[event].name ?? 'N/A'}</span>{' '}
              //   {activityLogData[log_name].name ?? 'N/A'}
              // </div>
            );
          }
        )}
      </div>
      <ButtonPrimary
        onClick={() => {
          loadActivities();
        }}
        className={style.btn}
      >
        Load More
      </ButtonPrimary>
    </>
  );
};

export default ActivityLog;
