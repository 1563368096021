import { UserCustomerType } from '../../models/User/User';

export enum OrderStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CLIENT_ACCEPTED = 'client-accepted',
  CLIENT_DECLINED = 'client-declined',
  RENTED = 'rented',
  RETURNED = 'returned'
}

export type CreateOrderPayload = {
  cart_token: string;
  products: number[];
  full_name: string;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  phone: string;
  state: string;
  city: string;
  address: string;
  zip: number | string;
  note: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
};

export type OrderResponse = {
  id: number;
  products: number[];
  full_name: string;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  phone: string;
  state: string;
  city: string;
  status: OrderStatus;
  address: string;
  zip: number | string;
  note: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
};
