import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Session from '../../utils/storage/ClientStorage';

interface HistoryContextValue {
  states: string[];
  goBack: () => void;
}

interface HistoryContextProps {
  defaultUrl?: string;
  excludePaths?: string[];
}

const HistoryContext = createContext<HistoryContextValue>({
  states: [],
  goBack: () => {}
});

export const useHistoryContext = () => useContext(HistoryContext);

function HistoryContextProvider({
  children,
  defaultUrl = '/',
  excludePaths = []
}: PropsWithChildren<HistoryContextProps>) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [history, setHistory] = useState([defaultUrl]);

  const goBack = () => {
    const previous = history.at(-1) ?? defaultUrl;
    previous === pathname
      ? navigate(defaultUrl, { replace: true })
      : navigate(previous);
  };

  useEffect(() => {
    if (excludePaths.includes(pathname)) {
      return;
    }
    setHistory((history) => {
      const urls = [...history];
      const index = urls.indexOf(pathname);
      index >= 0 && urls.splice(index, 1);
      return [...urls, pathname];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <HistoryContext.Provider value={{ states: history, goBack }}>
      {children}
    </HistoryContext.Provider>
  );
}

export default HistoryContextProvider;
