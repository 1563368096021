import { AttributeTypes } from '../../Shop/components/Attributes/AttributeValue';
import { AttributeValue } from '../Attribute/AttributeValue';
import { Pagination } from '../contracts';
import { StorageStand } from './Product';

export enum ProductType {
  SIMPLE = 'simple',
  VARIABLE = 'variable'
}

export interface StoreChildProductPayload {
  readonly name: string;
  readonly sku: string;
  readonly price: number;
  readonly points: number;
  readonly images: File[];
  readonly attribute_values: number[];
}

export interface StoreProductPayload extends StoreChildProductPayload {
  readonly type: ProductType;
  readonly category?: number;
  readonly stand?: number;
  readonly addons: number[];
  readonly feature_values: number[];
  readonly variations?: StoreChildProductPayload[];
}
export interface ProductResponse {
  readonly id: number;
  readonly type: ProductType;
  readonly category?: any;
  readonly stand?: any;
  readonly name: string;
  readonly sku: string;
  readonly price: number;
  readonly points: number;
  readonly images: string[];
  readonly attribute_values?: ProductAttribute[];
  readonly feature_values?: ProductFeature[];
  readonly addons?: number[];
  readonly variations?: ProductResponse[];
  readonly storage_room_stand?: StorageStand;
}

export interface ProductAttribute {
  readonly id: number;
  readonly name: string;
  readonly value: string;
  // TODO: Swap this around
  readonly type: AttributeTypes;
  readonly attribute_id: number;
  readonly settings: { [key: string]: any };
}

export interface ProductFeature {
  readonly id: number;
  readonly name: string;
  readonly value: string;
  readonly feature_id: number;
  readonly settings: { [key: string]: any };
}

export interface ProductsWithPagination {
  pagination: Pagination;
  products: ProductResponse[];
}
