import { UserCustomerType } from '../../../../models/User/User';
import axiosAPI from '../../../../utils/client/axiosAPI';

export type ProductCatrgory = {
  id: number;
  name: string;
};

export type Product = {
  id: number;
  name: string;
  sku: string;
  price: number;
  points: number;
  category: ProductCatrgory;
};

export type Project = {
  name: string;
  duration: number;
  points_required: number;
  price: number;
};

export type CreateOrderPayload = {
  cart_token: string;
  full_name: string;
  type: UserCustomerType;
  company_name: string;
  company_tax_number: number | string;
  company_id_number: number | string;
  email: string;
  state: string;
  city: string;
  zip: number | '';
  address: string;
  phone?: string;
  note?: string;
  washing_fee: boolean;
  reservation_start: Date | string;
  reservation_end: Date | string;
  products: number[];
};

export async function getAllProducts() {
  const { data } = await axiosAPI.get<Product[]>(`/order/all-products`);

  return data;
}

export async function getAllProjects() {
  const { data } = await axiosAPI.get<Project[]>(`/order/all-projects`);

  return data;
}

export async function createOrder(payload: CreateOrderPayload) {
  const { data } = await axiosAPI.post(`/order/create`, payload);

  return data;
}

export async function deleteOrder(id: number) {
  const { data } = await axiosAPI.delete(`/order/${id}`);

  return data;
}
