import style from './attributes.module.scss';
import cx from 'classnames';
import { ProductAttribute } from '../../../models/Product/contracts';

// TODO: Remove this
export enum AttributeRenderTypes {
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX'
}

export enum AttributeTypes {
  TEXT = 'text',
  COLOR = 'color',
  IMAGE = 'image'
}

export interface AttributeProps {
  type: AttributeTypes;
  id: number;
  value: string;
}

interface AttributeValueProps {
  attribute: ProductAttribute;
  isSelected?: boolean;
  onClick?: (attribute: ProductAttribute) => void;
}

function TextAttributeValue({
  attribute,
  isSelected = false,
  onClick
}: AttributeValueProps) {
  const { value } = attribute;

  return (
    <div
      className={cx(
        style.attributeTextItem,
        style.attributeItem,
        isSelected ? style.active : null
      )}
      onClick={() => onClick?.(attribute)}
    >
      {value}
    </div>
  );
}

function ColorAttributeValue({
  attribute,
  isSelected = false,
  onClick
}: AttributeValueProps) {
  const { settings } = attribute;
  return (
    <div
      style={{ backgroundColor: settings?.color ?? '#ffffff' }}
      className={cx(
        style.attributeColorItem,
        style.attributeItem,
        isSelected ? style.active : null
      )}
      onClick={() => onClick?.(attribute)}
    ></div>
  );
}

function ImageAttributeValue({
  attribute,
  isSelected = false,
  onClick
}: AttributeValueProps) {
  const { settings } = attribute;

  return (
    <img
      src={settings?.url}
      alt={settings?.url ?? 'NO IMAGE'}
      className={cx(
        style.attributeImageItem,
        style.attributeItem,
        isSelected ? style.active : null
      )}
      onClick={() => onClick?.(attribute)}
    />
  );
}

function AttributeValue({ attribute, ...props }: AttributeValueProps) {
  switch (attribute.type) {
    case AttributeTypes.TEXT:
      return <TextAttributeValue attribute={attribute} {...props} />;

    case AttributeTypes.COLOR:
      return <ColorAttributeValue attribute={attribute} {...props} />;

    case AttributeTypes.IMAGE:
      return <ImageAttributeValue attribute={attribute} {...props} />;
  }

  throw new Error(
    `AttributeValue Error: Failed to render attribute component of type "${attribute.type}"`
  );
}

export default AttributeValue;
