import axiosAPI from '../../../../utils/client/axiosAPI';

export enum DocumentDownloadType {
  CALCULATION = 'calculation',
  GET_PRODUCTS = 'get-products',
  RETURN_PRODUCTS = 'return-products'
}

export interface DownloadDocumentPayload {
  id: number | string;
  type: DocumentDownloadType;
}

export async function downloadDocument(
  payload: DownloadDocumentPayload
): Promise<void> {
  try {
    // Send POST request to download document
    const response = await axiosAPI.post(`/download-document`, payload, {
      responseType: 'blob' // Set the response type to blob for downloading files
    });

    // Create a temporary URL for the downloaded file
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', generateFilename(payload)); // Generate a descriptive filename
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link element and revoking the URL
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);

    // Optionally, show a success message to the user
    alert('Document downloaded successfully');
  } catch (error) {
    console.error('Error downloading document:', error);
    // Optionally, show an error message to the user
    alert('Failed to download document. Please try again later.');
    throw new Error('Failed to download document');
  }
}

function generateFilename(payload: DownloadDocumentPayload): string {
  // Customize the filename based on the document type and ID
  return `${payload.type}-${payload.id}.pdf`;
}
