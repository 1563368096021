import { AddonResponse, AddonStorePayload } from './contracts';
import { AddonValue } from './AddonValue';
import axiosAPI from '../../utils/client/axiosAPI';

export class Addon {
  public readonly id: number;
  public readonly name: string;
  public readonly options?: AddonValue[];

  public constructor({ id, name, options }: AddonResponse) {
    this.id = id;
    this.name = name;
    this.options = options?.map((data) => new AddonValue(data));
  }

  public static async all(...relations: string[]): Promise<Addon[]> {
    const { data } = await axiosAPI.get<AddonResponse[]>(
      relations.length === 0 ? '/addons' : `/addons?with=${relations.join(',')}`
    );
    return data.map((data) => new Addon(data));
  }

  public static async store(payload: AddonStorePayload): Promise<Addon> {
    const { data } = await axiosAPI.post<AddonResponse>('/addons', payload);
    return new Addon(data);
  }
}
