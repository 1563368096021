import { PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '../../context/AuthContext/AuthContext';
import { useHistoryContext } from '../../context/HistoryContext/HistoryContext';

function RedirectAuthenticated({ children }: PropsWithChildren) {
  const { user } = useAuthContext();
  const { goBack } = useHistoryContext();

  useEffect(() => {
    if (!user) {
      return;
    }
    setTimeout(() => {
      goBack();
    });
  }, [user]);

  return !user ? <>{children}</> : <></>;
}

export const withRedirectAuthenticated =
  (WrappedComponent: (props: PropsWithChildren) => JSX.Element) =>
  (props: PropsWithChildren) =>
    (
      <RedirectAuthenticated>
        <WrappedComponent {...props}></WrappedComponent>
      </RedirectAuthenticated>
    );

export default RedirectAuthenticated;
