import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { withRedirectAuthenticated } from '../../components/RedirectAuthenticated/RedirectAuthenticated';
import { useAuthContext } from '../../context/AuthContext/AuthContext';
import { AuthActionType } from '../../context/AuthContext/AuthReducer';
import { User, UserRegisterPayload } from '../../models/User/User';
import Session from '../../utils/storage/ClientStorage';
import style from './register.module.scss';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function Register() {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserRegisterPayload>({
    name: '',
    email: '',
    password: '',
    cart_token: Session.get('cart_token')
  });

  /**FORM VALIDATION */

  const formSchemaDefault = Yup.object().shape({
    name: Yup.string().required('Ime je obavezno polje'),
    email: Yup.string()
      .required('Email je obavezno polje')
      .email('Email nije validan'),
    password: Yup.string()
      .required('Šifra je obavezna')
      .min(8, 'Šifra mora imati najmanje 8 karaktera'),
    confirmPwd: Yup.string()
      .required('Morate potvrditi šifru')
      .oneOf([Yup.ref('password')], 'Šifre se ne poklapaju')
  });

  const formSchemaBusiness = Yup.object().shape({
    name: Yup.string().required('Ime je obavezno polje'),
    email: Yup.string()
      .required('Email je obavezno polje')
      .email('Email nije validan'),
    password: Yup.string()
      .required('Šifra je obavezna')
      .min(8, 'Šifra mora imati najmanje 8 karaktera'),
    confirmPwd: Yup.string()
      .required('Morate potvrditi šifru')
      .oneOf([Yup.ref('password')], 'Šifre se ne poklapaju'),
    id_number: Yup.string()
      .required('Matični broj je obavezan')
      .matches(/^[0-9]+$/, 'Matični broj može sadržati samo brojeve')
      .min(8, 'Matični broj mora imati 8 cifara')
      .max(8, 'Matični broj mora imati 8 cifara'),
    tax_id: Yup.string()
      .required('PIB je obavezan')
      .matches(/^[0-9]+$/, 'PIB može sadržati samo brojeve')
      .min(8, 'PIB mora imati 8 cifara')
      .max(8, 'PIB mora imati 8 cifara')
  });

  const formOptions = { resolver: yupResolver(formSchemaDefault) };
  const formOptionsBussines = { resolver: yupResolver(formSchemaBusiness) };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(formOptions);

  const {
    register: registerBussines,
    handleSubmit: handleBussines,
    formState: { errors: errorsBusiness }
  } = useForm(formOptionsBussines);

  const tryRegister = (userData: UserRegisterPayload) => {
    dispatch({ type: AuthActionType.REQUEST_LOGIN });
    User.register(userData)
      .then((user) => {
        Session.set(
          'user_token',
          user.getToken(),
          parseInt(process.env.REACT_APP_SESSION_TIMEOUT)
        );
        dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload: user });
      })
      .catch((error) => {
        dispatch({ type: AuthActionType.LOGIN_ERROR });
      });
  };

  const onSubmitDefault = (data: any) => {
    tryRegister({
      ...data,
      type: 'individual'
    });
    return false;
  };

  const onSubmitBusiness = (data: any) => {
    tryRegister({
      ...data,
      type: 'company'
    });
    return false;
  };

  const [userFormSelected, setUserFormSelected] = useState<boolean>(false);
  const [businessFormSelected, setBusinessFormSelected] =
    useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);

  return (
    <div className={style.registerWrapper}>
      <div className={style.logoWrapper}>
        <img src="/assets/logo1.png" alt="logo" />
      </div>
      <h1 className={style.title}>Registrujte se</h1>
      <p className={style.subtitle}>Želim da se registrujem kao:</p>
      <div className={style.userRoleSwitch}>
        <div
          className={cx(style.button, userFormSelected ? style.active : null)}
          onClick={() => {
            setBusinessFormSelected(false);
            setUserFormSelected(true);
          }}
        >
          Fizičko lice
        </div>
        <div
          className={cx(
            style.button,
            businessFormSelected ? style.active : null
          )}
          onClick={() => {
            setUserFormSelected(false);
            setBusinessFormSelected(true);
          }}
        >
          Pravno lice
        </div>
      </div>
      <Container className={style.formWrapper}>
        {userFormSelected && (
          <form
            className={style.innerFormWrapper}
            onSubmit={handleSubmit(onSubmitDefault)}
          >
            <Row className="mb-2">
              <Col>
                <div className={style.formGroup}>
                  <label>Ime i Prezime:</label>
                  <input
                    type="text"
                    placeholder="unesite ime i prezime"
                    defaultValue={userData.name}
                    {...register('name')}
                    onChange={({ target }) => {
                      setUserData({
                        ...userData,
                        name: target.value
                      });
                    }}
                  />
                  {/* @ts-ignore */}
                  <p className={style.errorMessage}>{errors.name?.message}</p>
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label>Email adresa:</label>
                  <input
                    type="email"
                    placeholder="unesite vašu email adresu"
                    defaultValue={userData.email}
                    {...register('email')}
                    onChange={({ target }) => {
                      setUserData({
                        ...userData,
                        email: target.value
                      });
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errors.email?.message}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <div className={style.formGroup}>
                  <label>Unesite šifru:</label>
                  <input
                    type="password"
                    {...register('password')}
                    onChange={({ target }) => {
                      setUserData({
                        ...userData,
                        password: target.value
                      });
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errors.password?.message}
                  </p>
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label>Potvrdite šifru:</label>
                  <input type="password" {...register('confirmPwd')} />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errors.confirmPwd?.message}
                  </p>
                </div>
              </Col>
            </Row>
            <button type="submit">Registruj se</button>
          </form>
        )}
        {businessFormSelected && (
          <form
            className={style.innerFormWrapper}
            onSubmit={handleBussines(onSubmitBusiness)}
          >
            <Row className="mb-2">
              <Col>
                <div className={style.formGroup}>
                  <label>Ime Kompanije:</label>
                  <input
                    type="text"
                    placeholder="unesite ime kompanije"
                    {...registerBussines('name')}
                    onChange={({ target }) => {
                      /*                setUserData({
                        ...userData,
                        name: target.value
                      }); */
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.name?.message}
                  </p>
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label>E-mail adresa:</label>
                  <input
                    type="email"
                    placeholder="unesite email adresu kompanije"
                    defaultValue={userData.email}
                    {...registerBussines('email')}
                    onChange={({ target }) => {
                      /*             setUserData({
                        ...userData,
                        email: target.value
                      }); */
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.email?.message}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className={style.formGroup}>
                  <label>Matični broj:</label>
                  <input
                    type="text"
                    placeholder="unesite matični broj"
                    {...registerBussines('id_number')}
                    onChange={({ target }) => {
                      /*         setUserData({
                        ...userData,
                        name: target.value
                      }); */
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.id_number?.message}
                  </p>
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label>PIB:</label>
                  <input
                    type="text"
                    placeholder="unesite pib"
                    {...registerBussines('tax_id')}
                    onChange={({ target }) => {
                      /*       setUserData({
                        ...userData,
                        email: target.value
                      }); */
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.tax_id?.message}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className={style.formGroup}>
                  <label>Unesite šifru</label>
                  <input
                    type="password"
                    {...registerBussines('password')}
                    onChange={({ target }) => {
                      /*                      setUserData({
                        ...userData,
                        password: target.value
                      }); */
                    }}
                  />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.password?.message}
                  </p>
                </div>
              </Col>
              <Col>
                <div className={style.formGroup}>
                  <label>Potvrdite šifru</label>
                  <input type="password" {...registerBussines('confirmPwd')} />
                  <p className={style.errorMessage}>
                    {/* @ts-ignore */}
                    {errorsBusiness.confirmPwd?.message}
                  </p>
                </div>
              </Col>
            </Row>
            <button type="submit">Registruj se</button>
          </form>
        )}
      </Container>
    </div>
  );
}

export default withRedirectAuthenticated(Register);
