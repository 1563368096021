import React from 'react';
import CookieConsent from 'react-cookie-consent';
import style from './cookies-consent.module.scss';

const CookiesConsent = () => {
  const handleAcceptAll = () => {
    console.log('All cookies accepted');
  };

  const handleDeclineAll = () => {
    console.log('All cookies declined');
    document.cookie =
      'myCookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Prihvati sve"
      cookieName="myCookieConsent"
      expires={150}
      onAccept={handleAcceptAll}
    >
      <div className={style.cookiesConsentBody}>
        <div>
          Ovaj veb sajt koristi "cookies" u cilju poboljšanja korisničkog
          iskustva.{' '}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Politika Privatnosti
          </a>
        </div>
        <button onClick={handleDeclineAll}>Odbij sve</button>
      </div>
    </CookieConsent>
  );
};

export default CookiesConsent;
