import { useQuery } from '@tanstack/react-query';
import { Select, TreeSelect } from 'antd';
import { FormikContextType, FormikContext } from 'formik';
import { useContext, useMemo } from 'react';
import { Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import { Dropzone, DropzonePreview } from '../../../../dropzone';
import { Category } from '../../../../models/Category/Category';
import { Product } from '../../../../models/Product/Product';
import axiosAPI from '../../../../utils/client/axiosAPI';
import CameraUploader from '../../../components/CameraUploader/CameraUploader';
import { ErrorMessageLabel } from '../../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../../components/InputField/InputField';
import { StoreProductValues } from '../CreateProductForm';
import ProductImages from '../ProductImages';

interface StepOneProps {
  product?: Product;
}

function StepOne({ product }: StepOneProps) {
  const { values, setValues, setFieldValue } =
    useContext<FormikContextType<StoreProductValues>>(FormikContext);
  const { data: categories = [] } = useQuery(['categories'], () =>
    Category.all('attributes', 'features')
  );

  const categoryMap = useMemo(() => Category.toMap(categories), [categories]);

  const { data: storage } = useQuery(['storage'], async () => {
    const { data } = await axiosAPI.get(`/product/test`);
    return data;
  });

  const getAttributeRelatedFields = (attributes: number[]) => {
    const attributeValues: { [key: number]: number[] } = {};
    attributes.forEach((id) => {
      attributeValues[id] = values.attribute_values[id] ?? [];
    });
    return {
      attributes,
      attribute_values: attributeValues
    };
  };

  const getFeatureRelatedFields = (features: number[]) => {
    const featureValues: { [key: number]: number[] } = {};
    features.forEach((id) => {
      featureValues[id] = values.feature_values[id] ?? [];
    });
    return {
      features,
      feature_values: featureValues
    };
  };

  const setCategory = (category: number) => {
    const { attributes, features } = categoryMap.get(category) as Category;
    setValues(
      {
        ...values,
        ...getAttributeRelatedFields(attributes?.map(({ id }) => id) ?? []),
        ...getFeatureRelatedFields(features?.map(({ id }) => id) ?? []),
        category
      },
      true
    );
  };

  return (
    <>
      <Row className="mt-5">
        <Col sm={6} className="cs-form-wrapper">
          <FormGroup>
            <FormLabel>Izaberi Lokaciju</FormLabel>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Izaberi lokaciju"
              value={values.stand}
              onChange={(value: number) => {
                setFieldValue('stand', value, true);
              }}
              optionFilterProp="children"
            >
              {storage?.map(({ id, name, stands }: any) => (
                <Select.OptGroup key={id} label={name}>
                  {stands?.map(({ id, name }: any) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel>Izaberi Kategoriju</FormLabel>

            <TreeSelect
              // style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
              showSearch
              treeNodeFilterProp="title"
              treeDefaultExpandAll
              placeholder="Izaberi kategoriju"
              treeData={categories ?? []}
              value={values.category}
              onChange={(value: number) => {
                setCategory(value);
              }}
              className="cs-tree-select"
            />
            <ErrorMessageLabel name="category" />
          </FormGroup>
          <FormGroup>
            <FormLabel>Ime</FormLabel>
            <InputField name="name" autoComplete="off" />
            <ErrorMessageLabel name="name" />
          </FormGroup>
          <FormGroup>
            <FormLabel>Sku</FormLabel>
            <InputField name="sku" autoComplete="off" />
            <ErrorMessageLabel name="sku" />
          </FormGroup>
          {/* <FormGroup>
            <FormLabel>Cena</FormLabel>
            <InputField
              name="price"
              type="number"
              step={0.1}
              autoComplete="off"
            />
            <ErrorMessageLabel name="price" />
          </FormGroup>
          <FormGroup>
            <FormLabel>Poeni</FormLabel>
            <InputField
              name="points"
              type="number"
              step={1}
              autoComplete="off"
            />
            <ErrorMessageLabel name="points" />
          </FormGroup> */}
        </Col>
        <Col sm={6}>
          <FormGroup>
            <FormLabel className="cs-subtitle cs-product-camera-label">
              <span>Galerija</span>
              <CameraUploader />
            </FormLabel>

            <Dropzone
              onSelectFiles={(newFiles) => {
                setValues({
                  ...values,
                  images: [...values.images, ...newFiles]
                });
              }}
            ></Dropzone>
            <DropzonePreview
              files={values.images}
              onRemove={(index) => {
                const files = [...values.images];
                files.splice(index, 1);
                setValues({
                  ...values,
                  images: [...files]
                });
              }}
            >
              {!!product && <ProductImages product={product} />}
            </DropzonePreview>
            <ErrorMessageLabel name="images" />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default StepOne;
