// import logo from './logo.svg';
// import styles from './App.module.scss';

import { Routes, Route, NavLink } from 'react-router-dom';
import Shop from './Shop/Shop';
import Admin from './Admin/Admin';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import AuthContextProvider from './context/AuthContext/AuthContext';
import HistoryContextProvider from './context/HistoryContext/HistoryContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CartContextProvider from './context/CartContext/CartContext';

function App() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5
      }
    }
  });

  return (
    <div>
      <QueryClientProvider client={client}>
        <AuthContextProvider>
          <HistoryContextProvider>
            <CartContextProvider>
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<Shop />} />
                <Route
                  element={
                    <ProtectedRoute
                      gate={(user) => {
                        return !!user && user.hasAnyRole(['super-admin']);
                      }}
                    />
                  }
                >
                  <Route path="/admin/*" element={<Admin />} />
                </Route>
              </Routes>
            </CartContextProvider>
          </HistoryContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
