import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { Field, Form, Formik } from 'formik';
import { PropsWithChildren, useMemo } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axiosAPI from '../../../utils/client/axiosAPI';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { InputField } from '../../components/InputField/InputField';

function Foo({ type, settings, ...props }: PropsWithChildren<any>) {
  switch (type) {
    case 'text': {
      delete props.onChange;
      return <InputField {...props} />;
    }

    case 'select':
      return (
        <Field
          style={{ width: '100%' }}
          dropdownStyle={{
            maxHeight: 500,
            overflow: 'auto'
          }}
          component={Select}
          {...props}
        >
          {settings?.options?.map(({ value, label }: any) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Field>
      );
  }

  return <>Ne?</>;
}

function UpdateConfiguration() {
  const { data } = useQuery(['configuration'], () =>
    axiosAPI.get('/configurations')
  );

  const initialValues = useMemo(() => {
    if (!data?.data) {
      return null;
    }
    const initialValues: any = {};
    data?.data?.forEach(({ id, key, label, value }: any) => {
      initialValues[id] = value;
    });
    return initialValues;
  }, [data?.data]);

  if (!initialValues) {
    return <></>;
  }

  return (
    <>
      <h1 className="cs-title">Osnovna podešavanja</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          axiosAPI.post('/configurations', values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="cs-form-wrapper">
            {data?.data?.map(({ id, key, label, type, settings }: any) => (
              <FormGroup key={id}>
                <FormLabel>{label}</FormLabel>
                <Foo
                  type={type}
                  settings={settings}
                  name={id}
                  value={values[id]}
                  onChange={(value: any) => {
                    setFieldValue(id, value);
                  }}
                ></Foo>
              </FormGroup>
            ))}
            <ButtonPrimary type="submit">Sačuvaj</ButtonPrimary>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateConfiguration;
