import { AttributeTypes, AttributeRenderTypes } from './AttributeValue';
import AttributeValue from './AttributeValue';
import style from './attributes.module.scss';

import { Select } from 'antd';
import { useEffect, useState } from 'react';

import cx from 'classnames';
import { ProductAttribute } from '../../../models/Product/contracts';
import { Collection } from 'collect.js';

interface AttributeGroupProps {
  title: string;
  attributes: ProductAttribute[];
  isCard: boolean;
  onSelect?: (attribute: ProductAttribute) => void;
}

function AttributeGroup({
  title,
  attributes,
  isCard,
  onSelect
}: AttributeGroupProps) {
  const [activeAttribute, setActiveAttribute] = useState(0);

  const allowSelect = typeof onSelect === 'function';

  useEffect(() => {
    if (!allowSelect) {
      return;
    }
    const attribute = attributes?.at(0);
    if (!attribute) {
      return;
    }
    setActiveAttribute(attribute.id ?? 0);
    onSelect?.(attribute);
  }, []);

  return (
    <div className={cx(style.attributeWrapper, isCard ? style.isCard : null)}>
      <p className={style.attributeTitle}>{title}</p>
      {/* {renderType == AttributeRenderTypes.CHECKBOX ? ( */}
      <div
        className={style.attributeCheckboxValues}
        // data-current={currentAttribute}
      >
        {attributes.map((attribute) => {
          return (
            <AttributeValue
              key={attribute.id}
              attribute={attribute}
              isSelected={activeAttribute === attribute.id}
              onClick={(attribute) => {
                if (!allowSelect) {
                  return;
                }
                setActiveAttribute(attribute.id);
                onSelect?.(attribute);
              }}
              // type={type}
              // renderType={renderType}
              // id={item.id}
              // value={value}
              // settings={settings}
              // label={item.value}
              // currentAttribute={currentAttribute}
              // setCurrentAttribute={setCurrentAttribute}
            />
          );
        })}
      </div>
      {/* ) : (
        <Select
          className={style.attributeSelectValues}
          dropdownClassName={cx(style.dropdown, style.dropdownWrapper)}
          placeholder="Izaberite vrednost"
        >
          {attributes.map((item: any) => (
            <AttributeValue
              key={item.id}
              type={type}
              renderType={renderType}
              id={item.id}
              value={item.label}
              label={item.label}
            />
          ))}
        </Select>
      )} */}
    </div>
  );
}

export default AttributeGroup;
