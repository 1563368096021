import { useQuery } from '@tanstack/react-query';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Select, TreeSelect } from 'antd';
import { Category } from '../../../models/Category/Category';
import { Filter } from '../../../models/Filter/Filter';
import { Attribute } from '../../../models/Attribute/Attribute';
import { Button, Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { InputField } from '../../components/InputField/InputField';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import style from './create-attribute.module.scss';
import { AttributeTypes } from '../../../Shop/components/Attributes/AttributeValue';
import { AttributeValueStorePayload } from '../../../models/Attribute/contracts';
import ColorBox from '../../components/ColorBox/ColorBox';

interface CreateAttributeValues {
  categories: number[];
  name: string;
  type: AttributeTypes;
  values: AttributeValueStorePayload[];
}

function CreateAttribute() {
  const { data: filters } = useQuery(['filters'], () => Filter.all('values'));
  const { data: categories } = useQuery(['categories'], () => Category.all());
  const { refetch: refetchAttributes } = useQuery(['attributes'], () =>
    Attribute.all('values')
  );

  const initialValues: CreateAttributeValues = {
    categories: [],
    name: '',
    type: AttributeTypes.TEXT,
    values: []
  };

  return (
    <>
      <h1 className="cs-title">Kreiranje Atributa</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Row>
        <Col lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              name: Yup.string().max(255, 'name.max').required('name.required'),
              type: Yup.string().required(),
              categories: Yup.array().of(Yup.number()).optional(),
              values: Yup.array()
                .required()
                .of(
                  Yup.object().shape({
                    value: Yup.string().required('values.required'),
                    filter_values: Yup.array().of(Yup.number()).optional()
                  })
                )
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              Attribute.store({
                ...values,
                values: values.values.map((vals) => ({
                  ...vals,
                  type: values.type
                }))
              })
                .then(() => {
                  refetchAttributes();
                  resetForm();
                  toast('Save success');
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, setFieldValue }) => (
              <Form autoComplete="off" className="cs-form-wrapper">
                <FormGroup>
                  <FormLabel>Izaberi Kategoriju</FormLabel>
                  <TreeSelect
                    treeCheckable
                    showSearch
                    treeDefaultExpandAll
                    placeholder="Izaberi kategoriju"
                    treeData={categories ?? []}
                    value={values.categories}
                    onChange={(value) =>
                      setFieldValue('categories', value, true)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Naziv Atributa</FormLabel>
                  <InputField
                    placeholder="unesi ime atributa"
                    name="name"
                    autoComplete="off"
                  />
                  <ErrorMessageLabel name="name" />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Tip Prikaza Atributa</FormLabel>
                  <Field
                    placeholder="Izaberi"
                    name="type"
                    component={Select}
                    mode={'single'}
                    defaultValue={AttributeTypes.TEXT}
                    onChange={(value: AttributeTypes) =>
                      setFieldValue(`type`, value, true)
                    }
                  >
                    <Select.OptGroup>
                      <Select.Option
                        key={AttributeTypes.TEXT}
                        value={AttributeTypes.TEXT}
                      >
                        Tekst
                      </Select.Option>
                      <Select.Option
                        key={AttributeTypes.COLOR}
                        value={AttributeTypes.COLOR}
                      >
                        Boja
                      </Select.Option>
                      <Select.Option
                        key={AttributeTypes.IMAGE}
                        value={AttributeTypes.IMAGE}
                      >
                        Slika
                      </Select.Option>
                    </Select.OptGroup>
                  </Field>

                  <ErrorMessageLabel name="type" />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Vrednost Atributa</FormLabel>
                  <FieldArray name="values">
                    {({ push, remove }) => (
                      <>
                        {values.values?.map((_, index) => (
                          <Row key={index} className="mb-3">
                            <Col className="cs-attribute-name-wrapper">
                              <InputField
                                placeholder="Unesite hex vrednost boje"
                                name={`values.${index}.value`}
                                autoComplete="off"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setFieldValue(`values.${index}.value`, value);
                                }}
                              />
                              {/* {values.type === AttributeTypes.TEXT && (
                                <InputField
                                  placeholder="Unesite vrednost"
                                  name={`values.${index}.value`}
                                  autoComplete="off"
                                />
                              )} */}
                              {values.type === AttributeTypes.COLOR && (
                                <>
                                  <div className={style.colorPickerInput}>
                                    {/* <InputField
                                      placeholder="Unesite hex vrednost boje"
                                      name={`values.${index}.value`}
                                      autoComplete="off"
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        setFieldValue(
                                          `values.${index}.value`,
                                          value
                                        );
                                      }}
                                    /> */}
                                    <ColorBox
                                      value={values.values[index]?.value}
                                      onChange={(color: any) =>
                                        setFieldValue(
                                          `values.${index}.settings`,
                                          color
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              {values.type === AttributeTypes.IMAGE && (
                                <InputField
                                  placeholder="Unesite vrednost"
                                  name={`values.${index}.value`}
                                  autoComplete="off"
                                />
                              )}
                              <ErrorMessageLabel
                                className="cs-error-msg"
                                name={`values.${index}.value`}
                              />
                            </Col>
                            <Col className="cs-attribute-filters-wrapper">
                              <Field
                                placeholder="Izaberi"
                                name={`values.${index}.filter_values`}
                                component={Select}
                                mode={'multiple'}
                                onChange={(value: number[]) =>
                                  setFieldValue(
                                    `values.${index}.filter_values`,
                                    value,
                                    true
                                  )
                                }
                              >
                                {filters?.map(({ id, name, options }) => (
                                  <Select.OptGroup key={id} label={name}>
                                    {options?.map(({ id, value }) => (
                                      <Select.Option key={id} value={id}>
                                        {value}
                                      </Select.Option>
                                    ))}
                                  </Select.OptGroup>
                                ))}
                              </Field>
                              <Button
                                className="JHover"
                                variant="outline-light"
                                onClick={() => remove(index)}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          variant="outline-light"
                          className="btn-lg JHover"
                          onClick={() =>
                            push({
                              value: '',
                              filter_values: [],
                              settings: null
                            })
                          }
                        >
                          <i className="fa-solid fa-circle-plus"></i>
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </FormGroup>
                <ButtonPrimary type="submit">Sačuvaj Atribut</ButtonPrimary>
              </Form>
            )}
          </Formik>
        </Col>
        {/*     <h2 className="cs-title mt-4 mb-3">Lista Atributa</h2>
        <Col>
          <div className={style.attributeTable}>
            {attributes?.map(({ id, name, options }) => {
              return (
                <div key={id} className={style.attributeItem}>
                  <div className={style.attributeHeader}>
                    <span className={style.title}>{name}</span>
                    <div className={style.actions}>
                      <EditIcon className={style.edit} />
                      <DeleteIcon className={style.delete} />
                    </div>
                  </div>
                  <div className={style.attributeValuesWrapper}>
                    {options?.map(({ id, value }) => {
                      return (
                        <span className={style.attributeValue} key={id}>
                          {value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Col> */}
      </Row>
    </>
  );
}

export default CreateAttribute;
