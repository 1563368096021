import axios from 'axios';
import Session from '../storage/ClientStorage';

const axiosAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosAPI.interceptors.request.use((config) => {
  const token = Session.get('user_token');
  if (!!token) {
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`
      },
      config.headers
    );

    config.params = Object.assign(
      {
        cart_token: Session.get('cart_token')
      },
      config.params
    );
  }
  return config;
});

axiosAPI.interceptors.response.use(void 0, (error) => {
  // check for "session expired" response and logout user
  throw error;
});

export default axiosAPI;
