import { useEffect, useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext/AuthContext';
import { AuthActionType } from '../context/AuthContext/AuthReducer';
import Header from './components/Header/Header';
import NotFound from '../pages/NotFound/NotFound';
import Home from './pages/Home/Home';
import style from './shop.module.scss';
import cx from 'classnames';
import Cart from './pages/Cart/Cart';
import Checkout from './pages/Checkout/Checkout';
import SingleProduct from './pages/SingleProduct/SingleProduct';
import MiniCart from './components/Cart/MiniCart';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import ThankYou from './pages/ThankYou/ThankYou';
import DateModal from './components/DateModal/DateModal';
import { useCartContext } from '../context/CartContext/CartContext';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import AcceptOffer from './pages/AcceptOffer/AcceptOffer';

function Shop() {
  const { user, dispatch } = useAuthContext();
  const { cart } = useCartContext();
  const [collapsedSidebar, setCollapsedSidebar] = useState<boolean>(false);
  const [minicartOpened, setMinicartOpened] = useState<boolean>(false);
  const [noSideBar, setNoSideBar] = useState<boolean>(false);
  const [dateModalOpen, setDateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setNoSideBar(false);
    if (
      cart &&
      typeof cart.hasStartEndDates === 'function' &&
      !cart.hasStartEndDates()
    ) {
      setDateModalOpen(true);
    }
  }, [cart]);

  return (
    <main
      className={cx(
        'cs-shop-layout-wrapper',
        collapsedSidebar ? 'collapsed' : null,
        noSideBar ? 'no-sidebar' : null
      )}
    >
      <div className="cs-header">
        <Header
          minicartOpened={minicartOpened}
          setMinicartOpened={setMinicartOpened}
          dateModalOpen={dateModalOpen}
          setDateModalOpen={setDateModalOpen}
        ></Header>
      </div>

      <div className="cs-content-wrapper">
        <Routes>
          <Route
            index
            element={
              <Home
                collapsedSidebar={collapsedSidebar}
                setCollapsedSidebar={setCollapsedSidebar}
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                collapsedSidebar={true}
                setCollapsedSidebar={setCollapsedSidebar}
              />
            }
          />
          <Route
            path="/thank-you"
            element={<ThankYou setNoSideBar={setNoSideBar} />}
          />
          <Route
            path="/accept-offer/:id"
            element={<AcceptOffer setNoSideBar={setNoSideBar} />}
          />
          {/* TODO ADD Cart */}
          <Route
            path="/cart"
            element={
              <Cart
                collapsedSidebar={collapsedSidebar}
                setCollapsedSidebar={setCollapsedSidebar}
              />
            }
          />
          <Route
            path="/checkout"
            element={
              <Checkout
                collapsedSidebar={collapsedSidebar}
                setCollapsedSidebar={setCollapsedSidebar}
              />
            }
          />
          {/* <Route
            element={
              <ProtectedRoute
                gate={(user) => {
                  return true;
                }}
              />
            }
          >
            <Route
              path="/checkout"
              element={
                <Checkout
                  collapsedSidebar={collapsedSidebar}
                  setCollapsedSidebar={setCollapsedSidebar}
                />
              }
            />
          </Route> */}
          <Route
            path="/product/:id"
            element={
              <SingleProduct
                collapsedSidebar={collapsedSidebar}
                setCollapsedSidebar={setCollapsedSidebar}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <DateModal
        open={dateModalOpen}
        setOpen={setDateModalOpen}
        onAction={() => {}}
      ></DateModal>
      <MiniCart
        opened={minicartOpened}
        setOpened={setMinicartOpened}
      ></MiniCart>
    </main>
  );
}

export default Shop;
