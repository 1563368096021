import { ProjectResponse, ProjectStorePayload } from './contracts';
import axiosAPI from '../../utils/client/axiosAPI';

export class Project {
  public readonly id: number;
  public readonly name: string;
  public readonly duration: number;
  public readonly points_required: number;

  public constructor({ id, name, duration, points_required }: ProjectResponse) {
    this.id = id;
    this.name = name;
    this.duration = duration;
    this.points_required = points_required;
  }

  public static async all(): Promise<Project[]> {
    const { data } = await axiosAPI.get<ProjectResponse[]>('projects');
    return data.map((data) => new Project(data));
  }

  public static async store(payload: ProjectStorePayload): Promise<Project> {
    const { data } = await axiosAPI.post<ProjectResponse>('/projects', payload);
    return new Project(data);
  }

  public static async get(id: string | number): Promise<Project> {
    const { data } = await axiosAPI.get<ProjectResponse>(`/projects/${id}`);
    return new Project(data);
  }

  public async delete() {
    return await axiosAPI.delete<null>(`/projects/${this.id}`);
  }

  public static async delete(id: number) {
    return await axiosAPI.delete<null>(`/projects/${id}`);
  }
  public async update(payload: ProjectStorePayload): Promise<any> {
    return axiosAPI.put(`/projects/${this.id}`, payload);
  }
}
