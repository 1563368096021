import { Form, Formik } from 'formik';
import { Category } from '../../../models/Category/Category';
import { useQuery } from '@tanstack/react-query';
import { Divider, TreeSelect } from 'antd';
import { InputField } from '../../components/InputField/InputField';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useRef, useState } from 'react'; // Import useState hook
import { FaPlus as PlusIcon, FaTimes as TrashIcon } from 'react-icons/fa';

import style from './create-category.module.scss';
import { CategoryStorePayload } from '../../../models/Category/contracts';

const createCategoryValidationSchema = Yup.object({
  parent_id: Yup.number().nullable(),
  name: Yup.string().required('Kategorija mora imati ime'),
  price: Yup.number().required(),
  image: Yup.mixed().nullable()
});
function CreateCategory() {
  type DataType = {
    parent_id: number | null;
    name: string;
    price: number;
    image: File | null;
  };
  const fileRef = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null); // State for image preview

  const { data: categories, refetch } = useQuery(['categories'], () =>
    Category.all()
  );
  const initialValues = {
    parent_id: null,
    name: '',
    price: 0,
    image: null
  };

  return (
    <>
      <h1 className="cs-title">Kreiranje kategorija</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Row>
        <Col>
          <Formik
            initialValues={initialValues}
            validationSchema={createCategoryValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              Category.store(values)
                .then(() => {
                  refetch();
                  resetForm();
                  toast('Uspešno kreirana kategorije');
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form autoComplete="off" className="cs-form-wrapper">
                <div className={style.form}>
                  <div className={style.formCol}>
                    <FormGroup>
                      <FormLabel>Bazna Kategorija</FormLabel>
                      <TreeSelect
                        style={{
                          width: '100%'
                        }}
                        showSearch
                        treeDefaultExpandAll
                        placeholder="roditelj kategorija"
                        treeData={categories ?? []}
                        value={values.parent_id}
                        onChange={(value) =>
                          setFieldValue('parent_id', value, true)
                        }
                      />
                      <ErrorMessageLabel name="parent_id" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Ime Kategorije</FormLabel>
                      <InputField
                        type="text"
                        name="name"
                        placeholder="Ime kategorije"
                        autoComplete="off"
                      />
                      <ErrorMessageLabel name="name" component="div" />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>
                        Cena proizvoda iz kategorije (
                        {process.env.REACT_APP_DEFAULT_CURRENCY})
                      </FormLabel>
                      <InputField
                        type="number"
                        step={1}
                        min={0}
                        name="price"
                        placeholder="Price"
                        autoComplete="off"
                      />
                      <ErrorMessageLabel name="price" component="div" />
                    </FormGroup>
                  </div>
                  <div className={style.formCol}>
                    <FormGroup>
                      <FormLabel>Dodaj sliku</FormLabel>
                    </FormGroup>
                    {/* Image preview */}
                    {imagePreview ? (
                      <FormGroup className={style.imagePreview}>
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className={style.image}
                        />
                        <button
                          type="button"
                          className={style.removeImage}
                          onClick={() => {
                            setImagePreview(null);
                            setFieldValue('image', null);
                            if (fileRef.current) {
                              // Check if fileRef.current is not null
                              fileRef.current.value = ''; // Clear file input value
                            }
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </FormGroup>
                    ) : (
                      <div className={style.imagePreviewEmpty}>
                        <p>Niste Izabrali sliku</p>
                        <label
                          htmlFor="fileInput"
                          className={style.addImageButton}
                        >
                          <PlusIcon />
                        </label>
                        <input
                          ref={fileRef}
                          id="fileInput"
                          name="image"
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }} // Hide the input
                          onChange={(event) => {
                            const image = event.target?.files?.[0];

                            if (!image) {
                              return;
                            }

                            setFieldValue('image', image);

                            // Display image preview
                            const reader = new FileReader();
                            reader.onload = () => {
                              setImagePreview(reader.result as string);
                            };
                            reader.readAsDataURL(image);
                          }}
                        />
                        <ErrorMessageLabel name="image" />
                      </div>
                    )}
                  </div>
                </div>

                <div className={style.footer}>
                  <ButtonPrimary type="submit" disabled={isSubmitting}>
                    Sačuvaj
                  </ButtonPrimary>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}

export default CreateCategory;
