import { FilterValueResponse } from './contracts';

export class FilterValue {
  public readonly id: number;
  public readonly value: string;

  constructor({ id, value }: FilterValueResponse) {
    this.id = id;
    this.value = value;
  }
}
