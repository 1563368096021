import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Cart, CartProduct } from '../../models/Cart/Cart';
import Session from '../../utils/storage/ClientStorage';

export interface CartContextState {
  cart: Cart | null;
  cartSetStartEndDates: (startDate: string, endDate: string) => void;
  cartAddProduct: (productID: number, quantity: number) => void;
  cartRemoveProduct: (productID: number) => void;
  cartChangeProductQuantity: (productID: number, delta: number) => void;
  cartClear: () => void;
}

const initialState: CartContextState = {
  cart: null,
  cartSetStartEndDates: () => {},
  cartAddProduct: () => {},
  cartRemoveProduct: () => {},
  cartChangeProductQuantity: () => {},
  cartClear: () => {}
};

export const CartContext = createContext(initialState);

export const useCartContext = () => useContext(CartContext);

function CartContextProvider({ children }: PropsWithChildren) {
  const [cart, setCart] = useState<Cart | null>(null);

  useQuery(['cart'], () => Cart.get(Session.get('cart_token') ?? null), {
    onSuccess(cart) {
      Session.set('cart_token', cart.token);
      setCart(cart);
    }
  });

  const cartSetStartEndDates = (startDate: string, endDate: string) => {
    cart?.setStartEndDates(startDate, endDate).then((cart) => setCart(cart));
  };

  const cartAddProduct = (productID: number, quantity: number) => {
    cart?.addProduct(productID, quantity).then((cart) => setCart(cart));
  };

  const cartRemoveProduct = (productID: number) => {
    cart?.removeProduct(productID).then((cart) => setCart(cart));
  };

  const cartChangeProductQuantity = (productID: number, delta: number) => {
    cart
      ?.changeProductQuantity(productID, delta)
      ?.then((cart) => setCart(cart));
  };

  const cartClear = () => {
    cart?.emptyCart().then((cart) => setCart(cart));
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartSetStartEndDates,
        cartAddProduct,
        cartRemoveProduct,
        cartChangeProductQuantity,
        cartClear
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContextProvider;
