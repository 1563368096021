import { useQuery } from '@tanstack/react-query';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import {
  OrderDocument,
  deleteOrderDocument,
  downloadOrderDocument,
  getAllOrderDocuments
} from '../queries';
import { FaRegTrashAlt, FaFileDownload } from 'react-icons/fa';

const OrderDocumentsList = () => {
  type DataType = {
    key: number;
    id: number;
    name: string;
    order_status: string;
    original_name: string;
  };

  const orderStatuses: Record<string, string> = {
    pending: 'Na čekanju',
    accepted: 'Prihvaćeno',
    declined: 'Odbijeno',
    rented: 'Iznajmljeno',
    returned: 'Vraćeno'
  };

  const { data: orderDocuments, refetch } = useQuery({
    queryKey: ['order-documents'],
    queryFn: () => getAllOrderDocuments()
  });

  const handleDelete = (orderDocument: OrderDocument) => {
    if (window.confirm('Da li ste sigurni?')) {
      deleteOrderDocument(orderDocument).then(() => {
        refetch();
      });
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status Narudžbine',
      dataIndex: 'order_status',
      key: 'order_status'
    },
    {
      title: 'Akcije',
      key: 'actions',
      render(orderDocument) {
        return (
          <div className="cs-table-button-wrapper">
            <button
              className="cs-table-button-icon"
              onClick={() => {
                downloadOrderDocument(orderDocument);
              }}
            >
              <FaFileDownload />
            </button>
            <button
              className="cs-table-button-icon"
              onClick={() => {
                handleDelete(orderDocument);
              }}
            >
              {' '}
              <FaRegTrashAlt />
            </button>
          </div>
        );
      }
    }
  ];

  const data = useMemo<DataType[]>(
    () =>
      orderDocuments?.map((orderDocument) => {
        const { id, name, order_status, original_name } = orderDocument;

        return {
          key: id,
          id,
          name,
          original_name,
          order_status: orderStatuses[order_status] ?? ''
        };
      }) ?? [],
    [orderDocuments]
  );

  return (
    <>
      <h1 className="cs-title">Lista Dokumenata</h1>

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="cs-table-wrapper"
      />
    </>
  );
};

export default OrderDocumentsList;
