import { FeatureValueResponse } from './contracts';

export class FeatureValue {
  public readonly id: number;
  public readonly value: string;
  public readonly filterValues: any[];

  constructor({ id, value, filter_values }: FeatureValueResponse) {
    this.id = id;
    this.value = value;
    this.filterValues = filter_values;
  }
}
