import { useQuery } from '@tanstack/react-query';
import { getAllProducts, getAllProjects } from './queries';
import { ProductsTable, OrderTable, OrderForm } from './components';
import { useMemo, useState } from 'react';
import type { Product } from './queries';

import { ButtonPrimary } from '../../../components/Button/ButtonPrimary';
import style from './create-order.module.scss';
import cx from 'classnames';
import { useCartContext } from '../../../../context/CartContext/CartContext';

const CreateOrder = () => {
  const [orderProducts, setOrderProducts] = useState<Product[]>([]);
  const [show, setShow] = useState(false);
  const [orderCreated, setOrderCreated] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data: products } = useQuery({
    queryKey: ['products'],
    queryFn: () => getAllProducts()
  });

  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => getAllProjects()
  });

  const handleAddToOrder = (product: Product) => {
    if (orderProducts.findIndex(({ id }) => id === product.id) >= 0) {
      return;
    }

    setOrderProducts((previousOrderProducts) => {
      const orderProducts = [...previousOrderProducts];

      orderProducts.push(product);

      return orderProducts;
    });
  };

  const handleRemoveFromOrder = (product: Product) => {
    const index = orderProducts.findIndex(({ id }) => id === product.id);

    if (index === -1) {
      return;
    }

    setOrderProducts((previousOrderProducts) => {
      const orderProducts = [...previousOrderProducts];

      orderProducts.splice(index, 1);

      return orderProducts;
    });
  };

  const handleEmptyOrder = () => {
    setOrderProducts([]);
  };

  const projectPoints = useMemo(
    () => orderProducts.reduce((carry, { points }) => carry + points, 0),
    [orderProducts]
  );

  const totalPrice = useMemo(() => {
    return orderProducts.reduce((total, { price }) => total + price, 0);
  }, [orderProducts]);

  const activeProject = useMemo(() => {
    if (!projects) {
      return null;
    }

    const sorted = [...projects].sort((previous, next) => {
      return next.points_required - previous.points_required;
    });

    return (
      sorted.find(({ points_required }) => projectPoints >= points_required) ??
      null
    );
  }, [projects, projectPoints]);

  return (
    <>
      <h1 className="cs-title">Kreiraj Narudžbinu</h1>
      {!orderCreated ? (
        <>
          <h2 className="cs-subtitle">Lista proizvoda</h2>

          <p>Odaberite proizvode koje zelite da ukljucite u porudzbinu:</p>
          <OrderTable
            products={orderProducts}
            handleRemoveFromOrder={handleRemoveFromOrder}
          />
          <ButtonPrimary type="button" onClick={() => setShow(true)}>
            Dodaj Proizvode
          </ButtonPrimary>
          <hr />
          <div className={style.orderInfoWrapper}>
            <h2 className="cs-subtitle">Informacije o porudzbini</h2>
            <div className={style.orderInfo}>
              <div>
                Cena: <span className="fw-bold">{totalPrice} RSD</span>
              </div>
              {/* <div>
              Broj Poena:
              <span className="fw-bold">{projectPoints}</span>
            </div>
            {activeProject && (
              <>
                <div>
                  Aktivan Projekat:
                  <span className="fw-bold">{activeProject.name}</span>
                </div>
                <div>
                  Cena po projektu
                  <span className="fw-bold">{activeProject.price}</span>
                </div>
              </>
            )} */}
            </div>
          </div>
          <div className={cx(style.modalWrapper, show && style.show)}>
            <div className={style.modalContainer}>
              {!!products && (
                <ProductsTable
                  products={products}
                  selectedProducts={orderProducts}
                  handleAddToOrder={handleAddToOrder}
                  handleRemoveFromOrder={handleRemoveFromOrder}
                />
              )}
              <button
                className={cx(style.modalButton, 'cs-button-primary')}
                onClick={() => handleClose()}
              >
                Sacuvaj
              </button>
            </div>
          </div>
          <hr />
          <h2 className="cs-subtitle">Informacije o kupcu</h2>

          <OrderForm
            products={orderProducts.map(({ id }) => id)}
            onAfterSubmit={() => {
              handleEmptyOrder();
              setOrderCreated(true);
            }}
          />
        </>
      ) : (
        <div className={style.orderCreatedContainer}>
          <p>Čestitamo! Uspešno ste kreirali novu porudzbinu.</p>
          <ButtonPrimary onClick={() => setOrderCreated(false)}>
            Nova porudzbinu
          </ButtonPrimary>
        </div>
      )}
    </>
  );
};

export default CreateOrder;
