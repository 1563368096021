import { useQuery } from '@tanstack/react-query';
import { Modal, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import axiosAPI from '../../../../utils/client/axiosAPI';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  FaTrash as DeleteIcon,
  FaEdit as EditIcon,
  FaEye as ViewIcon,
  FaCheck as AcceptIcon,
  FaTimes as DeclineIcon
} from 'react-icons/fa';
import {
  CreateOrderPayload,
  OrderResponse
} from '../../../../Shop/pages/Checkout/queries';
import { deleteOrder } from '../CreateOrder/queries';

const StatusColors: { [key: string]: string } = {
  pending: 'orange',
  accepted: 'green',
  declined: 'red',
  rented: 'purple',
  returned: 'blue'
};

const OrderStatusClasses: { [key: string]: string } = {
  pending: 'cs-pending-status',
  accepted: 'cs-accepted-status',
  declined: 'cs-declined-status',
  rented: 'cs-rented-status',
  returned: 'cs-returned-status'
};

function OrdersList() {
  const { data: orders, refetch } = useQuery(['orders'], async () => {
    const { data } = await axiosAPI.get(`/orders`);
    return data.map((order: OrderResponse) => ({ ...order, key: order.id }));
  });

  const navigation = useNavigate();

  const handleDelete = (id: number) => {
    Modal.confirm({
      title: 'Potvrdi brisanje',
      content: `Da li ste sigurni da želite da obrišete narudžbinu ${id}?`,
      okText: 'Obriši',
      cancelText: 'Prekini',
      className: 'cs-confirmation-modal',
      onOk() {
        deleteOrder(id);
        refetch();
      }
    });
  };

  const columns: ColumnsType<CreateOrderPayload> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <>{id}</>
    },
    {
      title: 'Ime',
      key: 'user',
      render: (row) => <span>{row.full_name}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag
          className={cx(
            OrderStatusClasses[status] || 'cs-default-status',
            'cs-status-badge'
          )}
        >
          {status}
        </Tag>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <div className="cs-action-wrapper">
          <div className="acction-btn">
            <AcceptIcon />
          </div>
          <div className="acction-btn">
            <DeclineIcon
              onClick={() => {
                handleDelete(id);
                refetch();
              }}
            />
          </div>
          <div
            className="acction-btn"
            onClick={() => navigation(`/admin/orders/${id}`)}
          >
            <ViewIcon />
          </div>
        </div>
      )
    }
  ];

  return (
    <div className='cs-inner-container"'>
      <h1 className="cs-title mb-4">Lista Narudžbina</h1>
      <Table
        columns={columns}
        dataSource={orders}
        className="cs-table-wrapper"
      ></Table>
    </div>
  );
}

export default OrdersList;
