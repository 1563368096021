import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { RentPeriod } from '../../../models/RentPeriod/RentPeriod';
import { Col, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { ButtonPrimary } from '../../components/Button/ButtonPrimary';
import { ErrorMessageLabel } from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import { InputField } from '../../components/InputField/InputField';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

function EditRentPeriod() {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = useQueryClient();

  const { data: rentPeriod, isLoading } = useQuery(
    ['rent-period', id],
    () => RentPeriod.get(id!),
    {
      enabled: typeof id !== 'undefined'
    }
  );

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <h1 className="cs-title">Promeni Period Rentiranja</h1>
      <ToastContainer className="cs-toast-wrapper" />
      <Row>
        <Formik
          initialValues={{
            duration: rentPeriod?.duration,
            price_factor: rentPeriod?.pointFactor,
            point_factor: rentPeriod?.priceFactor
          }}
          validationSchema={Yup.object({
            duration: Yup.number()
              .integer()
              .min(1, 'duration.min error')
              .required('duration.required error'),
            price_factor: Yup.number()
              .min(1, 'Faktor cene mora da bude minimum 1')
              .required('price_factor.required error'),
            point_factor: Yup.number()
              .min(1, 'Faktor poena mora da bude minimum 1')
              .required('point_factor.required error')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            rentPeriod
              ?.update(values)
              .then(() => {
                client
                  .invalidateQueries({
                    queryKey: ['rent-periods'],
                    exact: true,
                    refetchType: 'active'
                  })
                  .then(() => navigate('/admin/rent-periods/list'));
                client.invalidateQueries({
                  queryKey: ['rent-period', id],
                  exact: true,
                  refetchType: 'none'
                });
              })
              .catch((error) => {
                toast.error(error.response.data.message);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off" className="cs-form-wrapper">
              <FormGroup>
                <Col lg={6}>
                  <FormLabel>
                    Dužina perioda <i>[dani]</i>
                  </FormLabel>
                  <InputField
                    placeholder="Trajanje (dani)"
                    name="duration"
                    type="number"
                    step={1}
                  />
                  <ErrorMessageLabel name="duration" />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col lg={6}>
                  <FormLabel>
                    Faktor cene <i>[cena X faktor]</i>
                  </FormLabel>
                  <InputField
                    placeholder="Procenat uvećanja cene"
                    name="price_factor"
                    type="number"
                    step={0.1}
                  />
                  <ErrorMessageLabel name="price_factor" />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col lg={6}>
                  <FormLabel>
                    Faktor uvećanja poena <i>[poeni X faktor]</i>
                  </FormLabel>
                  <InputField
                    placeholder="Procenat uvećanja poena"
                    name="point_factor"
                    type="number"
                    step={0.1}
                  />
                  <ErrorMessageLabel name="point_factor" />
                </Col>
              </FormGroup>
              <ButtonPrimary type="submit" disabled={isSubmitting}>
                Sačuvaj
              </ButtonPrimary>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
}

export default EditRentPeriod;
