import { Routes, Route } from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import OrdersList from './pages/Orders/ListOrder/OrdersList';
import Sidebar from './components/Sidebar/Sidebar';
import CreateAddon from './pages/Addon/CreateAddon';
import CreateAttribute from './pages/Attribute/CreateAttribute';
import CreateCategory from './pages/Category/CreateCategory';
import CreateFeature from './pages/Feature/CreateFeature';
import CreateFilter from './pages/Filter/CreateFilter';
import links from './navigation';
import CreateProduct from './pages/Product/CreateProduct';
import Header from './components/Header/Header';
import cx from 'classnames';

import './index.scss';
import CreateRentPeriod from './pages/RentPeriod/CreateRentPeriod';
import RentPeriodList from './pages/RentPeriod/RentPeriodList';
import EditRentPeriod from './pages/RentPeriod/EditRentPeriod';
import CreateProject from './pages/Project/CreateProject';
import UpdateConfiguration from './pages/Configuration/UpdateConfiguration';

import SingleOrder from './pages/Orders/SingleOrder/SingleOrder';

import { useState } from 'react';
import ProductList from './pages/Product/ProductList';
import EditProduct from './pages/Product/EditProduct';
import SingleProduct from './pages/Product/SingleProduct';
import CategoryList from './pages/Category/CategoryList';
import FilterList from './pages/Filter/FilterList';
import EditFilter from './pages/Filter/EditFilter';
import AttributeList from './pages/Attribute/AttributeList';
import EditAttribute from './pages/Attribute/EditAttribute';
import EditFeature from './pages/Feature/EditFeature';
import FeatureList from './pages/Feature/FeatureList';
import ProjectList from './pages/Project/ProjectList';
import EditCategory from './pages/Category/EditCategory';
import EditProject from './pages/Project/EditProject';
import { CreateUser, EditUser, UserList } from './pages/Users';
import CreateOrder from './pages/Orders/CreateOrder';

import OrderDocumentsList from './pages/OrderDocuments/OrderDocumentsList';
import OrderDocumentsCreate from './pages/OrderDocuments/OrderDocumentsCreate';
import ActivityLog from './pages/ActivityLog';

function Admin() {
  const [collapsedSidebar, setCollapsedSidebar] = useState<boolean>(false);

  return (
    <main
      className={cx(
        'cs-admin-layout-wrapper',
        collapsedSidebar ? 'collapsed' : null
      )}
    >
      <div className="cs-header">
        <Header></Header>
      </div>
      <div className="cs-sidebar">
        <Sidebar
          collapsed={collapsedSidebar}
          setCollapsed={setCollapsedSidebar}
          links={links}
        ></Sidebar>
      </div>
      <div className="cs-content-wrapper">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route
            path="/configurations/update"
            element={<UpdateConfiguration />}
          />
          <Route path="/rent-periods/create" element={<CreateRentPeriod />} />
          <Route path="/rent-periods/edit/:id" element={<EditRentPeriod />} />
          <Route path="/rent-periods/list" element={<RentPeriodList />} />

          <Route path="/user/create" element={<CreateUser />} />
          <Route path="/user/edit/:id" element={<EditUser />} />
          <Route path="/user/list" element={<UserList />} />

          <Route path="/projects/create" element={<CreateProject />} />
          <Route path="/projects/edit/:id" element={<EditProject />} />
          <Route path="/projects/list" element={<ProjectList />} />

          <Route path="/categories/create" element={<CreateCategory />} />
          <Route path="/categories/edit/:id" element={<EditCategory />} />
          <Route path="/categories/list" element={<CategoryList />} />

          <Route path="/filters/create" element={<CreateFilter />} />
          <Route path="/filters/edit/:id" element={<EditFilter />} />
          <Route path="/filters/list" element={<FilterList />} />

          <Route path="/attributes/create" element={<CreateAttribute />} />
          <Route path="/attributes/edit/:id" element={<EditAttribute />} />
          <Route path="/attributes/list" element={<AttributeList />} />

          <Route path="/features/create" element={<CreateFeature />} />
          <Route path="/features/edit/:id" element={<EditFeature />} />
          <Route path="/features/list" element={<FeatureList />} />

          <Route path="/addons/create" element={<CreateAddon />} />

          {/* PRODUCT */}
          <Route path="/products/create" element={<CreateProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/products/list" element={<ProductList />} />
          <Route path="/product/:id" element={<SingleProduct />} />

          <Route path="/orders/*">
            <Route index element={<OrdersList />} />
            <Route path=":id" element={<SingleOrder />} />
            <Route path="create" element={<CreateOrder />} />
          </Route>

          <Route path="/order-documents/*">
            <Route index element={<OrderDocumentsList />} />
            <Route path="create" element={<OrderDocumentsCreate />} />
          </Route>

          <Route path="/activity-log" element={<ActivityLog />} />

          <Route
            element={
              <ProtectedRoute
                gate={(user) => {
                  return !!user && user.hasAnyRole(['superuser']);
                }}
              />
            }
          >
            <Route path="/super" element={<OrdersList />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </main>
  );
}

export default Admin;
