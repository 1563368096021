export interface PaginationResponse {
  current_page: number;
  last_page: number;
  size: number;
  count: number;
  total: number;
}

export class Paginator {
  public readonly currentPageIndex: number;
  public readonly pagesCount: number;
  public readonly firstPage: Page;
  public readonly lastPage: Page;
  public readonly pages: Page[];

  public constructor(
    { current_page, last_page }: PaginationResponse,
    pages: number
  ) {
    this.currentPageIndex = Math.min(current_page, last_page);
    this.pagesCount = ~~(pages / 2);
    this.firstPage = new Page(1);
    this.lastPage = new Page(last_page);
    this.pages = [];

    this.initialize();
  }

  public hasPages(): boolean {
    return this.pages.length > 0;
  }

  public getPages(): Page[] {
    return this.pages;
  }

  public getPreviousPageIndex(): number {
    return Math.max(this.firstPage.index, this.currentPageIndex - 1);
  }
  public getNextPageIndex(): number {
    return Math.min(this.lastPage.index, this.currentPageIndex + 1);
  }

  protected initialize(): void {
    const pages: Array<Page> = [];
    const { start, end } = this.getRange();

    if (end - start < -1) {
      return;
    }

    for (let index = start; index <= end; index++) {
      pages.push(new Page(index));
    }

    const firstPage = pages.at(0);
    if (!!firstPage && firstPage.getOffset(this.firstPage) > 1) {
      pages.unshift(new Page(firstPage.index - 1, true));
    }

    const lastPage = pages.at(-1);
    if (!!lastPage && lastPage?.getOffset(this.lastPage) > 1) {
      pages.push(new Page(lastPage.index + 1, true));
    }

    pages.unshift(this.firstPage);
    pages.push(this.lastPage);

    this.pages.push(...pages);
  }

  protected getRange(): { start: number; end: number } {
    return {
      start: Math.max(
        this.firstPage.index + 1,
        this.currentPageIndex - this.pagesCount
      ),
      end: Math.min(
        this.lastPage.index - 1,
        this.currentPageIndex + this.pagesCount
      )
    };
  }
}

class Page {
  constructor(
    public readonly index: number,
    public readonly asEllipsis: boolean = false
  ) {}

  public getOffset(page: Page): number {
    return Math.abs(this.index - page.index);
  }
}
