import style from './add-to-cart.module.scss';
import { InputNumber } from 'antd';
import { useState } from 'react';
import { ButtonPrimary } from '../Button/ButtonPrimary';
import { MdAddShoppingCart } from 'react-icons/md';
import cx from 'classnames';
import { Product } from '../../../models/Product/Product';
import { useCartContext } from '../../../context/CartContext/CartContext';

interface AddToCartButtonProps {
  product: Product;
}

function AddToCartButton({ product }: AddToCartButtonProps) {
  const { cartAddProduct } = useCartContext();

  const [quantity, setQuantity] = useState(1);

  // Dummy data
  const stockage = 100;

  return (
    <div className={style.addToCartWrapper}>
      <div className={style.addToCartContent}>
        {/* <div className={style.quantity}>
          <InputNumber
            min={0}
            defaultValue={1}
            onChange={(value) => setQuantity(value)}
            className={style.quantityInput}
          />
        </div> */}
        <ButtonPrimary
          className={style.button}
          onClick={() => {
            cartAddProduct(product.id, quantity);
          }}
        >
          <MdAddShoppingCart style={{ marginRight: '.5rem' }} />
          Dodaj
        </ButtonPrimary>
      </div>
      <div
        className={cx(
          quantity > stockage ? style.outOffStock : null,
          style.errorMessage
        )}
      >
        Nema trenutno na stanju {quantity} predmeta
      </div>
    </div>
  );
}

export default AddToCartButton;
