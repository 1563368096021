import { useState } from 'react';
import { SliderPicker } from 'react-color';
import { Button } from 'react-bootstrap';

import style from './color-box.module.scss';

interface ColorBoxProps {
  value: string;
  onChange: (value: any) => void;
}

const ColorBox = (props: ColorBoxProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(props.value);

  const handleChange = (color: any) => {
    props.onChange({ color: color.hex });
    setColor(color.hex);
  };

  return (
    <div
      className={style.colorPickerBox}
      style={{ backgroundColor: color }}
      onClick={() => setShowColorPicker(true)}
    >
      {showColorPicker && (
        <div className={style.colorPicker}>
          <div className={style.container}>
            <SliderPicker
              color={props.value}
              onChange={handleChange}
              className={style.pickerWrapper}
            />
            <label className="mt-3">Unesite hex boje(opciono)</label>
            <input
              className={style.colorInput}
              type="text"
              value={color}
              onChange={(event) => handleChange({ hex: event.target.value })}
            />
            <Button
              variant="outline-light"
              className="btn-lg JHover mt-5"
              onClick={(event) => {
                event.stopPropagation();
                setShowColorPicker(false);
              }}
            >
              Izaberi Boju
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorBox;
