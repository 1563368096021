import React, { PropsWithChildren } from 'react';
import { Outlet, Navigate, OutletProps } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext/AuthContext';
import { Gate } from '../../interfaces/Gate';

interface ProtectedRouteProps extends OutletProps {
  redirectPath?: string;
  redirectPathForbidden?: string;
  gate?: Gate;
}

function ProtectedRoute({
  children,
  redirectPath = '/',
  redirectPathForbidden = '/',
  gate,
  ...props
}: PropsWithChildren<ProtectedRouteProps>) {
  const { user, isAuthenticating } = useAuthContext();

  if (isAuthenticating) {
    return <div>Authentication in progress...</div>;
  }

  const isAuthenticated = !!user;
  const isAllowed = isAuthenticated && (gate?.(user) ?? true);

  if (!isAllowed) {
    return <Navigate to={redirectPathForbidden}></Navigate>;
  }

  return isAuthenticated ? (
    <Outlet {...props}></Outlet>
  ) : (
    <Navigate to={redirectPath}></Navigate>
  );
}

export default React.memo(ProtectedRoute);
