import { Routes, Route, NavLink } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { AuthActionType } from '../../../context/AuthContext/AuthReducer';

import { useCartContext } from '../../../context/CartContext/CartContext';
import style from './header.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import {
  MdOutlineSearch as SearchIcon,
  MdPayment as HeaderCheckout,
  MdOutlineShoppingBag as HeaderCart,
  MdLogout as HeaderLogout,
  MdLockOutline as HeaderLogin,
  MdAccessTime as HeaderClock,
  MdOutlineAdminPanelSettings as MenuAdmin,
  MdCheck as CheckedIcon,
  MdWarning as WarningIcon
} from 'react-icons/md';

import { WardrobeIcon } from '../Icons';

import {
  FaUserCog as HeaderUser,
  FaCog as HeaderSettings
} from 'react-icons/fa';
import { Dispatch, FC, SetStateAction, useState } from 'react';

interface HeaderModalProps {
  minicartOpened: boolean;
  setMinicartOpened: Dispatch<SetStateAction<boolean>>;
  dateModalOpen: boolean;
  setDateModalOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: FC<HeaderModalProps> = ({
  minicartOpened = false,
  setMinicartOpened,
  dateModalOpen = false,
  setDateModalOpen
}) => {
  const { cart, cartChangeProductQuantity: changeQuantity } = useCartContext();
  const { user, dispatch } = useAuthContext();

  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  return (
    /* {cart?.hasStartEndDates && ( */
    <div className={style.shopHeader}>
      <div>
        {/* <InputGroup className={style.shopSearch}>
          <InputGroup.Text>
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control
            placeholder="Pretraži (npr. 'Nemačka Uniforma')"
            aria-label="Pretraži (npr. 'Nemačka Uniforma')"
            aria-describedby="basic-addon1"
            type="text"
            id="search"
            name="search"
          />
        </InputGroup> */}
      </div>
      <div className={style.headerIcons}>
        <button
          className={style.cartButton}
          onClick={() =>
            setMinicartOpened((minicartOpened: boolean) => !minicartOpened)
          }
        >
          <WardrobeIcon />
          <div className={style.notification}>
            <span>{cart?.products?.length ?? 0} </span>
          </div>
        </button>

        {cart?.products?.length ? (
          <button
            className={style.headerButton}
            onClick={() => navigate('/checkout')}
          >
            <HeaderCheckout />
          </button>
        ) : (
          <></>
        )}
        {/* <button
          className={style.clockButton}
          onClick={() => setDateModalOpen(true)}
        >
          <HeaderClock />
          <div className={style.notification}>
            {cart?.hasStartEndDates ? <CheckedIcon /> : <WarningIcon />}
          </div>
        </button> */}

        {(!!user && (
          <Dropdown className={style.dropdownButton}>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <HeaderUser />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <p className={style.dropdownTitle}>{user?.name}</p>
              <p className={style.dropdownEmail}>{user?.email}</p>
              <div
                className={style.dropdownLinks}
                onClick={() => navigate('/admin')}
              >
                <span>
                  <MenuAdmin />
                </span>
                <span>Admin Panel</span>
              </div>
              <div
                className={style.dropdownLinks}
                onClick={() => {
                  dispatch({ type: AuthActionType.LOGOUT });
                }}
              >
                <span>
                  <HeaderSettings />
                </span>
                <span>Podešavanja</span>
              </div>
              <div
                className={style.dropdownLinks}
                onClick={() => {
                  dispatch({ type: AuthActionType.LOGOUT });
                }}
              >
                <span>
                  <HeaderLogout />
                </span>
                <span>Izloguj se</span>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )) || (
          <NavLink to="/login" className={style.loginButton}>
            <HeaderLogin />
          </NavLink>
        )}
      </div>
    </div>
    /*  )} */
  );
};

export default Header;
